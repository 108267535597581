import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPiggyBank,
  faUtensils,
  faBriefcase,
  faTshirt,
  faGamepad,
  faGift
} from '@fortawesome/free-solid-svg-icons';

function BankWindow({ closeWindow, simState, setSimState }) {
  // Function to apply for an overdraft limit (up to $2000)
  // (Note: applying for an overdraft does not log a transaction)
  const applyOverdraft = () => {
    const input = window.prompt("Enter overdraft limit (max $2000):", "0");
    if (input === null) return; // cancelled
    const limit = parseFloat(input);
    if (!isNaN(limit) && limit >= 0 && limit <= 2000) {
      setSimState(prevState => ({
        ...prevState,
        overdraftLimit: limit
      }));
    } else {
      alert("Invalid overdraft limit. Please enter a value between 0 and 2000.");
    }
  };

  // Function to move cash into the emergency fund.
  // This will log a transaction for the transfer.
  const moveToEmergencyFund = () => {
    const input = window.prompt("Enter amount to move to Emergency Fund:", "0");
    if (input === null) return; // cancelled
    const amount = parseFloat(input);
    if (!isNaN(amount) && amount > 0 && amount <= simState.accountBalance) {
      setSimState(prevState => ({
        ...prevState,
        accountBalance: prevState.accountBalance - amount,
        emergencyFund: (prevState.emergencyFund || 0) + amount,
        transactions: [
          ...prevState.transactions,
          {
            type: 'transfer',
            amount,
            date: new Date(prevState.currentTime).toISOString(),
            description: `Transferred $${amount?.toFixed(2)} to Emergency Fund`
          }
        ]
      }));
    } else {
      alert("Invalid amount. Please enter a value greater than 0 and not more than your current balance.");
    }
  };

  return (
    <div className="window">
      <div className="window-header d-flex justify-content-between align-items-center">
        <div className="window-title d-flex align-items-center">
          <div className="window-title-icon bank">
            <FontAwesomeIcon icon={faPiggyBank} />
          </div>
          <span>My Bank</span>
        </div>
        <div className="window-controls d-flex">
          <div className="window-button window-minimize"></div>
          <div className="window-button window-maximize"></div>
          <div className="window-button window-close" onClick={closeWindow}></div>
        </div>
      </div>
      <div className="window-content">
        <div className="bank-content">
          <div className="bank-header d-flex justify-content-between">
            <div className="balance-card">
              <div className="balance-title">CURRENT BALANCE</div>
              <div className="balance-amount">${simState.accountBalance?.toFixed(2)}</div>
              <div className="bank-actions d-flex">
                <button className="bank-action-btn me-2" onClick={applyOverdraft}>
                  Apply Overdraft
                </button>
                <button className="bank-action-btn" onClick={moveToEmergencyFund}>
                  Move Cash to Emergency Fund
                </button>
              </div>
              <div className="card-number">**** **** **** 5678</div>
            </div>
          </div>
          
          <div className="transactions">
            <div className="transactions-title">Recent Transactions</div>
            <div className="transaction-list">
              {simState.transactions && simState.transactions.length > 0 ? (
                simState.transactions.map((tx, idx) => (
                  <div key={idx} className="transaction-item d-flex justify-content-between align-items-center">
                    <div className="transaction-info d-flex align-items-center">
                      <div className="transaction-icon">
                        {/* You can choose different icons based on tx.type if desired */}
                        <FontAwesomeIcon icon={faPiggyBank} />
                      </div>
                      <div className="transaction-details">
                        <div className="transaction-name">{tx.description}</div>
                        <div className="transaction-date">{new Date(tx.date).toLocaleString()}</div>
                      </div>
                    </div>
                    <div className={`transaction-amount ${tx.amount < 0 ? 'amount-negative' : 'amount-positive'}`}>
                      ${tx.amount?.toFixed(2)}
                    </div>
                  </div>
                ))
              ) : (
                <p>No transactions yet.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankWindow;
