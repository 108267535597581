import { hashString, seededRandomGenerator } from './randomWalkUtils';
import stockMeta from './stockMeta';

// Helper: Generate a random walk array given a seed.
function generateRandomWalk(seed, steps, startValue, volatility, upwardPressure) {
  const random = seededRandomGenerator(seed);
  const data = [startValue];
  for (let i = 1; i < steps; i++) {
    const delta = (random() - 0.5) * volatility + upwardPressure;
    data.push(data[i - 1] + delta);
  }
  return data;
}

// Exported function: Calculate the current value of a purchased stock using the meta data.
// The number of steps is determined by the number of weeks elapsed divided by 4 (to approximate months).
export function calculateStockValue(purchasedStock, currentWeek) {
  // Default purchaseWeek to currentWeek if missing
  const purchaseWeek = purchasedStock.purchaseWeek !== undefined ? purchasedStock.purchaseWeek : currentWeek;
  const steps = Math.max(1, Math.floor((currentWeek - purchaseWeek) / 4));
  const meta = stockMeta[purchasedStock.stockId];
  if (!meta) {
    console.error("No stock metadata for stockId:", purchasedStock.stockId);
    return purchasedStock.purchasePrice;
  }
  // Use purchasePrice as the starting value.
  const seedInput = purchasedStock.stockId + purchaseWeek.toString();
  const walk = generateRandomWalk(
    Math.abs(hashString(seedInput)),
    steps,
    purchasedStock.purchasePrice,
    meta.volatility,
    meta.upwardPressure
  );
  return walk[walk.length - 1];
}

export function advanceSimulation(simState) {
  const newWeek = simState.week + 1;
  
  // --- Monetary adjustments ---
  const salary = simState.job.salary;
  const rent = simState.housing.rent;
  const foodCost = simState.weeklyFoodShop.reduce((sum, item) => sum + (item.cost * item.quantity), 0);
  const funExpense = simState.funExpense;
  
  // Update bank account.
  const newAccountBalance = simState.accountBalance + salary - rent - foodCost - funExpense;
  
  // --- Record transactions (using week number) ---
  const transactions = [...simState.transactions];
  transactions.push({
    type: 'salary',
    amount: salary,
    week: newWeek,
    description: 'Weekly salary deposited'
  });
  transactions.push({
    type: 'rent',
    amount: -rent,
    week: newWeek,
    description: 'Rent deducted'
  });
  transactions.push({
    type: 'food',
    amount: -foodCost,
    week: newWeek,
    description: 'Weekly food shop purchase'
  });
  transactions.push({
    type: 'fun',
    amount: -funExpense,
    week: newWeek,
    description: 'Fun expense'
  });
  
  // --- Nutrient tracking ---
  const nutrientIntake = { calories: 0, protein: 0, carbs: 0, fat: 0 };
  simState.weeklyFoodShop.forEach(item => {
    nutrientIntake.calories += item.nutrients.calories * item.quantity;
    nutrientIntake.protein += item.nutrients.protein * item.quantity;
    nutrientIntake.carbs += item.nutrients.carbs * item.quantity;
    nutrientIntake.fat += item.nutrients.fat * item.quantity;
  });
  const nutrientGap = {
    calories: Math.abs(simState.idealNutrients.calories - nutrientIntake.calories),
    protein: Math.abs(simState.idealNutrients.protein - nutrientIntake.protein),
    carbs: Math.abs(simState.idealNutrients.carbs - nutrientIntake.carbs),
    fat: Math.abs(simState.idealNutrients.fat - nutrientIntake.fat)
  };
  
  // --- Update portfolio value based on current stock prices ---
  const portfolioValue = simState.purchasedStocks.reduce((sum, stock) => {
    return sum + (calculateStockValue(stock, newWeek) * stock.quantity);
  }, 0);
  
  return {
    ...simState,
    week: newWeek,
    accountBalance: newAccountBalance,
    transactions,
    nutrientIntake,
    nutrientGap,
    portfolioValue,
  };
}
