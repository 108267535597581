import React from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRobot, faUserGroup, faUser, faCog, faNetworkWired, faEyeSlash, faPaintBrush, faFolder } from '@fortawesome/free-solid-svg-icons';
import ManageStaff from '../manageStaff';
import AIOptions from './aiOptions';
import CreateGroups from './createGroups';
import CreateCustomAreas from './createCustomAreas';
import CreateCustomModules from './createCustomModules'
import OtherSettings from './otherSettings';
import StylingSettings from './stylingSettings';
import CreateResources from './createResources';
import DesktopSim from '../../student/financeGame/desktopSim';

const SettingsPage = (props) => {
    return (
        <div className="container">


            <Routes>
                <Route path="/" element={<></>} />
                <Route path="*" element={<Link to="/settings" >Back</Link>} />
            </Routes>





            <Routes>
                <Route path="/" element={
                    <>
                        <h2 className={props.mrCharlieBot && "text-light"}>Settings</h2>
                        <div className="list-group">
                            <Link to="/settings/staff" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faUser} /> Manage Staff
                            </Link>
                            {/* <Link to="/settings/ai-options" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faRobot} /> AI Options
                            </Link> */}

                            {!props.mrCharlieBot &&

                                <>
                                    <Link to="/settings/create-groups" className="list-group-item list-group-item-action">
                                        <FontAwesomeIcon icon={faUserGroup} /> Create Groups
                                    </Link>

                                    <Link to="/settings/custom-areas" className="list-group-item list-group-item-action">
                                        <FontAwesomeIcon icon={faNetworkWired} /> Custom Areas
                                    </Link>
                                    <Link to="/settings/other-settings" className="list-group-item list-group-item-action">
                                        <FontAwesomeIcon icon={faEyeSlash} /> Hide Features
                                    </Link>
                                    <Link to="/settings/styling" className="list-group-item list-group-item-action">
                                        <FontAwesomeIcon icon={faPaintBrush} /> Modify Styling
                                    </Link>
                                </>}
                            <Link to="/settings/add-resources" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faFolder} /> Add Resources
                            </Link>


                            {/*                             
                            <Link to="/settings/modules" className="list-group-item list-group-item-action">
                                <FontAwesomeIcon icon={faNetworkWired} /> Modules 
                            </Link> */}
                        </div>
                    </>
                } />

                <Route path="create-groups" element={<CreateGroups schoolData={props.schoolData} />} />
                <Route path="ai-options" element={<AIOptions schoolData={props.schoolData} />} />
                <Route path="staff" element={<ManageStaff schoolData={props.schoolData} />} />
                <Route path="custom-areas" element={<CreateCustomAreas schoolData={props.schoolData} />} />
                <Route path="modules" element={<CreateCustomModules schoolData={props.schoolData} />} />
                <Route path="styling" element={<StylingSettings schoolData={props.schoolData} />} />
                <Route path="other-settings" element={<OtherSettings schoolData={props.schoolData} />} />
                <Route path="add-resources" element={<CreateResources schoolData={props.schoolData} />} />
                <Route path="finance-game" element={<DesktopSim schoolData={props.schoolData} />} />
            </Routes>
        </div>
    );
};

export default SettingsPage;
