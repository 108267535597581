import React from "react";
import { Field, ErrorMessage } from "formik";
import { Editor } from '@tinymce/tinymce-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ChromePicker } from 'react-color';
import { faArrowRightLong, faBook, faClipboardCheck } from '@fortawesome/free-solid-svg-icons'

class CharacteristicSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: undefined,
            example: false,
            tab: null,
            understand: "",
            improvementText: "",
            improvements: [],
            changeColor: false,
            color: '#ffffff',
            understandPreview: false,
            evidenceAdvancedSettings: false,
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        this.setState({ color: this.props.characteristicData.color ? this.props.characteristicData.color : '#ffffff' })

    }

    handleChange(newColor) {
        this.setState({ color: newColor.hex });
        this.props.handleColorChange(newColor.hex, this.props.characteristicData.id)
    }


    toggleExample = () => {
        let example = this.state.example
        this.setState({ example: example ? false : true })
    }

    toggleColor = () => {
        this.setState({ changeColor: !this.state.changeColor })
    }
    handleEditorChange = (content, editor) => {
        console.log(this.props.characteristicData.id)
        this.props.onEditorChange(content, this.props.characteristicData.id);
    };

    handleImprovementTextChange = event => {
        console.log("text changed")
        this.setState({ improvementText: event.target.value });
    };

    handleAddImprovementClick = () => {
        console.log("button clicked", this.state.improvementText, this.props.characteristicData.id)
        if (this.state.improvementText !== "") {
            this.props.improvementsAdd(this.state.improvementText, this.props.characteristicData.id);
        }
        this.setState({ improvementText: "" })
    };

    handleDeleteImprovementClick = (index) => {
        console.log("delete", index)
        this.props.improvementsDelete(index, this.props.characteristicData.id);

    }

    handleDeleteCharacteristicClick = () => {
        this.props.deleteCharacteristic(this.props.characteristicData.id);
    }




    render() {

        const letter = (this.props.index + 10).toString(36).toUpperCase()

        let improvements = []
        const improvementsData = this.props.improvements

        for (let i = 0; i < improvementsData?.length; i++) {
            improvements.push(<div>
                <label style={{ marginTop: "5px", textAlign: "left" }} className="btn btn-outline-dark btn-small" >

                    {improvementsData[i]}
                    <button type="button" onClick={() => this.handleDeleteImprovementClick(i)} style={{ marginLeft: "2rem" }} className="btn btn-danger">delete</button>
                </label>

            </div>
            )
        }



        return (
            <div className="card" style={{ backgroundColor: this.state.color, marginBottom: "25px" }}>
                <div className="card-body">
                    <div style={{ textAlign: "right" }}><button type="button" onClick={() => { this.handleDeleteCharacteristicClick() }} className="btn btn-danger">Delete</button></div>
                    <h6 className="card-title">{letter}...
                        <div className="row">

                            <div className="form-group  col-9">
                                <label>Name of characteristic</label>
                                <Field id={"name" + this.props.characteristicData.id} name={"name" + this.props.characteristicData.id} defaultValue={this.props.characteristicData["name" + this.props.characteristicData.id]} className="form-control" />
                            </div>

                            <div className="form-group  col-3">
                                <br />
                                <button type="button" className="btn btn-outline-secondary" onClick={() => { this.toggleColor() }} >Colour</button>

                                {this.state.changeColor &&
                                    <ChromePicker
                                        color={this.state.color}
                                        onChange={this.handleChange}
                                    />
                                }
                                {/*
                                <label>Sort order</label>
                                <Field id={"index" + this.props.characteristicData.id} type="number" name={"index" + this.props.characteristicData.id} className="form-control" />
        */}
                            </div>


                        </div>
                    </h6>

                    {/* Tabs */}
                    <div className="row">
                        <hr />
                        <div style={{ textAlign: "center" }} className="col-3">
                            <button
                                type="button"
                                onClick={() => this.setState({ tab: this.state.tab === "understand" ? null : "understand" })}
                                className={"btn btn" + (this.state.tab === "understand" ? "" : "-outline") + "-primary"}>
                                Understand
                            </button>
                        </div>

                        <div style={{ textAlign: "center", marginTop: "0.5rem" }} className="col-1">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        </div>

                        <div style={{ textAlign: "center" }} className="col-3">
                            <button
                                type="button"
                                onClick={() => this.setState({ tab: this.state.tab === "evidence" ? null : "evidence" })}
                                className={"btn btn" + (this.state.tab === "evidence" ? "" : "-outline") + "-primary"}>
                                Evidence
                            </button>
                        </div>

                        <div style={{ textAlign: "center", marginTop: "0.5rem" }} className="col-1">
                            <FontAwesomeIcon icon={faArrowRightLong} />
                        </div>

                        <div style={{ textAlign: "center" }} className="col-3">
                            <button type="button" onClick={() => this.setState({ tab: this.state.tab === "improve" ? null : "improve" })} className={"btn btn" + (this.state.tab === "improve" ? "" : "-outline") + "-primary"}>Improve</button>
                        </div>
                        <hr style={{ marginTop: "1rem" }} />
                    </div>

                    {/* Understand */}
                    <div className={this.state.tab === "understand" ? "characteristic-body" : "hidden"}>

                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={() => { this.setState({ understandPreview: !this.state.understandPreview }) }}
                        >{this.state.understandPreview ? "Edit" : "Preview"}</button>

                        {!this.state.understandPreview &&
                            <div>
                                <p>Leave blank to hide the understand tab for pupils</p>
                                <p>When adding links, always provide the full link. (e.g. https://www.example.com, not example.com)</p>

                                <Editor
                                    apiKey='7l9t82w8hj52hmtrcd1hiyhwtb49syw3n97hfhii0t3ptne7'
                                    //onInit={(evt, editor) => (editorRef.current = editor)}
                                    initialValue={this.props.characteristicData.understand}

                                    onEditorChange={this.handleEditorChange}
                                    init={{
                                        height: 500,
                                        menubar: true,
                                        plugins:
                                            "searchreplace autolink directionality  visualblocks visualchars image link media table charmap pagebreak nonbreaking anchor advlist lists wordcount help charmap emoticons autosave",
                                        toolbar:
                                            "undo redo spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat",
                                        height: "700px",
                                        toolbar_sticky: true,
                                        icons: "thin",
                                        skin: "material-classic",
                                        icons: "material",
                                        content_style: "material-classic",
                                        browser_spellcheck: true,
                                        contextmenu: false
                                    }}
                                />
                            </div>
                        }
                        {
                            this.props.characteristicData.understand && this.state.understandPreview &&
                            <div>
                                {!this.props.unsavedChanges &&
                                    <div className="alert alert-info">
                                        <h3>Preview</h3>
                                    </div>
                                }

                                <div
                                    dangerouslySetInnerHTML={{ __html: this.props.characteristicData.understand }}
                                />
                            </div>
                        }


                    </div>
                    {/* Evidence */}
                    <div className={this.state.tab === "evidence" ? "characteristic-body" : "hidden"}>

                        <div className="col-12">
                            <br />
                            <button type="button"
                                className="btn btn-outline-secondary"
                                onClick={() => { this.setState({ evidenceAdvancedSettings: !this.state.evidenceAdvancedSettings }) }}
                            >Advanced Settings</button>
                            <br /><br />
                            {this.state.evidenceAdvancedSettings &&
                                <div className="form-group  col-12">

                                    <h6>Ai Marking Settings</h6>
                                    <p>Here you can give specific instructions to the GRACE AI on how you would like it to mark this characteristic.</p>
                                    <p>Main Focus</p>
                                    <Field id={"aiMainFocus" + this.props.characteristicData.id} name={"aiMainFocus" + this.props.characteristicData.id} defaultValue={this.props.characteristicData["aiMainFocus" + this.props.characteristicData.id]} className="form-control" />

                                    <p>Mark Scheme</p>
                                    <Field id={"aiMarkScheme" + this.props.characteristicData.id} name={"aiMarkScheme" + this.props.characteristicData.id} defaultValue={this.props.characteristicData["aiMarkScheme" + this.props.characteristicData.id]} as="textarea" className="form-control" />
                                    <br />
                                    <h6>Add a badge icon</h6>
                                    <p>Enter the exact name of a "font awesome" icon. <a target="_blank" href="https://fontawesome.com/search">Click here to search icons</a>. Copy and paste the name of the icon. (for example, for <a target="_blank" href="https://fontawesome.com/icons/school-lock?f=classic&s=solid">this icon</a> you would enter "school-lock") </p>
                                    <Field id={"icon" + this.props.characteristicData.id} name={"icon" + this.props.characteristicData.id} defaultValue={this.props.characteristicData["icon" + this.props.characteristicData.id]} className="form-control" />
                                    <br />


                                </div>
                            }
                        </div>
                        <div className="alert alert-info">
                            <h3>Preview</h3>
                        </div>
                        <br />
                        <div className="row">
                            <h5>Upload a piece of evidence to prove that you have this characteristic</h5>

                            <div className="col-6">
                                <div className="form-group">
                                    <label>Description</label>
                                    <Field disabled={true} id={"description" + this.props.characteristicData.id} name={"description" + this.props.characteristicData.id} as="textarea" className="form-control" style={{ height: "200px" }} />
                                </div>
                            </div>

                            <div className="col-6">
                                <div className="form-group">
                                    <label for={"source" + this.props.characteristicData.id}>Source</label>
                                    <Field disabled={true} id={"source" + this.props.characteristicData.id} name={"source" + this.props.characteristicData.id} type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Date of evidence</label>
                                    <Field disabled={true} id={"date" + this.props.characteristicData.id} name={"date" + this.props.characteristicData.id} type="text" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <label>Upload evidence</label>

                                </div>

                            </div>




                        </div>
                    </div>

                    {/* Improve */}
                    <div className={this.state.tab === "improve" ? "characteristic-body" : "hidden"} >

                        <div className="row">
                            <div className="form-group  col-9">
                                <label>Suggest an activity that would help a pupil improve</label>
                                <input
                                    className="form-control"
                                    type="text"
                                    value={this.state.improvementText}
                                    onChange={this.handleImprovementTextChange}
                                />
                            </div>

                            <div className="form-group col-3">
                                <label> </label><br />
                                <button onClick={this.handleAddImprovementClick} ype="button" className="btn btn-primary">Add</button>
                            </div>
                        </div>

                        <hr />

                        <div className="alert alert-info">
                            <h3>Preview</h3>
                        </div>
                        <br />


                        <h5>What actions do you need to take to practice and improve this characteristic?</h5>
                        <br />
                        <h6>Enter a task that you could complete. You could set yourself this task later.</h6>
                        <div className="form-group row">
                            <label></label>
                            <Field disabled={true} id={"improvementz" + this.props.characteristicData.id} as="textarea" name={"improvementz"} className="form-control" />
                        </div>
                        <br /><hr />
                        <h6>If you can't think of actions you could take, choose from an example</h6>
                        {improvements}
                    </div>

                </div>
            </div>
        );

    }
}

export default CharacteristicSetup;