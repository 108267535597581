import React, { useState } from "react";
import { Field, Formik, Form } from "formik";
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import app from "./firebase";

const ForgotPasswordForm = ({ email = "" }) => {
    const [emailSent, setEmailSent] = useState(false);
    const auth = getAuth(app);

    const sendResetEmail = async (email) => {
        if (!email) {
            alert("Please enter a valid email.");
            return;
        }
        try {
            await sendPasswordResetEmail(auth, email);
            setEmailSent(true);
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="forgot-password-form">
            

            {emailSent ? (
                <p>A password reset email has been sent if the email is registered.</p>
            ) : (
                <Formik
                    initialValues={{ email }}
                    onSubmit={(values) => sendResetEmail(values.email)}
                >
                    {({ values }) => (
                        <Form>
                            <div className="form-field">
                                <label>Email Address</label>
                                <Field
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Enter your email"
                                />
                            </div>
                            <div className="form-field">
                                <button type="submit" className="btn btn-primary"
                                    onClick={(e) => {
                                        e.preventDefault(); 
                                        sendResetEmail(values.email);
                                    }}>
                                    Send Reset Email
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </div>
    );
};

export default ForgotPasswordForm;
