import React from "react";
import { getStorage, uploadBytes, ref as storageRef, getDownloadURL } from "firebase/storage";
import { getDatabase, ref as dbRef, set, get } from "firebase/database";
import app from "../../firebase";

class EvidenceFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            submission: null,
            storagePath: "",
            imageUrl: null
        };
    }

    componentDidMount() {
        const { schoolData, uid, areaPath, index } = this.props;
        if (schoolData && uid && areaPath && index) {

            const database = getDatabase(app);
            const databasePath = `schools/${this.props.schoolData.path}/submissions/${this.props.areaPath}/${this.props.uid}/values/fileUpload${this.props.index}`;

            get(dbRef(database, databasePath)).then(snapshot => {
                if (snapshot.val() === true) {
        
                    this.setState({
                        storagePath: this.constructStoragePath()
                    }, this.getFileUrl);
                }
            });
        }
    }

    constructStoragePath = () => {
        const { schoolData, uid, areaPath, index } = this.props;
        return "schools/" +
            schoolData.path +
            "/" +
            uid +
            "/" +
            areaPath +
            "/" +
            index +
            ".jpg"
    }

    getFileUrl = () => {
        if (!app) {
            console.error("Firebase app not initialized");
            return;
        }

        const storage = getStorage(app);
        getDownloadURL(storageRef(storage, this.constructStoragePath()))
            .then((url) => {
                this.setState({ imageUrl: url });
                if (this.props.onFileFound) {
                    this.props.onFileFound(this.props.index);
                }
            })
            .catch(console.log);
    };

    uploadFile = (e) => {
        const file = e.target.files[0];

        if (!app) {
            console.error("Firebase app not initialized");
            return;
        }

        const storage = getStorage(app);
        const storageReference = storageRef(storage, this.constructStoragePath());

        uploadBytes(storageReference, file).then(() => {
            this.getFileUrl();

            const database = getDatabase(app);
            const databasePath = `schools/${this.props.schoolData.path}/submissions/${this.props.areaPath}/${this.props.uid}/values/fileUpload${this.props.index}`;
            set(dbRef(database, databasePath), true);
        });
    };

    renderUploadButton = () => {
        const { disabled, index } = this.props;
        const { imageUrl } = this.state;

        if (!disabled) {
            return (
                <div>
                    <label
                        style={{ width: "100%" }}
                        className={`btn ${imageUrl ? "btn-success" : "btn-primary"}`}
                        htmlFor={`fileUpload${index}`}
                    >
                        {imageUrl ? "Replace Image" : "Upload Image"}
                    </label>
                    <input
                        className="hidden"
                        type="file"
                        id={`fileUpload${index}`}
                        value={this.state.file}
                        onChange={this.uploadFile}
                        accept="image/*"
                    />
                </div>
            );
        }
    }

    render() {
        const { imageUrl } = this.state;

        return (
            <div>
                {this.renderUploadButton()}
                {imageUrl && <img width="100%" src={imageUrl} alt="Uploaded Image" />}
            </div>
        );
    }
}

export default EvidenceFile;
