import React, { useState, useMemo, useEffect } from "react";
import { Formik, Form } from "formik";
import segmentTypes from "../../teacher/settings/segmentTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { 
  faStar, faTrophy, faCheckCircle, faTimesCircle, 
  faArrowRight, faArrowLeft, faRocket, faPuzzlePiece,
  faGamepad, faChild, faLightbulb, faQuestionCircle
} from "@fortawesome/free-solid-svg-icons";
import Confetti from "react-confetti";

// Helper to shuffle an array
const shuffleArray = (array) => {
  const arr = [...array];
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [arr[i], arr[j]] = [arr[j], arr[i]];
  }
  return arr;
};

const StudentModule = ({ moduleData }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [showCelebration, setShowCelebration] = useState(false);

  // Create a randomized copy of the module for student display.
  const randomizedModule = useMemo(() => {
    if (!moduleData) return null;
    return {
      ...moduleData,
      segments: moduleData.segments.map((segment) => {
        switch (segment.type) {
          case "SORT":
            return {
              ...segment,
              displaySortItems: shuffleArray(
                (segment.sortItems || []).map((item) => item.text)
              )
            };
          case "CATEGORISE":
            return {
              ...segment,
              displayItems: shuffleArray(segment.items || [])
            };
          case "MATCH":
            return {
              ...segment,
              shuffledRightOptions: shuffleArray(
                (segment.pairs || []).map((pair) => pair.right)
              )
            };
          case "CHOOSE":
            return {
              ...segment,
              displayOptions: shuffleArray(segment.options || [])
            };
          default:
            return segment;
        }
      })
    };
  }, [moduleData]);

  // Build initial answers based on the randomized module.
  const initialAnswers = useMemo(() => {
    if (!randomizedModule) return { segments: [] };
    return {
      segments: randomizedModule.segments.map((segment) => {
        if (segment.type === "CHOOSE") {
          const numCorrect = (segment.displayOptions || []).filter(
            (opt) => opt.isCorrect
          ).length;
          return numCorrect === 1
            ? { selectedOption: "" }
            : { selectedOptions: [] };
        } else if (segment.type === "CATEGORISE") {
          return { answers: (segment.displayItems || []).map(() => "") };
        } else if (segment.type === "SORT") {
          return { sortOrder: segment.displaySortItems ? [...segment.displaySortItems] : [] };
        } else if (segment.type === "MATCH") {
          return { matches: (segment.pairs || []).map(() => "") };
        } else {
          return {};
        }
      })
    };
  }, [randomizedModule]);

  // Calculate total slides (introduction + preparation + segments + final score)
  const totalSlides = useMemo(() => {
    if (!randomizedModule) return 0;
    return 2 + randomizedModule.segments.length + 1;
  }, [randomizedModule]);

  // Evaluate each segment on submission using the evaluation functions defined in segmentTypes.
  const handleSubmit = (values) => {
    const newFeedback = randomizedModule.segments.map((segment, index) => {
      const typeConfig = segmentTypes[segment.type];
      if (typeConfig && typeConfig.evaluate) {
        return typeConfig.evaluate(segment, values.segments[index]);
      }
      return null;
    });
    setFeedback(newFeedback);
    setSubmitted(true);
    
    // Calculate score to see if we should celebrate
    const evaluable = newFeedback.filter((fb) => fb !== null);
    const correctCount = evaluable.filter((fb) => fb === true).length;
    if (correctCount / evaluable.length >= 0.7) { // Celebrate if 70% or more correct
      setShowCelebration(true);
    }
    
    // Move to results slide
    setCurrentSlide(totalSlides - 1);
    console.log("Student Answers: ", values);
  };

  // Compute overall score.
  const overallScore = useMemo(() => {
    if (!feedback.length) return null;
    const evaluable = feedback.filter((fb) => fb !== null);
    const correctCount = evaluable.filter((fb) => fb === true).length;
    return { correct: correctCount, total: evaluable.length };
  }, [feedback]);

  // Define dimensions for confetti component
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  // Update dimensions on window resize
  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Star animation class for celebration
  const starAnimationClass = showCelebration ? 'animated-trophy pulse' : '';

  const goToNextSlide = () => {
    if (currentSlide < totalSlides - 1) {
      setCurrentSlide(currentSlide + 1);
    }
  };

  const goToPreviousSlide = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1);
    }
  };

  if (!randomizedModule) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ minHeight: "300px" }}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container py-4">
      {/* Progress Bar */}
      <div className="progress mb-4" style={{ height: '25px' }}>
        <div 
          className="progress-bar progress-bar-striped progress-bar-animated bg-success" 
          role="progressbar" 
          style={{ width: `${(currentSlide / (totalSlides - 1)) * 100}%` }}
          aria-valuenow={(currentSlide / (totalSlides - 1)) * 100}
          aria-valuemin="0" 
          aria-valuemax="100"
        >
          {Math.round((currentSlide / (totalSlides - 1)) * 100)}% Complete
        </div>
      </div>

      <div className="card shadow-lg border-0" style={{ borderRadius: '15px', overflow: 'hidden' }}>
        <div className="card-header bg-primary text-white d-flex align-items-center p-3">
          <FontAwesomeIcon icon={faGamepad} className="me-2" size="lg" />
          <h1 className="mb-0">{randomizedModule.name}</h1>
        </div>

        {showCelebration && (
          <Confetti
            width={dimensions.width}
            height={dimensions.height}
            recycle={false}
            numberOfPieces={200}
            gravity={0.2}
          />
        )}
        <div className="card-body p-0" style={{ minHeight: '400px', position: 'relative' }}>
          <Formik initialValues={initialAnswers} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Form style={{ height: '100%' }}>
                {currentSlide === 0 && (
                  <div 
                    className="slide-container"
                    style={{
                      position: 'absolute',
                      width: '100%',
                      padding: '20px'
                    }}
                  >
                    {/* Introduction slide */}
                    <div className="text-center">
                      <div className="mb-4">
                        <FontAwesomeIcon icon={faRocket} size="5x" className="text-primary mb-3" />
                        <h2 className="fw-bold">Welcome to Your Adventure!</h2>
                      </div>

                      <div className="mb-4 p-3 bg-light rounded-3">
                        <h3 className="mb-3">
                          <FontAwesomeIcon icon={faLightbulb} className="text-warning me-2" />
                          Investigation
                        </h3>
                        <div 
                          className="lead"
                          dangerouslySetInnerHTML={{ __html: randomizedModule.investigation }}
                        />
                      </div>

                      <button 
                        type="button" 
                        className="btn btn-lg btn-primary rounded-pill"
                        onClick={goToNextSlide}
                      >
                        Let's Begin! <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                      </button>
                    </div>
                  </div>
                )}

                {currentSlide === 1 && (
                  // Preparation slide
                  <div>
                    <div className="text-center mb-4">
                      <FontAwesomeIcon icon={faPuzzlePiece} size="4x" className="text-success mb-3" />
                      <h2 className="fw-bold">Preparation</h2>
                    </div>

                    <div className="row g-4 mb-4">
                      <div className="col-md-6">
                        <div className="card h-100 border-primary shadow-sm">
                          <div className="card-header bg-primary text-white">
                            <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                            <strong>Your Mission</strong>
                          </div>
                          <div className="card-body">
                            <p className="card-text">{randomizedModule.preparation.openingQuestion}</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card h-100 border-warning shadow-sm">
                          <div className="card-header bg-warning text-dark">
                            <FontAwesomeIcon icon={faChild} className="me-2" />
                            <strong>Your Goal</strong>
                          </div>
                          <div className="card-body">
                            <p className="card-text">{randomizedModule.preparation.purpose}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="card mb-4 border-info shadow-sm">
                      <div className="card-header bg-info text-white">
                        <strong>Materials</strong>
                      </div>
                      <div className="card-body">
                        <p 
                          className="card-text"
                          dangerouslySetInnerHTML={{ __html: randomizedModule.preparation.material }}
                        />
                      </div>
                    </div>

                    <div className="alert alert-warning">
                      <FontAwesomeIcon icon={faLightbulb} className="me-2" />
                      <strong>Time:</strong> You have {randomizedModule.preparation.preparationTime} minutes to complete this activity!
                    </div>

                    <div className="d-flex justify-content-between mt-4">
                      <button 
                        type="button" 
                        className="btn btn-outline-secondary rounded-pill"
                        onClick={goToPreviousSlide}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Back
                      </button>
                      <button 
                        type="button" 
                        className="btn btn-primary rounded-pill"
                        onClick={goToNextSlide}
                      >
                        Start Tasks <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                      </button>
                    </div>
                  </div>
                )}

                {currentSlide >= 2 && currentSlide < 2 + randomizedModule.segments.length && (
                  // Segment slides
                  (() => {
                    const segmentIndex = currentSlide - 2;
                    const segment = randomizedModule.segments[segmentIndex];
                    const typeConfig = segmentTypes[segment.type];
                    
                    return (
                      <div>
                        <div className="text-center mb-4">
                          <div className="badge bg-primary rounded-pill p-2 mb-3">
                            Task {segmentIndex + 1} of {randomizedModule.segments.length}
                          </div>
                          <h2>
                            <FontAwesomeIcon 
                              icon={
                                segment.type === "SORT" ? faPuzzlePiece :
                                segment.type === "CHOOSE" ? faCheckCircle :
                                segment.type === "MATCH" ? faGamepad :
                                faQuestionCircle
                              } 
                              className="me-2 text-primary" 
                            />
                            {segment.type} Challenge
                          </h2>
                        </div>

                        <div className="card shadow-sm mb-4">
                          <div className="card-body">
                            <h3 className="card-title">{segment.question}</h3>
                            <div className="card-text my-4">
                              {typeConfig && typeConfig.renderStudent ? (
                                typeConfig.renderStudent({
                                  segment,
                                  index: segmentIndex,
                                  values,
                                  setFieldValue
                                })
                              ) : (
                                <div className="alert alert-danger">
                                  Oops! This type of challenge isn't ready yet.
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between mt-4">
                          <button 
                            type="button" 
                            className="btn btn-outline-secondary rounded-pill"
                            onClick={goToPreviousSlide}
                          >
                            <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                            Previous
                          </button>
                          {segmentIndex === randomizedModule.segments.length - 1 ? (
                            <button 
                              type="submit" 
                              className="btn btn-success rounded-pill"
                            >
                              Submit All Answers <FontAwesomeIcon icon={faTrophy} className="ms-2" />
                            </button>
                          ) : (
                            <button 
                              type="button" 
                              className="btn btn-primary rounded-pill"
                              onClick={goToNextSlide}
                            >
                              Next Task <FontAwesomeIcon icon={faArrowRight} className="ms-2" />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })()
                )}

                {currentSlide === totalSlides - 1 && submitted && (
                  // Results slide
                  <div className="text-center">
                    <div className="mb-4">
                      {showCelebration ? (
                        <div className={starAnimationClass}>
                          <FontAwesomeIcon icon={faTrophy} size="5x" className="text-warning mb-3" />
                        </div>
                      ) : (
                        <FontAwesomeIcon icon={faStar} size="5x" className="text-secondary mb-3" />
                      )}
                      
                      <h2 className="fw-bold">
                        {showCelebration ? "Awesome Job!" : "Task Complete!"}
                      </h2>
                    </div>

                    <div className="card shadow-sm mb-4">
                      <div className="card-body">
                        <h3 className="card-title mb-4">Your Results</h3>
                        
                        {overallScore && (
                          <div className="mb-4">
                            <div className="progress" style={{ height: '30px' }}>
                              <div 
                                className={`progress-bar ${overallScore.correct / overallScore.total >= 0.7 ? 'bg-success' : 'bg-warning'}`} 
                                role="progressbar" 
                                style={{ width: `${(overallScore.correct / overallScore.total) * 100}%` }}
                                aria-valuenow={(overallScore.correct / overallScore.total) * 100}
                                aria-valuemin="0" 
                                aria-valuemax="100"
                              >
                                {Math.round((overallScore.correct / overallScore.total) * 100)}%
                              </div>
                            </div>
                            <h4 className="mt-2">
                              <strong>Score: {overallScore.correct} / {overallScore.total}</strong>
                            </h4>
                          </div>
                        )}

                        <div className="row">
                          {randomizedModule.segments.map((segment, index) => (
                            <div className="col-md-6 mb-3" key={index}>
                              <div 
                                className={`card h-100 border-${feedback[index] ? 'success' : 'danger'}`}
                              >
                                <div 
                                  className={`card-header bg-${feedback[index] ? 'success' : 'danger'} text-white d-flex justify-content-between align-items-center`}
                                >
                                  <span>Task {index + 1}</span>
                                  <FontAwesomeIcon 
                                    icon={feedback[index] ? faCheckCircle : faTimesCircle} 
                                  />
                                </div>
                                <div className="card-body">
                                  <p className="card-text">{segment.question}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center mt-4">
                      <button 
                        type="button" 
                        className="btn btn-lg btn-outline-primary rounded-pill"
                        onClick={() => {
                          setCurrentSlide(0);
                          setSubmitted(false);
                          setShowCelebration(false);
                        }}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2" />
                        Start Over
                      </button>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      
      {/* CSS for animations */}
      <style>{`
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.2) rotate(10deg); }
          100% { transform: scale(1); }
        }
        
        .animated-trophy {
          display: inline-block;
        }
        
        .pulse {
          animation: pulse 1s infinite;
        }
        
        .slide-container {
          transition: all 0.5s ease;
        }
      `}</style>
    </div>
  );
};

export default StudentModule;
