// segmentTypes.js
import React from "react";
import { Field, FieldArray } from "formik";
import { 
  ChooseStudentRenderer,
  CategoriseStudentRenderer,
  SortStudentRenderer,
  MatchStudentRenderer 
} from "./segmentRenderers";

const segmentTypes = {
  CHOOSE: {
    label: "Choose",
    fields: [
      { name: "question", label: "Question", type: "text" }
    ],
    arrays: {
      options: {
        label: "Options",
        addButtonLabel: "Add Option",
        defaultItem: { text: "", isCorrect: false },
        itemFields: [
          { name: "text", label: "Option text", type: "text" },
          { name: "isCorrect", label: "Correct", type: "checkbox" }
        ]
      }
    },
    // Assign the imported component
    renderStudent: ChooseStudentRenderer,
    evaluate: (segment, studentResponse) => {
      const correctOptions = (segment.displayOptions || [])
        .filter(opt => opt.isCorrect)
        .map(opt => opt.text);
      if (correctOptions.length === 1) {
        return studentResponse.selectedOption === correctOptions[0];
      } else {
        const studentAnswers = studentResponse.selectedOptions || [];
        return (
          studentAnswers.length === correctOptions.length &&
          studentAnswers.every(ans => correctOptions.includes(ans))
        );
      }
    }
  },
  CATEGORISE: {
    label: "Categorise",
    fields: [
      { name: "question", label: "Question", type: "text" }
    ],
    arrays: {
      categories: {
        label: "Categories",
        addButtonLabel: "Add Category",
        defaultItem: { name: "" },
        itemFields: [
          { name: "name", label: "Category Name", type: "text" }
        ]
      },
      items: {
        label: "Items",
        addButtonLabel: "Add Item",
        defaultItem: { text: "", correctCategory: "" },
        itemFields: [
          { name: "text", label: "Item Text", type: "text" },
          {
            name: "correctCategory",
            label: "Correct Category",
            type: "select",
            optionsField: "categories",
            optionFieldName: "name"
          }
        ]
      }
    },
    renderStudent: CategoriseStudentRenderer,
    evaluate: (segment, studentResponse) => {
      return (segment.displayItems || []).every((item, i) =>
        studentResponse.answers[i] === item.correctCategory
      );
    }
  },
  SORT: {
    label: "Sort",
    fields: [
      { name: "question", label: "Question", type: "text" }
    ],
    arrays: {
      sortItems: {
        label: "Sort Items (in correct order)",
        addButtonLabel: "Add Sort Item",
        defaultItem: { text: "" },
        itemFields: [
          { name: "text", label: "Item text", type: "text" }
        ]
      }
    },
    renderStudent: SortStudentRenderer,
    evaluate: (segment, studentResponse) => {
      const correctOrder = (segment.sortItems || []).map(item => item.text);
      return (
        JSON.stringify(studentResponse.sortOrder) ===
        JSON.stringify(correctOrder)
      );
    }
  },
  MATCH: {
    label: "Match",
    fields: [
      { name: "question", label: "Question", type: "text" }
    ],
    arrays: {
      pairs: {
        label: "Match Pairs",
        addButtonLabel: "Add Pair",
        defaultItem: { left: "", right: "" },
        itemFields: [
          { name: "left", label: "Left side", type: "text" },
          { name: "right", label: "Right side", type: "text" }
        ]
      }
    },
    renderStudent: MatchStudentRenderer,
    evaluate: (segment, studentResponse) => {
      return (segment.pairs || []).every(
        (pair, i) => studentResponse.matches[i] === pair.right
      );
    }
  }
};

export default segmentTypes;
