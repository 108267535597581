import React from "react";
import { signOutUser } from "../signOutUser";

export function wrongRoleTeacherWarning() {
  return (
    <div className="alert alert-warning text-center">
      <h2 className="mb-3">Teacher account still logged in</h2>
      <p className="mb-3">
        It looks like you may be trying to test your school's student link.
      </p>
      <p>
        <button className="btn btn-primary" onClick={() => signOutUser()}>
          Sign out of your teacher account
        </button>{" "}
        first if you want to test the student app.
      </p>
    </div>
  );
}
