import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import CheckoutButton from "./checkoutButton";
import { Link } from "react-router-dom";

const CharlieBotPaymentPage = ({ user }) => {

  const school = user.school && user.school !== "mrCharlieBot" ? user.school : null

  return (
    <div className="container py-5">
      <div className="text-center mb-5">
        <img style={{ height: "20vh" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" />

        {school && <><br /><Link to={`/student/${school}`}>
          <button className="btn btn-primary btn-lg">Go to your school account</button><br/>
        </Link><br/></>}
        <p className="fs-5 text-primary">
          {school ? "or c" : "C"}hoose your subscription plan {school && "to use Mr Charlie Bot outside of school"}
        </p>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-5">
          {/* Monthly Plan */}
          <div className="card shadow mb-4">
            <div className="card-body p-4">
              <h2 className="card-title fw-bold mb-3">Monthly Plan</h2>
              <h3 className="display-6 mb-3">£35 <span className="fs-5 text-muted">/ month</span>
              </h3>
              <p className="card-text text-muted mb-4">
                Subscribe with month-to-month payments.
              </p>
              <CheckoutButton uid={user.uid} buttonClass={"btn btn-primary w-100 py-2"} buttonText={"Choose Monthly"} />
            </div>
          </div>
        </div>
        <div className="col-md-5">
          {/* Annual Plan */}
          <div className="card shadow mb-4 border-primary">
            <div className="card-body p-4 position-relative">
              <span className="badge bg-primary position-absolute top-0 end-0 m-3 fs-6">Best Value</span>
              <h2 className="card-title fw-bold mb-3">Annual Plan</h2>
              <h3 className="display-6 mb-3">£30 <span className="fs-5 text-muted">/ month (billed annually)</span></h3>
              <p className="card-text text-muted mb-4">
                Save £60 per year by subscribing annually.
              </p>
              <CheckoutButton uid={user.uid} buttonClass={"btn btn-success w-100 py-2"} buttonText={"Choose Annual"} />
            </div>
          </div>

        </div>
      </div>
      <div className="text-center mt-5">
        <p className="text-success">
          <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" />
          Secure checkout - Cancel anytime
        </p>
      </div>

    </div>
  );
};


export default CharlieBotPaymentPage;
