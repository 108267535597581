import React from "react";
import { Link } from 'react-router-dom';
import SignIn from "../signIn.js";

export function StudentHomeLoggedOut({ mrCharlieBot }) {
    return (
        <div style={{ margin: "4rem" }}>
            <div style={{ marginBottom: "2rem", textAlign: "right" }}>
                Don't have an account yet?{" "}
                <Link to={"signUp"}>
                    <button className="btn btn-outline-primary">Sign up</button>
                </Link>
            </div>
            <SignIn mrCharlieBot={mrCharlieBot} />
        </div>
    );
}
