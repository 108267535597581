import React from "react";
import AreaIcon from "./areaIcon.js"
import NeneParkIcon from "./neneParkIcon.js";

class Areas extends React.Component {

  constructor(props) {
    super(props);
    this.state = { areas: 6 }
  }

  componentDidMount() {
    console.log("areas", this.props.user)



  }

  render() {
    let areasData = this.props.areaData;
    let schoolData = this.props.schoolData
    let filteredAreas = areasData
    
    let customAreasData = null

    if (schoolData.stage === "secondary" || schoolData.stage === "primary") {

      filteredAreas = areasData.filter(item => item.stage === schoolData.stage);

    } else if (schoolData.stage === "special") {

      //Do something else for special schools
    }

    customAreasData = areasData.filter(item => item.stage === "custom");
    customAreasData.sort((a, b) => a.sortOrder - b.sortOrder);


    let areas = [];
    console.log("areas data", filteredAreas)
    for (let i = 0; filteredAreas.length > i; i++) {

      areas.push(
        <div style={{textAlign:"center"}} className="col-lg-2 col-md-2 col-sm-4 col-xs-4"><AreaIcon schoolData={schoolData} user={this.props.user} area={filteredAreas[i]} key={i} /></div>
      )

    }

    let customAreas = [];
    for (let i = 0; customAreasData.length > i; i++) {

      customAreas.push(
        <div className="col"><AreaIcon schoolData={schoolData} user={this.props.user} area={customAreasData[i]} key={i} /></div>
      )

    }

    //To-do tidy this up, for nene park only!
    if (schoolData.path === "mzoZkNiM6L" || schoolData.path === "SZElqJ2fJz")
      customAreas.push(
        <div className="col"><NeneParkIcon schoolData={schoolData} user={this.props.user} key={customAreas.length + 1} /></div>
      )

    return (
      <div>
        <div className="container">
          <div style={{ marginTop: "2rem" }} className="row">
            {customAreas}
          </div>

          {!this.props.schoolData.hideWorldClassAreas && <>
            {customAreas &&
              <div style={{ marginTop: "2rem" }} className="row">
                <hr />
                <h4>World Class Areas</h4>
              </div>
            }
            <div className="row">
              {areas}

            </div>
          </>}
        </div>
        {/*JSON.stringify(this.props, null, '\t')*/}
      </div>
    );

  }
}

export default Areas;