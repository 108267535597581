import React from "react";
import StudentNavBar from "./navBar.js";
import LoggedInHome from "./homePage/loggedIn/loggedInHome.js"
import { getDatabase, ref, onValue } from "firebase/database";
import app from "../firebase";
import AreaPage from "./areaPage/areaPage.js"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import SignUpStudent from "./signUpStudent.js";
import areasConfig from "../areasConfig.js";
import {StudentHomeLoggedOut}  from "./studentHomeLoggedOut";
import StudentPortfolioPage from "./studentPortfolioGetArea.js";
import StudentPrivacyPolicy from "./studentPrivacyPolicy.js";
import NextStepsPage from "./nextSteps/nextStepsPage.js";
import CharlieMainMenu from "../mrcharliebot/charlieMainMenu.js";


class StudentPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            areaData: areasConfig
        }
    }

    componentDidMount() {

        console.log(this.props.user)
        this.addCustomArea()

    }

    addCustomArea = () => {
        const data = this.props.schoolData.areaConfig;
        console.log("custom area from props", data);

        if (data) {
            // Assuming areasData is already available in the state or as a prop
            let areasData = areasConfig; // or replace with areasConfig if available

            const areaKeys = Object.keys(data);
            for (let i = 0; i < areaKeys.length; i++) {
                const currentArea = data[areaKeys[i]];

                // Add the area of this area key to the areasData only if it's unique
                if (!areasData.some(area => area.path === currentArea.path)) {
                    areasData.push(currentArea);
                }
            }

            this.setState({ areasData: areasData });
        }
    }




    render() {

        return (
            <div className="student-page">
                <style> {this.props.schoolData.styleSheet}
                </style>
                {!this.props.mrCharlieBot &&
                    <StudentNavBar user={this.props.user} schoolData={this.props.schoolData} />
                }

                <Routes>
                    <Route path='/privacy*' element={<StudentPrivacyPolicy schoolData={this.props.schoolData} />} />
                </Routes>

                {this.props.user && !this.props.mrCharlieBot &&
                    <Routes>
                        <Route path='/area/:areaPath/*' element={<AreaPage schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                        <Route path='/improve' element={<NextStepsPage schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                        <Route path='/signUp' element={<div style={{ textAlign: "center", marginTop: "3rem" }}> <Link className="btn btn-primary" to={"../"}>Continue to account </Link> </div>} />
                        <Route path='/' element={<LoggedInHome schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                    </Routes>
                }

                {this.props.user && this.props.mrCharlieBot &&
                    <Routes>
                        <Route path='/signUp' element={<div style={{ textAlign: "center", marginTop: "3rem" }}> <Link className="btn btn-primary" to={"../"}>Continue to account </Link> </div>} />
                        <Route path='*' element={<CharlieMainMenu schoolData={this.props.schoolData} user={this.props.user} areaData={this.state.areaData} />} />
                    </Routes>
                }



                {!this.props.user &&
                    <div style={{ textAlign: "center" }}>
                        <h2 className={`school-name ${this.props.mrCharlieBot && "text-light"}`}>{this.props.schoolData.name}</h2>

                        <h1 className="tag-line" style={{ marginTop: "3rem" }}>{this.props.schoolData.tagLine}</h1>
                        <Routes>
                            <Route path='/signUp' element={<SignUpStudent schoolData={this.props.schoolData} />} />
                            <Route path='/*' element={<StudentHomeLoggedOut mrCharlieBot={this.props.mrCharlieBot} />} />
                        </Routes>
                    </div>

                }


            </div>
        );
    }
}

export default StudentPage;


