import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import ChangePasswordForm from "../changePasswordForm";
import ChangeEmailForm from "../changeEmailForm";
import ChangeDetailsForm from "../changeDetailsForm";
import { signOutUser } from "../signOutUser";
import ManageSubscription from "../mrcharliebot/manageSubscription";
//import { useFirebase } from 'your-firebase-package'; // import the Firebase hooks you're using

const StudentNavBar = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [passwordChangeOpen, setPasswordChangeOpen] = useState(false);
  const [emailChangeOpen, setEmailChangeOpen] = useState(false);
  const [detailsChangeOpen, setDetailsChangeOpen] = useState(false);
  const [subscriptionChangeOpen, setSubscriptionChangeOpen] = useState(false);

  const handleLogout = () => {
    signOutUser()
  };

  const openChangePassword = () => {
    setPasswordChangeOpen(true); // Open the password change form
  };

  const openChangeEmail = () => {
    setEmailChangeOpen(true); // Open the password change form
  };

  const openChangeDetails = () => {
    setDetailsChangeOpen(true); // Open the password change form
  };

  const openChangeSubscription = () => {
    setSubscriptionChangeOpen(true); // Open the password change form
  };

  const closePasswordChange = () => {
    setPasswordChangeOpen(false); // Close the password change form
  };

  const closeEmailChange = () => {
    setEmailChangeOpen(false); // Close the password change form
  };

  const closeDetailsChange = () => {
    setDetailsChangeOpen(false); // Close the password change form
  };

  const closeSubscriptionChange = () => {
    setSubscriptionChangeOpen(false); // Close the password change form
  };

  const schoolPath = (props.schoolData?.path && props.schoolData?.path !== "mrCharlieBot") ? props.schoolData.path : null;

  return (
    <nav className={`navbar navbar-dark ${!props.charliebot ? "bg-dark" : ""} no-print `} style={{ marginBottom: "20px",minHeight:"50px" }  }>
      {props.charliebot ? <Link className="navbar-brand" to={!schoolPath ? "/" : `/student/${schoolPath}`}>
        <img style={{ marginLeft: "10px" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" height="50px" alt="" />
        </Link> :


        <Link className={`navbar-brand `} to={"/student/" + schoolPath}>
          <img style={{ marginLeft: "10px" }} src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png" height="50px" alt="" />
        </Link>

      }

      {props.user && !props.schoolData.demo &&  (

        <div className="dropdown">
          <DropdownButton variant="secondary" style={{ marginLeft: "-40px", }} id="dropdown-basic-button" title={
            <FontAwesomeIcon icon={faCog} /> && props.user
              ? <><span className="nav-name"> {props.user.firstName} {props.user.lastName} </span> <FontAwesomeIcon icon={faCog} /> </> :
              ""}>
            <Dropdown.Item onClick={openChangeDetails}>Change Details</Dropdown.Item>
            <Dropdown.Item onClick={openChangePassword}>Change Password</Dropdown.Item>
            <Dropdown.Item onClick={openChangeEmail}>Change Email Address</Dropdown.Item>
            {props.charliebot && !schoolPath &&
              <Dropdown.Item onClick={openChangeSubscription}>Manage Subscription</Dropdown.Item>
            }
            <Dropdown.Item onClick={handleLogout}>Log out</Dropdown.Item>
          </DropdownButton>
        </div>
      )}

      {passwordChangeOpen && <ChangePasswordForm closePasswordChange={closePasswordChange} />}
      {emailChangeOpen && <ChangeEmailForm closeEmailChange={closeEmailChange} />}
      {detailsChangeOpen && <ChangeDetailsForm user={props.user} schoolData={props.schoolData} closeDetailsChange={closeDetailsChange} charliebot={props.charliebot} />}
      {subscriptionChangeOpen && <ManageSubscription user={props.user} schoolData={props.schoolData} closeSubscriptionManagement={closeSubscriptionChange} charliebot={props.charliebot} />}

    </nav>
  );
};

export default StudentNavBar;
