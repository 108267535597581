import React from "react";
import Robot from "../../robot";


const DemoLandingPage = ({ schoolData }) => {
    return (
        <div className="tag-line" style={{ backgroundColor: "white" }}>
            {/* Hero Section */}
            <header
                className="hero-section text-center d-flex align-items-center"
            >
                <div className="container">
                    <div
                        className="tag-line display-7 font-weight-bold"
                        style={{ paddingTop: "3rem", paddingBottom: "3rem" }}
                        dangerouslySetInnerHTML={{ __html: schoolData.demoIntro }}
                    />
                </div>
            </header>

            {/* How It Works Section */}
            <section className="container py-5" id="demo">

                <div className="text-center mb-5">
                    <h2 className="font-weight-bold">How BeWorldClass Works</h2>
                    <p> Try your platform by selecting one of the values above, or see below to find out more about how it all works.</p>
                </div>
                <div className="row" style={{ marginBottom: "5rem" }}>
                    {/* Section 1: Reflection and Goal Setting */}
                    <div className="col-md-4 text-center mb-4" >
                        <div style={{ padding: "3rem" }} className="img-fluid rounded mb-3 shadow">
                            <img
                                src={schoolData.logoUrl}
                                alt="logo"
                                style={{ maxHeight: "200px", maxWidth: "100%" }}

                            />
                            <h3> Values </h3>
                        </div>

                    </div>

                    <div className="col-md-6"  >
                        <h3 className="h5 font-weight-bold">Your Values</h3>
                        <p>
                            {schoolData.importantDemoValues ? schoolData.importantDemoValues : `The ${schoolData.name} values are setting pupils up to thrive in both life and their careers. Every school has its own unique values based on its context and asperations, which is why we create a custom platform tailored to each school. What you're seeing in the demo is just an initial concept. If you choose BeWorldClass, we will work closely with you to ensure it meets your exact needs and vision.`}
                            <br />
                            <br />
                            <p>This platform is designed to take all the seperate experiences pupils have at {schoolData.name}, and combine them through reflection, so that pupils can embed the whole narrative of personal development in line with your values.</p>
                        </p>

                    </div>


                </div>


                <div className="row" style={{ marginBottom: "5rem" }}>

                    {/*reflection */}
                    <div className="col-md-4 text-center mb-4"  >
                        <div style={{ marginLeft: "15rem", cursor: "none" }}>
                            <Robot messageText={"Try submitting some evidence in the platform to see how automatic feedback works"} thinking={false} />
                        </div>

                        {/* <img
                            src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2023/06/Grace-Maths.png?w=1347&ssl=1"
                            alt="Reflection"
                            className="img-fluid rounded mb-3 shadow"
                            style={{ maxHeight: "300px" }}
                        /> */}
                    </div>




                    <div className="col-md-8">

                        <h3 className="h5 font-weight-bold">Reflection</h3>
                        <p>
                            Research shows that reflecting on achievements and actions is crucial for embedding both learning and character development.
                        </p>
                        <p>
                            Our AI assistant, GRACE, encourages students to delve deeper in their reflection, while they learn to advocate for themselves professionally. To try GRACE on your demo platform, try submitting some evidence!
                        </p>
                        <p>
                            GRACE can save up to 5000 hours of teacher time per school compared with manual marking, so tutors can spend their time coaching and planning next steps, not gathering information.
                        </p>
                    </div>

                </div>
                <div className="row" style={{ marginBottom: "5rem" }}>
                    {/* Section 3: Demonstrate Impact */}
                    <div className="col-md-4 text-center mb-4">
                        <img
                            src="https://beworldclass.org/wp-content/uploads/2025/02/1739535675518.jpg"
                            alt="Demonstrate Impact"
                            className="img-fluid rounded mb-3 shadow"
                            style={{ maxHeight: "300px" }}
                        />
                    </div>
                    <div className="col-md-8">

                        <h3 className="h5 font-weight-bold">Data Insights</h3>
                        <p> Using AI analysis, BeWorldClass can analyse the qualitative data embedded in millions of words written by students about their character development, providing precise insights to inform school strategies and funding decisions.</p>

                        <p>A comprehensive school-wide analysis of the who, what, where, when, and how of character development is now achievable through this new technology.</p>


                    </div>
                </div>
                <div className="row" style={{ marginBottom: "5rem" }}>

                    {/* Section 3: Demonstrate Impact */}
                    <div className="col-md-4 text-center mb-4">
                        <img
                            src="https://beworldclass.org/wp-content/uploads/2025/02/Screenshot-2025-02-21-183857.png"
                            alt="Demonstrate Impact"
                            className="img-fluid rounded mb-3 shadow"
                        />
                    </div>
                    <div className="col-md-8">

                        <h3 className="h5 font-weight-bold">Demonstrate Impact</h3>
                        <p>
                            Schools have traditionally struggled to showcase the impact of character and holistic initiatives. BeWorldClass provides tools to share insights in a secure way, enabling clear communication of results to all stakeholders.
                        </p>
                        <p>
                            From social media posts to detailed reporting to governers and stakeholders, we ensure that the value of character development is both visible and verifiable.
                        </p>
                    </div>
                </div>


                {/* Section 2: Data Insights */}
                <div className="col-md-12 text-center mb-4">
                    <h3 className="h5 font-weight-bold">View a demo of the staff interface</h3>
                    <iframe src="https://drive.google.com/file/d/16rncDt01dE96DLwYDICbspqIk9FBPM7x/preview" width="100%" height="480" allow="autoplay"></iframe>
                </div>

            </section >

            {/* About Us Section */}
            < section className="about-us bg-light py-5" id="about" >
                <div className="container">
                    <h2 className="text-center font-weight-bold mb-4">About us</h2>
                    <p>
                        We are a group of experienced educationalists and technology experts from a variety of backgrounds, united in our passion to equip students with the skills, competencies, and character traits necessary to thrive in an ever-evolving global economy.
                    </p>
                    <p>
                        BeWorldClass was developed in collaboration with the staff and students of some of the world’s finest schools, those accredited by the{" "}
                        <strong>World Class Schools Quality Mark</strong>. We would be delighted to work with {schoolData.name} as you prepare your pupils for a world that values skills and character more than academic qualifications.
                    </p>

                    <a href="https://beworldclass.org/about-us/" target="_blank">Find out more about our team</a>

                </div>
            </section >

            < section className="about-us bg-light py-5" id="about" >
                {!schoolData.hidePrice &&
                    <div className="container">
                        <h2 className=" font-weight-bold mb-4">Price</h2>
                        <p className=" font-weight-bold">
                            BeWorldClass is only {schoolData.stage === "primary" ? "£475" : "£1475"} per year and includes accounts for all pupils and staff within your school.
                        </p>

                        <p className=" font-weight-bold">
                            We also offer discounts for Multi Academy Trusts.
                        </p>


                        {schoolData.studentNumberEstimate &&
                            <p className="display-6 font-weight-bold">
                                For a school the size of {schoolData.name}, this works out to approximately <br />
                                <u> £{(schoolData.stage === "primary" ? 475 / schoolData.studentNumberEstimate : 1475 / schoolData.studentNumberEstimate).toFixed(2)}</u>{' '}
                                per pupil!
                            </p>

                        }


                    </div>
                }
            </section >


        </div >
    );
};

export default DemoLandingPage;
