import React from "react";
import ImprovedGrid from "./grid/improvedGrid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTable } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';

class NeneParkGridDashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubmissions: [],
            selectedArea: null,
            selectedLevel: null
        };
    }

    componentDidMount() {
        this.modifySubmissions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.extendedSubmissions !== this.props.extendedSubmissions ||
            prevState.selectedArea !== this.state.selectedArea ||
            prevState.selectedLevel !== this.state.selectedLevel
        ) {
            this.modifySubmissions();
        }
    }

    modifySubmissions = () => {
        const { extendedSubmissions } = this.props;
        const { selectedArea, selectedLevel } = this.state;

        if (!extendedSubmissions || extendedSubmissions.length === 0) {
            return; // Prevent modifying submissions if data isn't loaded yet
        }

        let filteredSubmissions = selectedArea
            ? extendedSubmissions.filter(submission => submission.area === selectedArea)
            : extendedSubmissions;

        if (selectedLevel) {
            filteredSubmissions = filteredSubmissions.filter(submission =>
                submission.characteristic && submission.characteristic.toLowerCase().includes(selectedLevel.toLowerCase())
            );
        }

        const userSubmissionsMap = {};

        filteredSubmissions.forEach(submission => {
            const { uid, firstName, lastName, class: className, yearGroup } = submission;

            if (!userSubmissionsMap[uid]) {
                userSubmissionsMap[uid] = {
                    uid,
                    firstName,
                    lastName,
                    class: className,
                    badge: 0,
                    yearGroup,
                    characteristicsCompleted: {}
                };
            }

            if (submission.characteristic) {
                userSubmissionsMap[uid].characteristicsCompleted[submission.characteristic] = {
                    completed: true,
                    pledge: submission.pledge || '',
                    description: submission.description || ''
                };

                if (!selectedLevel || submission.characteristic.toLowerCase().includes(selectedLevel.toLowerCase())) {
                    userSubmissionsMap[uid].badge += 1;
                }
            }
        });

        const userSubmissions = Object.values(userSubmissionsMap);
        this.setState({ userSubmissions });
    }

    handleAreaClick = (area) => {
        this.setState({ selectedArea: area, selectedLevel: null });
    }

    handleLevelClick = (level) => {
        this.setState({ selectedLevel: level, selectedArea: null });
    }

    render() {
        const { userSubmissions, selectedArea, selectedLevel } = this.state;
        const { areas } = this.props;

        const filteredAndSortedAreas = this.props.schoolData.name.includes("Nene Park") ? areas
            .filter(area => area.stage !== "secondary")
            .sort((a, b) => a.sortOrder - b.sortOrder) :

            areas.sort((a, b) => a.sortOrder - b.sortOrder)



        const selectedAreaCharacteristics = selectedArea
            ? areas.find(area => area.path === selectedArea)?.characteristics.sort((a, b) => a.index - b.index) || []
            : filteredAndSortedAreas.flatMap(area => area.characteristics || []).sort((a, b) => a.index - b.index);

        const filteredCharacteristics = selectedLevel
            ? selectedAreaCharacteristics.filter(characteristic => characteristic.name?.toLowerCase().includes(selectedLevel.toLowerCase()))
            : selectedAreaCharacteristics;

        const columns = [
            { key: 'firstName', label: 'First Name' },
            { key: 'lastName', label: 'Last Name' },
            { key: 'class', label: 'Class' },
            { key: 'yearGroup', label: 'Year' },
            { key: 'badge', label: 'Complete reflections' },
            ...filteredCharacteristics.flatMap(characteristic => [
                { key: `${characteristic.name}_completed`, label: `${characteristic.name}` },
                // Conditionally include the pledge column if schoolName contains 'nene park'
                ...(this.props.schoolData.name.toLowerCase().includes("nene park")
                    ? [{ key: `${characteristic.name}_pledge`, label: `${characteristic.name} Pledge` }]
                    : []),
                { key: `${characteristic.name}_description`, label: `${characteristic.name} Description` }
            ])
        ];


        return (
            <div className="container">
                <h1 className="my-3"></h1>
                {this.props.schoolData.name.includes("Nene Park") &&
                    <div className="mb-3 d-flex flex-wrap">
                        <button
                            className={`btn ${selectedLevel === 'Bronze' ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                            onClick={() => this.handleLevelClick('Bronze')}
                        >
                            Bronze
                        </button>
                        <button
                            className={`btn ${selectedLevel === 'Silver' ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                            onClick={() => this.handleLevelClick('Silver')}
                        >
                            Silver
                        </button>
                        <button
                            className={`btn ${selectedLevel === 'Gold' ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                            onClick={() => this.handleLevelClick('Gold')}
                        >
                            Gold
                        </button>
                        <button
                            className={`btn ${selectedLevel === null ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                            onClick={() => this.handleLevelClick(null)}
                        >
                            All Levels
                        </button>
                    </div>       
                }

                <div className="mb-3 d-flex flex-wrap">
                    <button
                        className={`btn ${selectedArea === null ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                        onClick={() => this.handleAreaClick(null)}
                    >
                        All Areas
                    </button>
                    {filteredAndSortedAreas.map(area => (
                        <button
                            key={area.path}
                            className={`btn ${selectedArea === area.path ? 'btn-primary' : 'btn-outline-secondary'} me-2 mb-2`}
                            onClick={() => this.handleAreaClick(area.path)}
                        >
                            {area.name}
                        </button>
                    ))}
                </div>

                <div className="d-flex justify-content-end mb-3">
                    <CSVLink
                        data={userSubmissions.map(submission => {
                            const characteristicsData = filteredCharacteristics.reduce((acc, characteristic) => {
                                const charCompleted = submission.characteristicsCompleted[characteristic.name] || {};
                                acc[`${characteristic.name}_completed`] = charCompleted.completed ? '✔' : '';
                                acc[`${characteristic.name}_pledge`] = charCompleted.pledge || '';
                                acc[`${characteristic.name}_description`] = charCompleted.description || '';
                                return acc;
                            }, {});
                            return {
                                firstName: submission.firstName,
                                lastName: submission.lastName,
                                class: submission.class,
                                badge: submission.badge,
                                yearGroup: submission.yearGroup,
                                ...characteristicsData
                            };
                        })}
                        headers={columns.map(col => ({ label: col.label, key: col.key }))}
                    filename={"BeWorldClass" + (selectedArea || 'AllAreas') + "_" + (this.props.schoolData.name.includes("Nene Park") ? selectedLevel || 'AllLevels' : "") + ".csv"}
                        className="btn btn-success"
                    >
                        Download <FontAwesomeIcon icon={faTable} />
                    </CSVLink>
                </div>

                <ImprovedGrid
                    extendedSubmissions={userSubmissions.map(submission => {
                        const characteristicsData = filteredCharacteristics.reduce((acc, characteristic) => {
                            const charCompleted = submission.characteristicsCompleted[characteristic.name] || {};
                            acc[`${characteristic.name}_completed`] = charCompleted.completed
                                ? <FontAwesomeIcon icon={faCheck} />
                                : '';
                            acc[`${characteristic.name}_pledge`] = charCompleted.pledge || '';
                            acc[`${characteristic.name}_description`] = charCompleted.description || '';
                            return acc;
                        }, {});

                        return {
                            ...submission,
                            ...characteristicsData
                        };
                    })}
                    columns={columns}
                />
            </div>
        );
    }
}

export default NeneParkGridDashboard;
