import React from "react";
import ImprovedGrid from "./grid/improvedGrid";
import StudentDetails from "./studentDetails";
import FlaggedContent from "./flaggedContent"
import AddStudents from "./addStudents";
import { CSVLink } from 'react-csv';
import { getDatabase, ref, onValue } from 'firebase/database';
import NeneParkDashboard from "./NeneParkGridDashboard";

class AllStudents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userSubmissions: [],
            selectedStudent: null,
            flaggedSubmissions: [],
            showFlaggedSubmissions: false,
            downloadCsv: false,
            isLoading: true,
        };
    }

    componentDidMount() {
        this.modifySubmissions();
        this.fetchFlaggedContent()
        console.log("users in all students", this.props.users)
    }

    componentDidUpdate(prevProps) {
        // Check if extendedSubmissions or users have changed
        if (prevProps.extendedSubmissions !== this.props.extendedSubmissions ||
            prevProps.users !== this.props.users) {
            this.modifySubmissions();
        }
    }

    fetchFlaggedContent = () => {
        const database = getDatabase();
        const flaggedRef = ref(database, 'schools/' + this.props.schoolData.path + '/flagged');

        onValue(flaggedRef, (snapshot) => {
            const flaggedData = [];
            snapshot.forEach((childSnapshot) => {
                flaggedData.push({
                    key: childSnapshot.key,
                    ...childSnapshot.val()
                });
            });
            this.setState({
                flaggedSubmissions: flaggedData,
                isLoading: false
            });
            console.log("got flagged content", flaggedData)
        });
    };


    modifySubmissions = () => {
        const { extendedSubmissions, users } = this.props; // Incoming data
        const { userSubmissions } = this.state; // Current state data

        // If neither `extendedSubmissions` nor `users` is provided, do nothing
        if ((!extendedSubmissions || extendedSubmissions.length === 0) &&
            (!users || users.length === 0)) {
            return;
        }

        // Build a map from the existing state for efficient merging
        const existingMap = userSubmissions.reduce((map, user) => {
            map[user.uid] = { ...user };
            return map;
        }, {});

        // Update with `extendedSubmissions` data
        if (extendedSubmissions && extendedSubmissions.length > 0) {
            extendedSubmissions.forEach((submission) => {
                const { uid, characteristic, pledge, description, badge } = submission;

                // Merge new or updated data into the existing map
                if (!existingMap[uid]) {
                    existingMap[uid] = { ...submission, badge: 0, characteristicsCompleted: {} };
                }

                const user = existingMap[uid];

                // Merge all fields from the submission
                Object.assign(user, submission);

                // Update characteristics and badge count if a characteristic is provided
                if (characteristic) {
                    user.characteristicsCompleted = {
                        ...user.characteristicsCompleted,
                        [characteristic]: {
                            completed: true,
                            pledge: pledge || '',
                            description: description || '',
                        },
                    };
                    user.badge = Object.keys(user.characteristicsCompleted).length; // Recalculate badge count
                    if (user[characteristic.areaName] > 0) {

                    }
                }
            });
        }

        // Update with `users` data
        if (users && users.length > 0) {
            users.forEach((updatedUser) => {
                const existingUser = existingMap[updatedUser.uid];

                if (existingUser) {
                    // Merge updated fields into the existing user data
                    existingMap[updatedUser.uid] = {
                        ...existingUser,
                        ...updatedUser,
                    };
                } else {
                    // Add new user if not already present
                    existingMap[updatedUser.uid] = { ...updatedUser, badge: 0, characteristicsCompleted: {} };
                }
            });
        }

        // Remove users no longer present in the `users` prop
        const filteredSubmissions = Object.values(existingMap).filter(user =>
            users.some(existingUser => existingUser.uid === user.uid)
        );

        // Update state with the filtered data
        this.setState({ userSubmissions: filteredSubmissions });
    };





    handleStudentSelect = (student) => {
        const selectedStudent = this.props.users.find(user => user.uid === student.uid);
        this.setState({ selectedStudent });
    };

    handleBackToList = () => {
        this.setState({ selectedStudent: null });
    }

    render() {
        const { userSubmissions, selectedStudent } = this.state;

        if (selectedStudent) {
            return (
                <StudentDetails
                    student={selectedStudent}
                    extendedSubmissions={this.props.extendedSubmissions}
                    onBack={this.handleBackToList}
                    areasData={this.props.areasData}
                    schoolData={this.props.schoolData}
                    mrCharlieBot={this.props.mrCharlieBot}
                />
            );
        }

        const columns = [
            { key: 'firstName', label: 'First Name' },
            { key: 'lastName', label: 'Last Name' },
            { key: 'email', label: 'Email/Username' },
            { key: 'yearGroup', label: 'Year' },
            { key: 'class', label: 'Class' },
            { key: 'badge', label: 'Badge Count' }
        ];

        return (
            <div className="container card">
                <div className="card-body">
                    <h1 className="my-3">All {this.props.schoolData.stage === "primary" ? "Pupils" : "Students"}</h1>
                    <div style={{ maxWidth: "700px" }}>
                        <div className="d-flex  mb-3 btn-group" >
                            {this.state.flaggedSubmissions.length > 0 && !this.state.isLoading &&
                                <button className="btn btn-outline-danger" onClick={() => { this.setState({ showFlaggedSubmissions: !this.state.showFlaggedSubmissions, downloadCsv: false }) }} > {this.state.showFlaggedSubmissions ? "Close" : "View"} flagged submissions</button>
                            }

                            <AddStudents schoolData={this.props.schoolData} />

                            <button

                                onClick={() => { this.setState({ showFlaggedSubmissions: false, downloadCsv: !this.state.downloadCsv }) }}
                                className="btn btn-outline-primary">
                                {this.state.downloadCsv ? "Close" : ""}   Spreadsheet downloads
                            </button>

                        </div>
                    </div>


                    {this.state.showFlaggedSubmissions &&
                        < FlaggedContent
                            schoolId={this.props.schoolData.path}
                            users={this.props.users}
                            flaggedSubmissions={this.state.flaggedSubmissions}
                        />
                    }
                </div>
                {this.props.extendedSubmissions.length > 0 && this.state.downloadCsv &&
                    <NeneParkDashboard
                        extendedSubmissions={this.props.extendedSubmissions}
                        areas={this.props.areasData}
                        schoolData={this.props.schoolData}
                    />
                }

                {!this.state.downloadCsv && !this.state.showFlaggedSubmissions &&

                    <>
                        <hr />
                        <ImprovedGrid
                            extendedSubmissions={userSubmissions}
                            columns={columns}
                            onRowClick={this.handleStudentSelect}
                        />
                    </>
                }

            </div>
        );
    }
}

export default AllStudents;
