import React from "react";

import { getDatabase, ref, set, onValue, push, get } from "firebase/database";
import { getFunctions, httpsCallable } from "firebase/functions";
import AreaSetup from "./areaSetup";
import app from "../firebase";
import Grid from "./grid/grid";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import GiveFeedback from "./giveFeedback";
import { CSVLink, CSVDownload } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import submissionTabsConfig from "./submissionTabsConfig";
import EditSelectedStudent from "./editStudentDetails"
import areasConfig from "../areasConfig";
import SpreadsheetImport from "./spreadsheetImport";
import TeacherStudentDashboard from "./teacherStudentDashboard";
import CreateWebsiteWidget from "./createWebsiteWidget";
import SocialMediaPosts from "./socialMediaPosts";
import ChangeStudentPassword from "./changeStudentPassword";
import ImprovedGrid from "./grid/improvedGrid";
import FilterableCharts from "./filterableCharts";
import TopSubmissionsSearch from "./TopSubmissonsSearch";
import AllStudents from "./allStudents";




class ViewSubmissions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            values: [],
            databaseValues: {},
            users: [],
            tab: "dashboard",
            setupTab: null,
            selected: null,
            activeAction: null,
            areasData: areasConfig,
            socialMediaPosts: {},
            extendedSubmissions: []
        }
    }

    componentDidMount() {
        const database = getDatabase(app);
        const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/submissions/')
        get(areaStatePath).then((snapshot) => {
            const data = snapshot.val();
            console.log("from Database", data);
            if (data) {
                this.setState({ loaded: true, databaseValues: data }, () => {
                    this.getUsers();
                })
            } else {
                this.setState({ loaded: true }, () => {
                    this.getUsers();
                })
            }
        }).catch((error) => {
            console.error(error);
            // Handle the error if any
        });

        const socialMediaPath = ref(database, 'schools/' + this.props.schoolData.path + '/private/socialMediaPosts/')
        onValue(socialMediaPath, (snapshot) => {
            const socialMediaData = snapshot.val();
            console.log("social media posts from database", socialMediaData)
            if (socialMediaData) {
                this.setState({ socialMediaPosts: socialMediaData })
            } else {
                console.log("no social media")
            }
        });

        this.addCustomArea()
    }

    addCustomArea = () => {
        let filteredAreas = areasConfig; // Assuming areasData is in the state
        // or use areasConfig if it's available: let filteredAreas = [...areasConfig];

        const { schoolData } = this.props;

        if (schoolData.stage === "secondary" || schoolData.stage === "primary") {
            filteredAreas = filteredAreas.filter(item => item.stage === schoolData.stage || item.stage === "custom");
        }

        const database = getDatabase(app);
        const areaPath = ref(database, 'schools/' + schoolData.path + '/public/areaConfig');

        onValue(areaPath, (snapshot) => {
            const data = snapshot.val();
            console.log("custom area from Database", data);

            if (data) {
                const areaKeys = Object.keys(data);
                for (let i = 0; i < areaKeys.length; i++) {
                    const currentArea = data[areaKeys[i]];

                    if (!filteredAreas.some(area => area.path === currentArea.path)) {
                        filteredAreas.push(currentArea);
                    }
                }
            }

            // Setting the state inside the onValue callback to ensure it has the latest data
            this.setState({ areasData: filteredAreas });
        });
    }




    getUsers = () => {
        console.log("get Users")
        const database = getDatabase(app);
        const areaStatePath = ref(database, 'schools/' + this.props.schoolData.path + '/students/')
        onValue(areaStatePath, (snapshot) => {
            const data = snapshot.val();
            console.log("Users update from Database", data)
            if (data) {

                let userArray = []

                for (let [userKey, userValue] of Object.entries(data)) {
                    let user = userValue.details || {}
                    user.key = userKey
                    user.uid = userKey
                    if (!user.hidden) {
                        userArray.push(user)
                    }

                }


                this.setState({ loaded: true, users: userArray }, () => {
                    this.getEmailAddresses(data, userArray).then(() => {
                        this.findSubmissions(data, userArray);
                    });
                });
            }
        });

    }


    getEmailAddresses = (data) => {
        return new Promise((resolve, reject) => {
            const uids = Object.keys(data);
            const schoolPath = this.props.schoolData.path;
            console.log("schoolPath for get emails");

            const functions = getFunctions();
            const getStudentEmails = httpsCallable(functions, 'getStudentEmails');

            getStudentEmails({ schoolId: schoolPath, uids: uids })
                .then((result) => {
                    console.log("emails", result);
                    let users = this.state.users;
                    const usersWithEmail = result.data?.getUsersResult;

                    // Update users with email addresses
                    for (let i = 0; i < usersWithEmail.length; i++) {
                        const uid = usersWithEmail[i].uid;
                        const user = users.find(obj => obj.uid === uid);
                        if (user) {
                            user.email = usersWithEmail[i].email;
                        }
                    }
                    console.log("users", users);

                    // Update state and resolve the Promise
                    this.setState({ users: users }, () => {
                        resolve(); // Signal that email processing is done
                    });
                })
                .catch((error) => {
                    console.error("Error fetching emails:", error);
                    reject(error); // Signal an error
                });
        });
    };


    findSubmissions = (data, userArray) => {
        let submissions = []; // Original format
        let extendedSubmissions = []; // Extended list format

        for (let [areaKey, areaValue] of Object.entries(this.state.databaseValues)) {
            let areaName = this.state.areasData.find(area => area.path === areaKey)?.name || null;
            for (let [userKey, userValue] of Object.entries(areaValue)) {
                console.log(areaKey, userKey, userValue);

                const submissionDatabaseKey = this.state.databaseValues[areaKey][userKey];
                if (submissionDatabaseKey.values) {
                    let submission = { ...submissionDatabaseKey.values };

                    // Merge user details
                    if (data[userKey]?.details) {
                        Object.assign(submission, data[userKey].details);
                    }

                    // Basic submission properties
                    submission.key = areaKey + "-" + userKey;
                    submission.progress = submissionDatabaseKey.state;
                    submission.mark = submissionDatabaseKey.mark;
                    submission.progressEstimate = submissionDatabaseKey.progressEstimate ? Math.round(submissionDatabaseKey.progressEstimate) : 0;
                    submission.area = areaKey;
                    submission.uid = userKey;
                    submission.embeddings = submissionDatabaseKey.embeddings;
                    submission.grace = submissionDatabaseKey.grace;

                    submissions.push(submission);

                    if (submissionDatabaseKey.grace) {

                        // Generate extended submissions
                        for (let graceKey of Object.keys(submissionDatabaseKey.grace)) {
                            let { firstName, lastName, ...userDetails } = data[userKey].details || {};
                            const durationOfImpact = submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.durationOfImpact || null;
                            const durationCategory = durationOfImpact
                                ? durationOfImpact.includes("Day")
                                    ? "Short"
                                    : durationOfImpact.includes("Month")
                                        ? "Medium"
                                        : durationOfImpact.includes("Year+")
                                            ? "Long"
                                            : null
                                : null;
                            let extendedSubmission = {
                                firstName,
                                lastName,
                                ...userDetails,
                                key: graceKey + "-" + areaKey + "-" + userKey,
                                understanding: submissionDatabaseKey.grace?.[graceKey]?.award || null,
                                badge: submissionDatabaseKey.grace?.[graceKey]?.award >= 70 ? true : false,
                                location: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.location || null,
                                affects: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.affects || null,
                                durationOfImpact: durationCategory || null,
                                subject: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.subject[0]?.replace("Learning ", "") || null,
                                supportedBy: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.supportedBy || null,
                                moneyRaised: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.moneyRaised || null,
                                valueEstimate: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.valueEstimate || null,
                                impressiveSorting: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.impressiveSorting || null,
                                role: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.role || null,
                                other: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.other?.toString() || null,
                                inappropriate: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.inappropriate || null,
                                safeguardConcern: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.safeguardConcern || null,
                                displayChildline: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.displayChildline || null,
                                unsafe: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.unsafe || null,
                                hate: submissionDatabaseKey.embeddings?.[graceKey]?.embeddingData?.hate || null,
                                area: areaKey,
                                areaName: areaName
                            };


                            //Nene park only, tidy this up!
                            if (this.props.schoolData.name.includes("Nene Park")) {
                                const response = submissionDatabaseKey.grace?.[graceKey]?.response;
                                if (response && response.includes("|")) {
                                    // Extract the string between two | symbols
                                    const extractedString = response.split('|')[1] || ''; // Get the part between the first two | symbols
                                    extendedSubmission.pledge = extractedString;
                                }
                            }

                            // Find the characteristic name
                            let characteristicName = "";
                            const area = this.state.areasData.find(a => a.path === areaKey);
                            if (area) {
                                console.log("area found", area)
                                const characteristic = area.characteristics.find(c => c.id.toString() === graceKey.toString());
                                if (characteristic) {
                                    characteristicName = characteristic.name;
                                }
                            }
                            extendedSubmission.characteristic = characteristicName;

                            for (let key of Object.keys(submission)) {
                                if (['date', 'description', 'confidence', 'source'].some(substring => key.includes(substring) && key.includes(graceKey))) {
                                    // Remove the graceKey part from the key for consistent naming
                                    const normalizedKey = key.replace(graceKey, '');
                                    let value = submission[key];

                                    // Convert confidence to a number if the normalizedKey is 'confidence'
                                    if (normalizedKey === 'confidence') {
                                        value = parseInt(value); // Use parseFloat to handle decimals, or parseInt for integers
                                    }

                                    extendedSubmission[normalizedKey] = value;
                                }
                            }

                            extendedSubmissions.push(extendedSubmission);
                        }
                    }
                }
            }
        }

        console.log("submissions", submissions);
        console.log("extendedSubmissions", extendedSubmissions);
        this.setState({ values: submissions, users: userArray, extendedSubmissions: extendedSubmissions });
    }




    selectStudent = (selectedRows) => {


        this.setState({ selected: selectedRows })

    }

    closeAction = () => {
        this.setState({ activeAction: null })

    }

    changeTab = (newValue) => {
        this.setState({ tab: newValue });
    }

    render() {

        const that = this
        const tab = submissionTabsConfig.filter(function (tab) {

            return tab.key == that.state.tab
        })[0]

        const socialMediaPostsArray = Object.values(this.state.socialMediaPosts);



        return (
            <div style={{ padding: "30px" }} className="row">

                {this.state.tab !== "dashboard" &&
                    <div style={{ paddingBottom: "1rem", textAlign: "left" }}>
                        <button onClick={() => this.setState({ tab: "dashboard", insight: false })} className="btn btn-link p-0 text-left ">
                            <FontAwesomeIcon icon="arrow-left" className="mr-3" />    Back
                        </button>
                    </div>

                }
                {this.state.values && this.props.schoolData && this.state.areasData &&
                    <>


                        {this.state.tab === "dashboard" &&

                            <>

                                <TeacherStudentDashboard
                                    values={this.state.values}
                                    schoolData={this.props.schoolData}
                                    areasData={this.state.areasData}
                                    socialMediaPosts={this.state.socialMediaPosts}
                                    changeParentTab={this.changeTab}
                                    mrCharlieBot={this.props.mrCharlieBot}
                                />
                            </>
                        }
                        {this.state.tab === "widgets" &&
                            <CreateWebsiteWidget
                                values={this.state.values}
                                schoolData={this.props.schoolData}
                                areasData={this.state.areasData}
                                socialMediaPosts={this.state.socialMediaPosts}
                                extendedSubmissions={this.state.extendedSubmissions}
                                changeParentTab={this.changeTab}
                            />
                        }

                        {this.state.tab === "socialMedia" &&
                            <>
                                <TopSubmissionsSearch extendedSubmissions={this.state.extendedSubmissions} />
                                {/* {
                                    socialMediaPostsArray.length > 0 ?
                                        <SocialMediaPosts socialMediaPostsArray={socialMediaPostsArray} />
                                        :
                                        <p> No social media posts to display</p>
                                } */}
                            </>

                        }
                        {this.state.tab === "allStudents" &&
                            <AllStudents extendedSubmissions={this.state.extendedSubmissions} areas={this.state.areasData} schoolData={this.props.schoolData} areasData={this.state.areasData} users={this.state.users} mrCharlieBot={this.props.mrCharlieBot} />
                        }

                        {this.state.tab === "insight" &&
                            <>
                                <FilterableCharts
                                    extendedSubmissions={this.state.extendedSubmissions}
                                    areas={this.state.areasData}
                                    schoolData={this.props.schoolData}

                                />

                                
                            </>
                        }
                    </>
                }






                {this.state.users.length < 1 &&
                    <div className="alert alert-danger" role="alert">
                        <h5 className="alert-heading"><strong>Important!</strong></h5>
                        <p>No students are signed up. Students need to create their own accounts using the link below:</p>
                        <hr />
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://app.beworldclass.org/student/${this.props.schoolData.path}`}
                            className="alert-link"
                        >
                            app.beworldclass.org/student/{this.props.schoolData.path}
                        </a>
                    </div>
                }


                {this.state.tab === "setup" &&

                    <AreaSetup schoolData={this.props.schoolData} />
                }


            </div>
        );
    }



    submissionTabsUi() {
        return <ul style={{ marginLeft: "2rem" }} className="nav nav-tabs col-9">
            <li onClick={() => this.setState({ tab: "review", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "review" ? "active" : "")} aria-current="page">Review</a>
            </li>
            <li onClick={() => this.setState({ tab: "complete", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "complete" ? "active" : "")}>Complete</a>
            </li>
            <li onClick={() => this.setState({ tab: "incomplete", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "incomplete" ? "active" : "")}>Incomplete</a>
            </li>
            <li onClick={() => this.setState({ tab: "favorites", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "favorites" ? "active" : "")}>Favorites</a>
            </li>
            <li onClick={() => this.setState({ tab: "allSubmissions", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "allSubmissions" ? "active" : "")}>All Submissions</a>
            </li>
            <li onClick={() => this.setState({ tab: "allStudents", insight: false })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "allStudents" ? "active" : "")}>All Students</a>
            </li>
            <li onClick={() => this.setState({ tab: "insight", insight: true })} className="nav-item">
                <a className={"nav-link " + (this.state.tab === "insight" ? "active" : "")}>Insight</a>
            </li>
        </ul>;
    }
}

export default ViewSubmissions;