import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen, faFolder, faLink, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const StudentResources = ({ schoolData }) => {
    const defaultResources = [
        { name: "Reflection protocols", url: "https://drive.google.com/file/d/1BSz1yvmN4vnEMNz1_rpb_Y7KhWJoWVuF/view?usp=sharing" },
        { name: "Reflection conversations", url: "https://drive.google.com/file/d/1HAFvup4FcxbWP5-F8u--pOAfwN590vBZ/view?usp=sharing" },
        { name: "Study tips conversations", url: "https://drive.google.com/file/d/16zwfRK8W-rtwnk-nZRl90Ra59QZDj1Cp/view?usp=sharing" },
        { name: "Developing opinions on global issues", url: "https://drive.google.com/file/d/1Q-KdZ3uwMX53GnSPBBHAe-UiiUgK1LI4/view?usp=sharing" },
        { name: "Developing academic interests and enquiry", url: "https://drive.google.com/file/d/18xR68HpqcZgGPBLIK-nK4QmMCYUU7pZC/view?usp=sharing" },
        { name: "Body life area conversations", url: "https://drive.google.com/file/d/1X4gjUFvEbCXWR6SkY9hVD8wziLzNg8AO/view?usp=sharing" },
    ];

    const [currentPath, setCurrentPath] = useState([]);

    const resources = schoolData?.resources || defaultResources;

    // Get current folder contents based on navigation
    const getCurrentFolder = () => {
        let folder = { children: resources };
        for (const index of currentPath) {
            folder = folder.children[index];
        }
        return folder;
    };

    const currentFolder = getCurrentFolder();

    // Navigate into a folder
    const openFolder = (index) => {
        setCurrentPath([...currentPath, index]);
    };

    // Navigate back up
    const goBack = () => {
        setCurrentPath(currentPath.slice(0, -1));
    };

    return (
        <div className="bg-white rounded-3 shadow p-4 d-flex flex-column flex-grow-1">
            <h2 className="fs-5 fw-bold text-dark d-flex align-items-center gap-2">
                <FontAwesomeIcon icon={faBookOpen} className="text-primary" />
                Learning Resources
            </h2>
            <div
                className="mt-3"
                style={{
                    maxHeight: "50vh",
                    overflowY: "auto",
                    scrollBehavior: "smooth",
                    scrollbarWidth: "thin",
                    scrollbarColor: "#007bff white",
                }}
            >
                {/* Back button for navigating up folders */}
                {currentPath.length > 0 && (
                    <div
                        className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                        style={{ cursor: "pointer" }}
                        onClick={goBack}
                    >
                        <FontAwesomeIcon icon={faArrowLeft} className="me-2 text-secondary" />
                        <span className="text-dark">Back</span>
                    </div>
                )}

                {/* Display folder contents */}
                {currentFolder.children?.length > 0 ? (
                    currentFolder.children.map((item, index) => (
                        item.type === "folder" ? (
                            <div
                                key={index}
                                className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                                style={{ cursor: "pointer" }}
                                onClick={() => openFolder(index)}
                            >
                                <FontAwesomeIcon icon={faFolder} className="me-2 text-warning" />
                                <h3 className="fs-6 fw-semibold text-dark">{item.name}</h3>
                            </div>
                        ) : (
                            <a
                                key={index}
                                href={item.url?.startsWith("http") ? item.url : `https://${item.url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textDecoration: "none" }}
                            >
                                <div
                                    className="d-flex align-items-center p-3 mb-3 bg-light rounded shadow-sm position-relative"
                                    style={{ cursor: "pointer" }}
                                >
                                    <FontAwesomeIcon icon={faLink} className="me-2 text-primary" />
                                    <h3 className="fs-6 fw-semibold text-dark">{item.name ? item.name : item.url}</h3>
                                </div>
                            </a>
                        )
                    ))
                ) : (
                    <h5 className="text-muted">This folder is currently empty.</h5>
                )}
            </div>
        </div>
    );
};

export default StudentResources;
