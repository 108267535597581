import React, { Component } from "react";
import { render } from "react-dom";
import { BrowserRouter, Route, Routes, Link, useParams } from 'react-router-dom';

import "./index.css"
import 'bootstrap/dist/css/bootstrap.min.css';

import TeacherPage from "./teacher/teacherPage.js";
import SignUpSchool from "./teacher/signUpSchool.js";

import SignUpSchoolDemo from "./teacher/signUpSchoolDemo.js";
import FindSchoolUrl from "./student/findSchoolUrl.js";
import ChatLink from "./chat/chatLink.js";
import WebsiteWidgetBanner from "./websiteWidgetBanner.js";


import { getAuth, createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { getDatabase, ref, onValue, get } from "firebase/database"
import app from './firebase.js'
import { wrongRoleTeacherWarning } from "./teacher/wrongRoleTeacherWarning";
import { signOutUser } from "./signOutUser";
import { landingPage } from "./landingPage";
import UploadPage from "./htmlSharing/uploadPage.js"
import DisplayPage from "./htmlSharing/displaypage.js"
import AudioChat from "./mrcharliebot/audioChat.js";
import CharlieMainMenu from "./mrcharliebot/charlieMainMenu.js";
import CharlieSignUp from "./mrcharliebot/charlieSignUp.js";
import CharlieBotPaymentPage from "./mrcharliebot/charlieBotPaymentPage.js";
import CharactersDisplay from "./mrcharliebot/charactersDisplay.js";
import FreeAccessCode from "./mrcharliebot/freeAccessCode.js"
import ForgotPasswordForm from "./forgotPasswordForm";


import SignIn from "./signIn.js";



class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,

    }
  }


  componentDidMount() {

    const auth = getAuth(app);


    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log("got user")
        this.getUserRoles(user)
      } else {
        console.log("signed out")
      }
    })


  }

  getUserRoles = (user) => {

    const database = getDatabase(app);
    const databasePath = ref(database, 'users/' + user.uid)
    console.log(user.uid)

    onValue(databasePath, (snapshot) => {
      const data = snapshot.val();
      console.log("got user roles", data)
      if (data) {
        this.getUserDetails(data, user)

      } else {
        console.log("no user roles")
      }
    });

  }

  getUserDetails = (userRoles, user) => {

    const rolePath = userRoles.role === "student" ? "students" : "staff"
    const database = getDatabase(app);
    console.log(rolePath)
    const databasePath = ref(database, 'schools/' + Object.keys(userRoles.schools)[0] + "/" + rolePath + "/" + user.uid)

    onValue(databasePath, (snapshot) => {
      const data = snapshot.val();
      console.log("user details", data)

      this.addUserDetailsToUser(data, user, userRoles);

    });


  }

  handleSignOut = () => {
    signOutUser();
  }


  addUserDetailsToUser(data, user, userRoles) {
    if (data) {
      const newUserObject = {
        uid: user.uid,
        email: user.email,
        role: userRoles.role,
        school: Object.keys(userRoles.schools)[0] || undefined,
        firstName: data.details?.firstName,
        lastName: data.details?.lastName,
        yearGroup: data.details?.yearGroup,
        gender: data.details?.gender,
        mrCharlieBot: data.details?.mrCharlieBot,
        subscriptions: userRoles.subscriptions
        // Add any other necessary fields
      };

      this.setState({ user: newUserObject }, () => {
        console.log("Updated user state:", this.state.user);
      });
    } else {
      console.log("No user details");
    }
  }


  render() {

    const wrongRole = wrongRoleTeacherWarning();
    const hostname = window.location.hostname;
    if (
      // hostname.toLowerCase().includes('local') ||
      hostname.toLowerCase().includes('mrcharliebot')) {
      return (
        <div style={{ backgroundImage: "linear-gradient(to top, #052C65, #212529)", height: "100%", minHeight: "100vh" }}>

          <BrowserRouter>
            <Routes>
              {this.state.user ?
                <>
                  {/* Have they paid for mr charlie bot? */}
                  {this.state.user.subscriptions ? 
                    <>
                      <Route path="*" element={<CharlieMainMenu user={this.state.user} />} />
                      {/* <Route path="/tasks" element={<CharactersDisplay user={this.state.user} />} />
                      <Route path="/games" element={<CharactersDisplay user={this.state.user} games={true} />} /> */}

                    </> :

                    // not paid? Show payment options
                    <>
                      {this.state.user?.role === "teacher" &&
                        <Route path='/*' element={

                          <TeacherPage user={this.state.user} mrCharlieBot={true} />

                        } />
                      }
                      <Route exact path="/*" element={<CharlieBotPaymentPage user={this.state.user} />} />
                      <Route exact path="/FreeAccess" element={<FreeAccessCode />} />
                      <Route exact path="/paymentsuccess" element={<div style={{ textAlign: "center", padding: "5rem" }}>
                        <img style={{ height: "20vh" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" />
                        <br />
                        <h3 className="mb-6 display-6 text-primary">
                          Payment recieved! We are setting up your account.
                        </h3>
                        <h5 className="mb-10 display-10 text-white" >If this takes more than a few minutes, try refreshing the page, then please contact us</h5>
                      </div>} />
                    </>

                  }
                </> :
                <>
                  <Route exact path="/*" element={<div style={{ textAlign: "center", padding: "5rem" }}>
                    <img style={{ height: "20vh" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" />
                    <br />
                    <div style={{ textAlign: "right", width: "100%" }}>
                      <Link className="btn btn-primary" to={"/signUp"}>Don't have an account? Sign up here!</Link>
                    </div>
                    <SignIn mrCharlieBot={true} />


                  </div>} />

                  <Route exact path="/SignUp" element={<CharlieSignUp />} />
                  <Route exact path="/FreeAccess" element={<CharlieSignUp />} />
                  <Route path='/signupSchool/:schoolPath/*' element={<SignUpSchool mrCharlieBot={true} />} />


                  <Route exact path="/paymentsuccess" element={<div style={{ textAlign: "center", padding: "5rem" }}>
                    <img style={{ height: "20vh" }} src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" />
                    <br />
                    <h3 className="mb-6 display-6 text-primary">
                      Payment recieved! We are setting up your account.
                    </h3>
                    <h5 className="mb-10 display-10 text-white" >If this takes more than a few minutes, please contact us</h5>
                  </div>} />





                </>
              }

              <Route path='/student/:schoolPath/*' element={<div>
                {this.state.user?.role === "teacher" && wrongRole}
                {this.state.user?.role !== "teacher" && <FindSchoolUrl user={this.state.user} mrCharlieBot={true}/>}
              </div>} />

              <Route path='/student/*' element={<div className="app"><h2>Error: No school. Try going back to the login page</h2></div>} />

            </Routes>
          </BrowserRouter>

        </div>

      )
    } else {
      return (<>

        <BrowserRouter>
          <Routes>
            {this.state.user && this.state.user?.role !== "teacher" &&
              <Route path='/' element={<div className="app"><div style={{ margin: "2rem", padding: "2rem", textAlign: "center" }} className="card">
                <h4>Logged in</h4>
                <h4> <Link className="btn btn-primary" to={"student/" + this.state.user?.school}>Continue to my school</Link>{ } </h4></div></div>} />
            }

            <Route path='/student/:schoolPath/*' element={<div className="app"><div>
              {this.state.user?.role === "teacher" && wrongRole}
              {this.state.user?.role !== "teacher" && <FindSchoolUrl user={this.state.user} />}
            </div></div>} />

            <Route path='/student/*' element={<div className="app"><h2>Error: No school. Try going back to the login page</h2></div>} />


            <Route path='/s/:schoolPath/*' element={<div className="app"><div>
              {this.state.user?.role === "teacher" && wrongRole}
              {this.state.user?.role !== "teacher" && <FindSchoolUrl user={this.state.user} />}
            </div></div>} />

            <Route path='/s/*' element={<div className="app"><h2>Invalid link. Try going back to the login page</h2></div>} />

            {this.state.user && this.state.user.role === "teacher" &&
              <Route path='/*' element={<div className="app">

                <TeacherPage user={this.state.user} />

              </div>} />
            }
            {!this.state.user &&
              <Route path='/' element={<div className="app">{landingPage()}</div>} />
            }

            <Route path='/setPassword' element={<div className="app"><div className="landing-page" ><div className="sign-in-form card">
              <h2>Set your password</h2>
              <ForgotPasswordForm /></div> </div></div>} />

            {!this.state.user &&
              <Route path='/signupSchool/:schoolPath/*' element={<div className="app"><SignUpSchool mrCharlieBot={false} /></div>} />
            }

            {!this.state.user &&
              <Route path='/signupSchooldemo/' element={<div className="app"><SignUpSchoolDemo /></div>} />
            }

            {!this.state.user &&
              <Route path='/signupSafeStart/:schoolPath/*' element={<div className="app"><SignUpSchool safeStart={true} /></div>} />
            }

            {this.state.user &&
              <Route path='/signupSchool/:schoolPath/*' element={
                <div className="app" style={{ margin: "2rem", padding: "2rem", textAlign: "center" }}>
                  <h4>Your school is signed up!</h4>
                  <h4> <Link className="btn btn-primary" to={"/"}>Go to my account</Link>{ } </h4>
                </div>} />
            }

            {this.state.user &&
              <Route path='/signupSafeStart/:schoolPath/*' element={
                <div className="app" style={{ margin: "2rem", padding: "2rem", textAlign: "center" }}>
                  <h4>Your school is signed up!</h4>
                  <h4> <Link className="btn btn-primary" to={"/"}>Go to my account</Link>{ } </h4>
                </div>} />
            }


            <Route path='/usegpt/:schoolPath/:assistantId' element={<div className=""><ChatLink llama={false} /></div>} />
            <Route path='/llama/:schoolPath/:assistantId' element={<div className=""><ChatLink llama={true} /></div>} />
            <Route path='/websiteWidgetBanner/:schoolPath' element={<WebsiteWidgetBanner />} />
            <Route exact path="/htmlSharing" element={<UploadPage />} />

            <Route path="h/:fileId" element={<DisplayPage />} />
          </Routes>
        </BrowserRouter>


      </>
      );
    }





  }
}
render(<App />, document.getElementById("root"));





