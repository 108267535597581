import React, { useState } from 'react';
import './financeGame.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHome,
  faPiggyBank,
  faChartLine,
  faShoppingCart,
  faBriefcase,
  faGraduationCap,
  faHeartbeat
} from '@fortawesome/free-solid-svg-icons';
import BankWindow from './bankWindow';
import InvestmentsWindow from './investmentsWindow';
import { advanceSimulation } from './simulationRunner';

function GenericWindow({ title, icon, closeWindow }) {
  return (
    <div className="window">
      <div className="window-header d-flex justify-content-between align-items-center">
        <div className="window-title d-flex align-items-center">
          <div className="window-title-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
          <span>{title}</span>
        </div>
        <div className="window-controls d-flex">
          <div className="window-button window-minimize"></div>
          <div className="window-button window-maximize"></div>
          <div className="window-button window-close" onClick={closeWindow}></div>
        </div>
      </div>
      <div className="window-content">
        <div style={{ padding: '20px' }}>
          <h2>{title}</h2>
          <p>This is the {title} window.</p>
        </div>
      </div>


    </div>
  );
}

function DesktopSim() {
  // Active window (if any)
  const [activeWindow, setActiveWindow] = useState(null);

  // INITIAL SIMULATION STATE.
  const [simState, setSimState] = useState({
    accountBalance: 2000,
    job: { title: 'Software Developer', salary: 1500 }, // weekly salary
    housing: { rent: 200 }, // weekly rent cost
    weeklyFoodShop: [
      {
        item: 'Milk',
        quantity: 2,
        cost: 3,
        nutrients: { calories: 150, protein: 8, carbs: 12, fat: 8 }
      },
      {
        item: 'Eggs',
        quantity: 1,
        cost: 2.5,
        nutrients: { calories: 70, protein: 6, carbs: 1, fat: 5 }
      },
      {
        item: 'Bread',
        quantity: 1,
        cost: 2,
        nutrients: { calories: 80, protein: 3, carbs: 15, fat: 1 }
      },
      {
        item: 'Fruit',
        quantity: 5,
        cost: 0.5,
        nutrients: { calories: 50, protein: 0.5, carbs: 12, fat: 0.2 }
      },
    ],
    idealNutrients: { calories: 2000, protein: 100, carbs: 250, fat: 70 },
    nutrientIntake: { calories: 0, protein: 0, carbs: 0, fat: 0 },
    nutrientGap: { calories: 0, protein: 0, carbs: 0, fat: 0 },
    funExpense: 50, // fixed weekly fun expense
    // Purchased stocks now store only the necessary purchase info.
    purchasedStocks: [
      { stockId: 'AAPL', quantity: 10, purchaseWeek: 1, purchasePrice: 150 },
      { stockId: 'GOOGL', quantity: 5, purchaseWeek: 1, purchasePrice: 1200 },
    ],
    week: 1, // simulation starts at week 1
    transactions: [],
    overdraftLimit: 0,
    emergencyFund: 0,
    portfolioValue: 0 // computed based on purchased stocks
  });

  // Advance simulation one week.
  const advanceTime = () => {
    setSimState(prevState => advanceSimulation(prevState));
  };

  // Desktop icons (for opening various windows/apps).
  const desktopIcons = [
    { id: 'bank', title: 'My Bank', icon: faPiggyBank },
    { id: 'invest', title: 'Investments', icon: faChartLine },
    { id: 'shop', title: 'Shop Online', icon: faShoppingCart },
    { id: 'home', title: 'Housing', icon: faHome },
    { id: 'job', title: 'Find Jobs', icon: faBriefcase },
    { id: 'education', title: 'Education', icon: faGraduationCap },
    { id: 'health', title: 'Health', icon: faHeartbeat },
  ];

  const taskbarIcons = desktopIcons.slice(0, 4);

  const handleIconClick = (iconObj) => {
    setActiveWindow(iconObj);
  };

  const closeWindow = () => {
    setActiveWindow(null);
  };

  return (
    <div>
      <div
        style={{
          maxHeight: '300px', // Adjust the height as needed
          overflowY: 'auto',
          border: '1px solid #ddd',
          borderRadius: '4px',
          padding: '10px',
          backgroundColor: '#f7f7f7',
        }}
      >
        <pre style={{ margin: 0 }}>
          {JSON.stringify(simState, null, 2)}
        </pre>
      </div>
      {/* Taskbar */}
      <div className="taskbar d-flex justify-content-between align-items-center">
        <div className="taskbar-start d-flex align-items-center">
          <button className="start-button">
            <FontAwesomeIcon icon={faHome} />
          </button>
          <div className="app-buttons d-flex">
            {taskbarIcons.map((iconObj) => (
              <div
                key={iconObj.id}
                className="app-button"
                onClick={() => handleIconClick(iconObj)}
              >
                <FontAwesomeIcon icon={iconObj.icon} />
              </div>
            ))}
          </div>
        </div>
        <div className="taskbar-end d-flex align-items-center">
          <div className="time-display">Week {simState.week}</div>
        </div>
      </div>

      {/* Desktop Icons */}
      <div className="desktop">
        {desktopIcons.map((iconObj) => (
          <div
            key={iconObj.id}
            className="desktop-icon"
            onClick={() => handleIconClick(iconObj)}
          >
            <div className={`icon-img ${iconObj.id}`}>
              <FontAwesomeIcon icon={iconObj.icon} />
            </div>
            <div className="icon-text">{iconObj.title}</div>
          </div>
        ))}
      </div>

      {/* Advance Time Button */}
      <div className="advance-time-btn" style={{ position: 'fixed', bottom: 20, right: 20 }}>
        <button className="btn btn-primary" onClick={advanceTime}>
          Advance Time
        </button>
      </div>

      {/* Window Rendering */}
      {activeWindow && (
        activeWindow.id === 'bank' ?
          <BankWindow closeWindow={closeWindow} simState={simState} setSimState={setSimState} />
          : activeWindow.id === 'invest' ?
            <InvestmentsWindow closeWindow={closeWindow} simState={simState} setSimState={setSimState} />
            : <GenericWindow
              title={activeWindow.title}
              icon={activeWindow.icon}
              closeWindow={closeWindow}
            />
      )}

    </div>
  );
}

export default DesktopSim;
