import React from "react";
import { Formik, Form, Field } from "formik";
import { getDatabase, update, ref } from "firebase/database";
import app from "../firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";

class AddStudents extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            message: "",
            view: null,
            loading: false,
            loadingMessage: "",
            importResults: [],
            errorMessages: [],
            successMessages: [],
        };
    }

    toggleModal = () => {
        this.setState((prevState) => ({
            showModal: !prevState.showModal,
            message: "",
            view: null,
            importResults: [],
            errorMessages: [],
            successMessages: [],
        }));
    };

    handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (!file) return;

        this.setState({
            loading: true,
            loadingMessage: "Processing spreadsheet...",
            importResults: [],
            errorMessages: [],
            successMessages: [],
        });

        try {
            const data = await this.readExcelFile(file);
            const results = [];

            for (const row of data) {
                try {
                    const user = this.validateRow(row);
                    await this.inviteUser(user);
                    results.push({ ...user, status: "success", message: "Invited successfully" });
                } catch (error) {
                    results.push({
                        email: row.Email || row.email || "No email provided",
                        status: "error",
                        message: error.message,
                    });
                }
            }

            this.setState({
                importResults: results,
                successMessages: ["File processing completed"],
            });
        } catch (error) {
            this.setState({
                errorMessages: [`File processing failed: ${error.message}`],
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    readExcelFile = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const workbook = XLSX.read(event.target.result, { type: "binary" });
                    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                    resolve(XLSX.utils.sheet_to_json(worksheet, { defval: "" }));
                } catch (error) {
                    reject(new Error("Invalid file format"));
                }
            };
            reader.onerror = () => reject(new Error("Error reading file"));
            reader.readAsBinaryString(file);
        });
    };

    validateRow = (row) => {
        const email = row["Email/Username"] || row.email;
        const firstName = row["First Name"] || row.firstName;
        const lastName = row["Last Name"] || row.lastName;
        const password = row["Password"] || row.password;
        const yearGroup = row["Year Group"] || row.yearGroup;
        const classGroup = row["Class"] || row.class;
        const gender = row["Gender"] || row.gender;

        if (!email || !firstName || !lastName || !password || !yearGroup || !classGroup || !gender) {
            throw new Error("Missing required fields");
        }

        if (isNaN(yearGroup)) {
            throw new Error("Year Group must be a number");
        }

        return {
            email,
            firstName,
            lastName,
            password,
            yearGroup: Number(yearGroup),
            class: classGroup,
            gender,
        };
    };

    inviteUser = async (userData) => {
        const functions = getFunctions();
        const createSchoolStaff = httpsCallable(functions, "createSchoolStaff");

        // Process email transformation
        const modifiedUserData = { ...userData };
        if (modifiedUserData.email && !modifiedUserData.email.includes("@")) {
            modifiedUserData.email = `b3w0r1d+${modifiedUserData.email}@beworldclass.org`;
        }

        const result = await createSchoolStaff({
            school: this.props.schoolData.path,
            userType: "student",
            ...modifiedUserData, // Spread modified data after potential email update
        });

        if (result.data.error) throw new Error(result.data.error);
        return result;
    };

    addUser = async (values) => {
        this.setState({
            loading: true,
            loadingMessage: "Adding user...",
            errorMessages: [],
            successMessages: [],
        });

        try {
            await this.inviteUser(values);
            this.setState({
                successMessages: [`User ${values.firstName} ${values.lastName} added successfully`],
                view: null,
            });
        } catch (error) {
            this.setState({
                errorMessages: [`Error adding user: ${error.message}`],
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    renderModalContent() {
        const { view } = this.state;
        const headers = [
            { label: "First Name", key: "firstName" },
            { label: "Last Name", key: "lastName" },
            { label: "Email/Username", key: "email" },
            { label: "Password", key: "password" },
            { label: "Year Group", key: "yearGroup" },
            { label: "Class", key: "class" },
            { label: "Gender", key: "gender" },
        ];

        const menuButtons = [
            {
                label: "Add user",
                view: "addUser",
                variant: "-outline-primary",
            },
            {
                label: "Import Users",
                view: "importUsers",
                variant: "-outline-secondary",
            },
        ];

        return (
            <div style={{ textAlign: "left" }} className="modal-body">
                <div className="row g-2 mb-3">
                    <p className="text-muted">If pupils are capable of creating their own accounts, it is usually easiest and more secure for them to sign up themselves via the sign up link</p>

                    {menuButtons.map((button) => (
                        <div key={button.view} className="col-12 col-md-6">
                            <button
                                className={`btn btn${button.variant} w-100`}
                                onClick={() => this.setState({ view: button.view })}
                            >
                                {button.label}
                            </button>
                        </div>
                    ))}
                </div>

                {this.state.loading && (
                    <div className="alert alert-info">{this.state.loadingMessage}</div>
                )}

                {view === "addUser" && (
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            password: "",
                            yearGroup: 0,
                            class: "",
                            gender: "",
                        }}
                        onSubmit={(values) => this.addUser(values)}
                    >
                        {() => (
                            <Form>
                                <div className="row g-3">
                                    <div className="col-md-6">
                                        <label className="form-label">First Name</label>
                                        <Field name="firstName" type="text" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Last Name</label>
                                        <Field name="lastName" type="text" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Email/username</label>
                                        <Field name="email" type="email" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Password</label>
                                        <Field name="password" type="password" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Year Group</label>
                                        <Field name="yearGroup" type="number" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Class/Tutor Group</label>
                                        <Field name="class" type="text" className="form-control" required />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="form-label">Gender</label>
                                        <Field as="select" name="gender" className="form-select" required>
                                            <option value="">Select Gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </Field>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-end mt-3">
                                    <button
                                        type="button"
                                        className="btn btn-secondary me-2"
                                        onClick={this.toggleModal}
                                    >
                                        Cancel
                                    </button>
                                    <button type="submit" className="btn btn-primary">
                                        Add User
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}

                {view === "importUsers" && (
                    <div>
                        <CSVLink
                            data={[{
                                firstName: "John",
                                lastName: "Doe",
                                email: "john@example.com",
                                password: "securepassword123$",
                                yearGroup: 7,
                                class: "7A",
                                gender: "male"
                            }, {
                                firstName: "Jane",
                                lastName: "Doe",
                                email: "JaneDoe25",
                                password: "securepassword123$",
                                yearGroup: 5,
                                class: "Mr Smith",
                                gender: "female"
                            }]}
                            headers={headers}
                            filename="student_template.csv"
                            className="btn btn-outline-secondary mb-3"
                        >
                            Download Template
                        </CSVLink>

                        <div className="mb-3">
                            <label htmlFor="fileUpload" className="form-label">
                                Upload Spreadsheet (CSV or Excel)
                            </label>
                            <input
                                type="file"
                                id="fileUpload"
                                className="form-control"
                                accept=".xlsx, .xls, .csv"
                                onChange={this.handleFileUpload}
                            />
                        </div>

                        {this.state.importResults.length > 0 && (
                            <div className="mt-3">
                                <h6>Import Results:</h6>
                                <ul className="list-group">
                                    {this.state.importResults.map((result, index) => (
                                        <li
                                            key={index}
                                            className={`list-group-item ${result.status === "success"
                                                    ? "list-group-item-success"
                                                    : "list-group-item-danger"
                                                }`}
                                        >
                                            <strong>{result.email}:</strong> {result.message}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {this.state.successMessages.map((msg, index) => (
                            <div key={index} className="alert alert-success mt-3">
                                {msg}
                            </div>
                        ))}

                        {this.state.errorMessages.map((msg, index) => (
                            <div key={index} className="alert alert-danger mt-3">
                                {msg}
                            </div>
                        ))}

                        <div className="d-flex justify-content-end mt-3">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={this.toggleModal}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { showModal } = this.state;

        return (
            <>
                <button className="btn btn-outline-secondary" onClick={this.toggleModal}>
                    Add users
                </button>

                {showModal && (
                    <div className="modal show d-block" tabIndex="-1">
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Add Users</h5>
                                    <button
                                        type="button"
                                        className="btn-close"
                                        onClick={this.toggleModal}
                                    ></button>
                                </div>
                                {this.renderModalContent()}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default AddStudents;