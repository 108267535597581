import React from "react";

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import ViewSubmissions from "./viewSubmissions";
import TeacherNavBar from "./teacherNavBar";
import ReactDOM from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUsers, faGear, faAward, faQuestionCircle, faPersonChalkboard } from '@fortawesome/free-solid-svg-icons'
import { getDatabase, ref, get, onValue } from 'firebase/database';
import { getAuth, signOut } from "firebase/auth";
import app from "../firebase";
import ManageStaff from "./manageStaff";
import SettingsPage from "./settings/settingsPage";
import TeacherChatHome from "../chat/teacherChatHome";
import ReportGeneratorHome from "./reportGenerator/reportGeneratorHome";





class TeacherPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = { schoolData: null }
    }

    componentDidMount() {
        this.getSchoolData()


    }

    getSchoolData = () => {
        const database = getDatabase(app);
        const schoolDatabasePath = ref(database, 'schools/' + this.props.user.school + '/public');

        onValue(schoolDatabasePath, (snapshot) => {
            const data = snapshot.val();
            console.log("from Database", data)
            if (data) {
                this.setState({ schoolData: data })
            } else {

            }
        });
    }


    render() {

        return (

            <>
                <div>
                    <TeacherNavBar schoolData={this.state.schoolData} user={this.props.user} mrCharlieBot={this.props.mrCharlieBot}/>

                    <Routes>
                        <Route path='/' element={<div>

                            {this.state.schoolData?.safeStart ? <div><TeacherChatHome schoolData={this.state.schoolData} /> </div> :

                                <div style={{ padding: "20px" }} className="row">
                                    {this.state.schoolData && 
                                    <ViewSubmissions schoolData={this.state.schoolData} mrCharlieBot={this.props.mrCharlieBot} />
                                    }
                                </div>
                            }
                        </div>} />

                        {this.state.schoolData &&
                            <>
                                <Route path='/marking' element={<ViewSubmissions schoolData={this.state.schoolData} />} />
                                <Route path='/staff' element={<ManageStaff schoolData={this.state.schoolData} />} />
                                <Route path='/settings/*' element={<SettingsPage schoolData={this.state.schoolData} mrCharlieBot={this.props.mrCharlieBot} />} />
                                <Route path='/gpt' element={<TeacherChatHome schoolData={this.state.schoolData} />} />
                                <Route path='/report-generator' element={<ReportGeneratorHome schoolData={this.state.schoolData} />} />
                            </>
                        }
                    </Routes>

                </div>
            </>
        );
    }
}

export default TeacherPage;