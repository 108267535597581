import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faUser, faGraduationCap, faCalendarAlt, faCheckCircle, faChartBar } from '@fortawesome/free-solid-svg-icons';
import FilterableCharts from "./filterableCharts";
import ChangeStudentPassword from "./changeStudentPassword";
import ChangeStudentEmail from "./changeStudentEmail"
import EditStudentDetails from "./editStudentDetails";
import DeleteStudentAccount from "./deleteStudentAccount";

class StudentDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            studentSubmissions: [],
            selectedArea: null,
            showCharts: false,
            showChangePasswordModal: false,
            showChangeEmailModal: false,
            showDeleteAccountModal: false,
        };
    }

    componentDidMount() {
        this.filterStudentSubmissions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.student !== this.props.student) {
            this.filterStudentSubmissions();
        }
    }

    filterStudentSubmissions = () => {
        const { student, extendedSubmissions } = this.props;
        const studentSubmissions = extendedSubmissions.filter(submission => submission.uid === student.uid);
        this.setState({ studentSubmissions });
    }

    handleAreaClick = (area) => {
        this.setState({ selectedArea: area });
    }

    handleBackToAreas = () => {
        this.setState({ selectedArea: null });
    }

    toggleChartsVisibility = () => {
        this.setState((prevState) => ({ showCharts: !prevState.showCharts }));
    }

    calculateProgress = (area) => {
        const areaCharacteristics = area.characteristics || [];
        const totalCharacteristics = areaCharacteristics.length;

        const submissionsForArea = this.state.studentSubmissions.filter(submission => submission.area === area.path);
        const completedCount = submissionsForArea.filter(sub => sub.badge).length;

        return totalCharacteristics ? Math.round((completedCount / totalCharacteristics) * 100) : 0;
    }

    renderAreaCard = (area) => {
        const progress = this.calculateProgress(area);

        return (
            <div
                key={area.id}
                className="col-lg-2 col-md-3 col-sm-4 col-xs-6 text-center mb-3"
                onClick={() => this.handleAreaClick(area)}
                style={{ cursor: 'pointer' }}
            >
                <img
                    src={area.imageUrl}
                    alt={area.name}
                    className="img-fluid mb-2 rounded"
                />

                <div className="progress" style={{ height: '12px', borderRadius: '5px', overflow: 'hidden', backgroundColor: '#f0f0f0', marginTop: "1rem" }}>
                    <div
                        className={`progress-bar`}
                        role="progressbar"
                        style={{
                            width: `${progress}%`,
                            transition: 'width 0.5s ease',
                            fontWeight: 'bold',
                            fontSize: '0.75rem',
                            color: 'white',
                        }}
                        aria-valuenow={progress}
                        aria-valuemin="0"
                        aria-valuemax="100"
                        title={`${progress}%`}
                    >
                        {progress}%
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { student, onBack, areasData, schoolData } = this.props;
        const { studentSubmissions, selectedArea, showCharts } = this.state;

        let filteredAreas = areasData || [];
        if (schoolData.stage === "secondary" || schoolData.stage === "primary") {
            filteredAreas = filteredAreas.filter(item => item.stage === schoolData.stage);
        } else if (schoolData.stage === "special") {
            // Handle special schools
        }

        const customAreasData = (areasData || []).filter(item => item.stage === "custom").sort((a, b) => a.sortOrder - b.sortOrder);

        return (
            <div className="container my-4">
                <button className="btn btn-outline-secondary mb-3" onClick={onBack}>
                    <FontAwesomeIcon icon={faArrowLeft} /> Back to All Students
                </button>

                <div className="card mb-4">
                    <div className="card-body">
                        <div className="row">
                            <div className="col">
                                <h2 className="card-title">
                                    <FontAwesomeIcon icon={faUser} /> {student.firstName} {student.lastName}
                                </h2>
                                <p className="text-muted" > {student.email.includes('b3w0r1d+')
                    ? student.email.replace('b3w0r1d+', '').replace('@beworldclass.org', '')
                    : student.email} </p>

                                <h5 className="card-subtitle mb-2 text-muted">
                                    <FontAwesomeIcon icon={faGraduationCap} /> Class: {student.class}
                                </h5>
                                <h5 className="card-subtitle mb-2 text-muted">
                                    <FontAwesomeIcon icon={faCalendarAlt} /> Year Group: {student.yearGroup || "N/A"}
                                </h5>

                            </div>
                            <div className="col text-end">
                                {!this.props.mrCharlieBot &&
                                    <button className="btn btn-outline-primary mb-3" onClick={this.toggleChartsVisibility}>
                                        <FontAwesomeIcon icon={faChartBar} /> {showCharts ? "Hide Charts" : "Show Charts"}
                                    </button>
                                }
                                <br />

                                <EditStudentDetails user={student} schoolData={schoolData} />

                            </div>
                        </div>

                    </div>
                </div>
                {!this.props.mrCharlieBot && <>
                    {showCharts ? (
                        <FilterableCharts
                            extendedSubmissions={studentSubmissions}
                            areas={areasData}
                            schoolData={schoolData}
                            mini={true}
                        />
                    ) : (
                        <div className="card mb-4">
                            <div className="card-body">
                                {!selectedArea && (
                                    <div className="my-4">
                                        <div className="row">
                                            {customAreasData.map(this.renderAreaCard)}
                                            <h3>World Class Areas</h3>
                                            {filteredAreas.map(this.renderAreaCard)}
                                        </div>
                                    </div>
                                )}

                                {selectedArea && (
                                    <div className="my-4">
                                        <button className="btn btn-outline-secondary mb-3" onClick={this.handleBackToAreas}>
                                            <FontAwesomeIcon icon={faArrowLeft} /> Back to Areas
                                        </button>
                                        {/* Selected Area Characteristics */}
                                        <h4>{selectedArea.motto}</h4>
                                        <ul className="list-group">
                                            {selectedArea.characteristics.map((char, index) => {
                                                const submission = studentSubmissions.find(sub => sub.characteristic === char.name);
                                                const isCompleted = submission && submission.badge;
                                                return (
                                                    <li key={char.id || index} className="list-group-item">
                                                        <h5 className="text-primary d-flex align-items-center">
                                                            {isCompleted && <FontAwesomeIcon icon={faCheckCircle} className="text-success me-2" size="lg" />}
                                                            {char.name}
                                                        </h5>
                                                        {submission && (
                                                            <>
                                                                <p><strong>Description:</strong> {submission.description}</p>
                                                                <p><strong>Source:</strong> {submission.source}</p>
                                                                <p><strong>Date:</strong> {submission.date}</p>
                                                                <p>
                                                                    <strong>Confidence:</strong>
                                                                    <span
                                                                        className={`badge rounded-pill ${submission.confidence <= 1 ? "bg-danger" :
                                                                            submission.confidence <= 3 ? "bg-warning" :
                                                                                "bg-success"
                                                                            }`}
                                                                    >
                                                                        {submission.confidence} / 5
                                                                    </span>

                                                                </p>
                                                            </>
                                                        )}
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </>}
            </div>
        );
    }
}

export default StudentDetails;
