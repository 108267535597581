import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import RandomWalkGraph from './randomWalkGraph';
import { calculateStockValue } from './simulationRunner';
import stockMeta from './stockMeta';

function InvestmentsWindow({ closeWindow, simState, setSimState }) {
  // Investment option definitions.
  const investmentOptions = [
    { id: 'cashIsa', title: 'Cash ISA' },
    { id: 'ssaIsa', title: 'Stocks and Shares ISA' },
    { id: 'bonds', title: 'Bonds' },
    { id: 'managedFund', title: 'Managed Fund' },
    { id: 'ssaAccount', title: 'Stocks and Shares Account' },
  ];

  // Local state to manage selected option, selected stock, and trade quantity.
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStock, setSelectedStock] = useState(null);
  const [tradeQuantity, setTradeQuantity] = useState('');

  // Cash ISA option is fixed.
  const cashIsaOption = {
    id: 'CashISA',
    name: 'Cash ISA',
    type: 'Cash',
    volatility: 0,
    upwardPressure: 0,
    dividends: 5, // 5% dividend yield
    startingPrice: 1, // Price is fixed at 1
  };

  // For Stocks and Shares ISA, get stocks from stockMeta.
  const stocksList = Object.entries(stockMeta).map(([id, meta]) => ({
    id,
    name: meta.name,
    type: meta.type || 'Individual',
    volatility: meta.volatility,
    upwardPressure: meta.upwardPressure,
    dividends: meta.dividends !== undefined ? meta.dividends : 0,
    startingPrice: meta.startingPrice !== undefined ? meta.startingPrice : 100,
  }));

  // Handle option selection.
  const handleOptionClick = (option) => {
    setSelectedOption(option.id);
    setSelectedStock(null);
  };

  // Handle stock selection.
  const handleStockClick = (stock) => {
    setSelectedStock(stock);
  };

  // Helper: Get the current price of a stock.
  const getCurrentPrice = (stock) => {
    if (selectedOption === 'cashIsa' && stock.id === 'CashISA') {
      return 1; // Fixed for Cash ISA.
    }
    // Use calculateStockValue from simulationRunner.
    return calculateStockValue(
      { stockId: stock.id, purchaseWeek: simState.week, purchasePrice: stock.startingPrice },
      simState.week
    );
  };

  // Handle buying the selected stock.
  const handleBuy = () => {
    const qty = parseFloat(tradeQuantity);
    if (isNaN(qty) || qty <= 0) {
      alert("Enter a valid quantity to buy.");
      return;
    }
    const currentPrice = getCurrentPrice(selectedStock);
    const cost = currentPrice * qty;
    if (cost > simState.accountBalance) {
      alert("Not enough funds to buy.");
      return;
    }
    setSimState(prevState => {
      const existing = prevState.purchasedStocks.find(p => p.stockId === selectedStock.id);
      let newPurchased;
      if (existing) {
        const totalCost = existing.avgPrice * existing.quantity + cost;
        const newQuantity = existing.quantity + qty;
        const newAvg = totalCost / newQuantity;
        newPurchased = prevState.purchasedStocks.map(p =>
          p.stockId === selectedStock.id ? { ...p, quantity: newQuantity, avgPrice: newAvg } : p
        );
      } else {
        newPurchased = [...prevState.purchasedStocks, { stockId: selectedStock.id, quantity: qty, avgPrice: currentPrice }];
      }
      return {
        ...prevState,
        accountBalance: prevState.accountBalance - cost,
        purchasedStocks: newPurchased,
        transactions: [
          ...prevState.transactions,
          {
            type: 'buy',
            stockId: selectedStock.id,
            quantity: qty,
            price: currentPrice,
            week: simState.week,
            description: `Bought ${qty} shares of ${selectedStock.name} at $${currentPrice.toFixed(2)}`
          }
        ]
      };
    });
    setTradeQuantity('');
  };

  // Handle selling the selected stock.
  const handleSell = () => {
    const qty = parseFloat(tradeQuantity);
    if (isNaN(qty) || qty <= 0) {
      alert("Enter a valid quantity to sell.");
      return;
    }
    const existing = simState.purchasedStocks.find(p => p.stockId === selectedStock.id);
    if (!existing || existing.quantity < qty) {
      alert("Not enough shares to sell.");
      return;
    }
    const currentPrice = getCurrentPrice(selectedStock);
    const revenue = currentPrice * qty;
    setSimState(prevState => {
      let newPurchased;
      if (existing.quantity === qty) {
        newPurchased = prevState.purchasedStocks.filter(p => p.stockId !== selectedStock.id);
      } else {
        newPurchased = prevState.purchasedStocks.map(p =>
          p.stockId === selectedStock.id ? { ...p, quantity: p.quantity - qty } : p
        );
      }
      const profitLoss = (currentPrice - existing.avgPrice) * qty;
      return {
        ...prevState,
        accountBalance: prevState.accountBalance + revenue,
        purchasedStocks: newPurchased,
        transactions: [
          ...prevState.transactions,
          {
            type: 'sell',
            stockId: selectedStock.id,
            quantity: qty,
            price: currentPrice,
            week: simState.week,
            description: `Sold ${qty} shares of ${selectedStock.name} at $${currentPrice.toFixed(2)} (P/L: $${profitLoss.toFixed(2)})`
          }
        ]
      };
    });
    setTradeQuantity('');
  };

  // Get current holdings for the selected stock.
  const getHoldings = (stockId) => {
    const holding = simState.purchasedStocks.find(p => p.stockId === stockId);
    return holding ? holding.quantity : 0;
  };

  // Calculate overall profit/loss for the selected stock.
  const getProfitLoss = (stock) => {
    const holding = simState.purchasedStocks.find(p => p.stockId === stock.id);
    if (!holding) return 0;
    const currentPrice = getCurrentPrice(stock);
    return (currentPrice - holding.avgPrice) * holding.quantity;
  };

  return (
    <div className="window">
      <div className="window-header d-flex justify-content-between align-items-center">
        <div className="window-title d-flex align-items-center">
          <div className="window-title-icon">
            <FontAwesomeIcon icon={faChartLine} />
          </div>
          <span>Investments</span>
        </div>
        <div className="window-controls d-flex">
          <div className="window-button window-minimize"></div>
          <div className="window-button window-maximize"></div>
          <div className="window-button window-close" onClick={closeWindow}></div>
        </div>
      </div>
      <div className="window-content" style={{ padding: '20px' }}>
        {/* Investment Options */}
        <div className="investment-options mb-3">
          {investmentOptions.map((option) => (
            <button
              key={option.id}
              className={`btn btn-secondary me-2 ${selectedOption === option.id ? 'active' : ''}`}
              onClick={() => handleOptionClick(option)}
            >
              {option.title}
            </button>
          ))}
        </div>

        {/* Display content based on selected option */}
        {selectedOption === 'cashIsa' && (
          <div>
            <h4>Cash ISA</h4>
            <div className="list-group">
              <button
                className={`list-group-item list-group-item-action ${selectedStock && selectedStock.id === cashIsaOption.id ? 'active' : ''}`}
                onClick={() => handleStockClick(cashIsaOption)}
              >
                {cashIsaOption.name} ({cashIsaOption.type})
              </button>
            </div>
            {selectedStock && (
              <div className="mt-4">
                <h5>{selectedStock.name} Performance</h5>
                <RandomWalkGraph
                  seed={selectedStock.id}
                  volatility={selectedStock.volatility}
                  upwardPressure={selectedStock.upwardPressure}
                  startValue={selectedStock.startingPrice}
                  fixedReturn={5}
                />
                <div className="mt-2">
                  <strong>Dividends: </strong>{selectedStock.dividends}%
                </div>
                <div className="mt-2">
                  <p>Holdings: {getHoldings(selectedStock.id)} shares</p>
                  <p>Profit/Loss: ${getProfitLoss(selectedStock).toFixed(2)}</p>
                </div>
                <div className="mt-2">
                  <input
                    type="number"
                    value={tradeQuantity}
                    onChange={(e) => setTradeQuantity(e.target.value)}
                    placeholder="Quantity"
                  />
                  <button className="btn btn-success me-2" onClick={handleBuy}>Buy</button>
                  <button className="btn btn-danger" onClick={handleSell}>Sell</button>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedOption === 'ssaIsa' && (
          <div>
            <h4>Stocks and Shares ISA - Buy/Sell Stocks</h4>
            <div className="list-group">
              {stocksList.map((stock) => (
                <button
                  key={stock.id}
                  className={`list-group-item list-group-item-action ${selectedStock && selectedStock.id === stock.id ? 'active' : ''}`}
                  onClick={() => handleStockClick(stock)}
                >
                  {stock.name} ({stock.type})
                </button>
              ))}
            </div>
            {selectedStock && (
              <div className="mt-4">
                <h5>{selectedStock.name} Performance</h5>
                <RandomWalkGraph
                  seed={selectedStock.id}
                  volatility={selectedStock.volatility}
                  upwardPressure={selectedStock.upwardPressure}
                  startValue={selectedStock.startingPrice}
                />
                <div className="mt-2">
                  <strong>Dividends: </strong>{selectedStock.dividends}%
                </div>
                <div className="mt-2">
                  <p>Current Price: ${getCurrentPrice(selectedStock).toFixed(2)}</p>
                  <p>Holdings: {getHoldings(selectedStock.id)} shares</p>
                  <p>Profit/Loss: ${getProfitLoss(selectedStock).toFixed(2)}</p>
                </div>
                <div className="mt-2">
                  <input
                    type="number"
                    value={tradeQuantity}
                    onChange={(e) => setTradeQuantity(e.target.value)}
                    placeholder="Quantity"
                  />
                  <button className="btn btn-success me-2" onClick={handleBuy}>Buy</button>
                  <button className="btn btn-danger" onClick={handleSell}>Sell</button>
                </div>
              </div>
            )}
          </div>
        )}

        {selectedOption === 'bonds' && (
          <div>
            <h4>Bonds</h4>
            <p>Bonds details and performance will be available soon.</p>
          </div>
        )}

        {selectedOption === 'managedFund' && (
          <div>
            <h4>Managed Fund</h4>
            <p>Managed Fund details and performance will be available soon.</p>
          </div>
        )}

        {selectedOption === 'ssaAccount' && (
          <div>
            <h4>Stocks and Shares Account</h4>
            <p>Stocks and Shares Account details and performance will be available soon.</p>
          </div>
        )}

        {!selectedOption && (
          <div>
            <p>Please select an investment option above.</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default InvestmentsWindow;
