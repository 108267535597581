import React, { useMemo } from 'react';

// A simple hash function to convert a string into a numeric seed.
function hashString(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
    }
    return Math.abs(hash);
}

// A basic seeded random number generator using a linear congruential generator.
function seededRandomGenerator(seed) {
    const m = 0x80000000; // 2^31
    const a = 1103515245;
    const c = 12345;
    let state = seed % m;
    return function () {
        state = (a * state + c) % m;
        return state / m;
    };
}

// Generate a random walk given a seed, number of steps, start value,
// volatility (step size variation), and upward pressure (bias).
function generateRandomWalk(seed, steps, startValue, volatility, upwardPressure) {
    const random = seededRandomGenerator(seed);
    const data = [startValue];
    for (let i = 1; i < steps; i++) {
        // Calculate the percentage change (e.g. -10% to +10% if volatility is 20 and upwardPressure is small)
        const pctChange = (random() - 0.5) * volatility + upwardPressure;
        // Apply the percentage change: newValue = previousValue * (1 + pctChange/100)
        const newValue = data[i - 1] * (1 + pctChange / 100);
        data.push(newValue);
    }
    return data;
}


function RandomWalkGraph({ seed, volatility, upwardPressure, startValue = 100, fixedReturn }) {
    const steps = 50;
    const numericSeed = useMemo(() => hashString(seed), [seed]);
    const data = useMemo(
        () => generateRandomWalk(numericSeed, steps, startValue, volatility, upwardPressure),
        [numericSeed, steps, startValue, volatility, upwardPressure]
    );

    // Determine the scale for the SVG.
    const min = Math.min(...data);
    const max = Math.max(...data);
    const width = 300;
    const height = 150;
    const margin = 10;

    const points = data
        .map((val, i) => {
            const x = margin + (i / (steps - 1)) * (width - 2 * margin);
            const y = height - margin - ((val - min) / (max - min)) * (height - 2 * margin);
            return `${x},${y}`;
        })
        .join(' ');

    // Compute the yearly return.
    // If fixedReturn is provided (for Cash ISA), use that value.
    // Otherwise, compute return as: ((finalValue - startValue)/startValue * 100) plus the dividend yield.
    const finalValue = data[data.length - 1];
    const computedReturn = ((finalValue - startValue) / startValue) * 100;
    const yearlyReturn = fixedReturn !== undefined ? fixedReturn : computedReturn.toFixed(2);

    return (
        <div>
            <svg width={width} height={height} style={{ border: '1px solid #ccc' }}>
                <polyline fill="none" stroke="#2e3192" strokeWidth="2" points={points} />
            </svg>
            <div className="mt-2">
                <strong>Yearly Return: </strong>{yearlyReturn}%
            </div>
        </div>
    );
}

export default RandomWalkGraph;
