import React from "react";
import { Formik, Field, Form } from "formik";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";
import { getFirestore, doc, setDoc } from "firebase/firestore";

import app from '../firebase.js';

class SignUpSchoolDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      submitAttempt: false,
      values: {},
      serverError: null,
    };
  }

  signUp = () => {
    createUserWithEmailAndPassword(
      getAuth(app),
      this.state.values.email,
      this.state.values.password
    )
      .then((userCredential) => {
        const user = userCredential.user;
        this.setState({ user: user });
        this.createSchoolInDatabase(user);
      })
      .catch((error) => {
        const errorMessage = error.message;
        this.setState({ serverError: errorMessage });
        console.error(errorMessage);
      });
  };

  createSchoolInDatabase = (user) => {
    // Generate a random school path ID
    function makeId(length) {
      let result = '';
      const characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    }

    const schoolPath = makeId(10);
    const database = getDatabase(app);
    const inSchoolPath = ref(database, 'schools/' + schoolPath);
    const userPath = ref(database, 'users/' + user.uid);

    // Parse the configuration JSON provided by the user
    let configData = {};
    try {
      configData = JSON.parse(this.state.values.config);
    } catch (e) {
      // If parsing fails, default to an empty object (this should not happen
      // if our validation already caught invalid JSON)
      configData = {};
    }
    
    // Add demo flag and school path to the configuration
    configData.demo = true;
    configData.path = schoolPath;

    // Save the user data with their role and associated school
    set(userPath, {
      role: "teacher",
      schools: {
        [schoolPath]: true,
      }
    })
      .then(() => {
        // Save the school data in realtime database using the parsed configuration JSON
        return set(inSchoolPath, {
          staff: {
            [user.uid]: {
              firstName: this.state.values.firstName,
              lastName: this.state.values.lastName,
            }
          },
          public: configData
        });
      })
      .then(() => {
        // Create the school entry in Firestore as well
        this.createSchoolInFirestore(schoolPath, user, configData);
      })
      .catch((error) => {
        this.setState({ serverError: error.message });
        console.error("Error saving school data: ", error);
      });
  };

  createSchoolInFirestore = (schoolId, user, configData) => {
    const db = getFirestore(app);
    const schoolRef = doc(db, 'schools', schoolId);

    setDoc(schoolRef, {
      public: configData,
      staff: {
        [user.uid]: true
      }
    })
      .then(() => {
        console.log("School created in Firestore");
      })
      .catch((error) => {
        this.setState({ serverError: error.message });
        console.error("Error creating school in Firestore: ", error);
      });
  };

  render() {
    return (
      <div style={{ textAlign: "center", padding: "2rem" }}>
        <div className="card" style={{ textAlign: "left", padding: "2rem" }}>
          <Formik
            initialValues={{}}
            onSubmit={async (values) => {
              await new Promise((resolve) => setTimeout(resolve, 500));
              if (Object.keys(this.state.errors).length === 0) {
                this.setState({ submitAttempt: true, values: values }, () => {
                  this.signUp();
                });
              } else {
                this.setState({ submitAttempt: true });
              }
            }}
            validate={(values) => {
              const errors = {};

              // Validate configuration JSON field
              if (!values.config) {
                errors.config = "Required";
              } else {
                try {
                  JSON.parse(values.config);
                } catch (e) {
                  errors.config = "Invalid JSON configuration";
                }
              }

              // User account fields validation
              if (!values.firstName) {
                errors.firstName = "Required";
              }
              if (!values.lastName) {
                errors.lastName = "Required";
              }
              if (!values.email) {
                errors.email = "Required";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Invalid email address";
              }
              if (values.email !== values.confirmEmail) {
                errors.confirmEmail = "Email address does not match";
              }
              if (!values.password) {
                errors.password = "Required";
              } else if (values.password.length < 6) {
                errors.password = "Password must be at least 6 characters in length";
              }
              if (values.password !== values.confirmPassword) {
                errors.confirmPassword = "Password does not match";
              }

              this.setState({ errors: errors });
              return errors;
            }}
          >
            <Form>
              <h2 className="form-heading">School Demo Config</h2>

              <label>Configuration JSON</label>
              <Field
                as="textarea"
                name="config"
                rows="8"
                className={`${
                  this.state.errors.config && this.state.submitAttempt ? "is-invalid" : ""
                } form-control`}
                placeholder="{}"
              />
              {this.state.errors.config && this.state.submitAttempt && (
                <div className="invalid-feedback">{this.state.errors.config}</div>
              )}

              <hr style={{ margin: "2rem 0" }} />

              <h4>Your Account Details</h4>
              <div className="row">
                <div className="col">
                  <label>First name</label>
                  <Field
                    name="firstName"
                    type="text"
                    className={`${
                      this.state.errors.firstName && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.firstName && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.firstName}</div>
                  )}
                </div>
                <div className="col">
                  <label>Last Name</label>
                  <Field
                    name="lastName"
                    type="text"
                    className={`${
                      this.state.errors.lastName && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.lastName && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.lastName}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Email Address</label>
                  <Field
                    name="email"
                    type="email"
                    className={`${
                      this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.email && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.email}</div>
                  )}
                </div>
                <div className="col">
                  <label>Confirm Email Address</label>
                  <Field
                    name="confirmEmail"
                    type="email"
                    className={`${
                      this.state.errors.confirmEmail && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.confirmEmail && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.confirmEmail}</div>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label>Create Password</label>
                  <Field
                    name="password"
                    type="password"
                    className={`${
                      this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.password && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.password}</div>
                  )}
                </div>
                <div className="col">
                  <label>Confirm Password</label>
                  <Field
                    name="confirmPassword"
                    type="password"
                    className={`${
                      this.state.errors.confirmPassword && this.state.submitAttempt ? "is-invalid" : ""
                    } form-control`}
                  />
                  {this.state.errors.confirmPassword && this.state.submitAttempt && (
                    <div className="invalid-feedback">{this.state.errors.confirmPassword}</div>
                  )}
                </div>
              </div>

              {this.state.serverError && (
                <div className="alert alert-danger" style={{ marginTop: "1rem" }}>
                  {this.state.serverError}
                </div>
              )}
              <button className="btn btn-primary" type="submit" style={{ marginTop: "1rem" }}>
                Submit
              </button>
            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}

export default SignUpSchoolDemo;
