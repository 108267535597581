import React, { useState } from 'react';
import { getDatabase, ref, set } from "firebase/database";
import app from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faLink } from '@fortawesome/free-solid-svg-icons';

const CreateResources = (props) => {
  // The overall resource tree from Firebase (or props)
  const [resources, setResources] = useState(props.schoolData.resources || []);
  const [message, setMessage] = useState('');
  // currentPath is an array of indices representing the navigation depth in the tree
  const [currentPath, setCurrentPath] = useState([]);
  // For adding new items at the current folder level
  const [newItemType, setNewItemType] = useState('folder');
  const [newItemName, setNewItemName] = useState('');
  const [newItemUrl, setNewItemUrl] = useState('');

  // Helper to get the current folder based on currentPath
  const getCurrentFolder = () => {
    let folder = { children: resources }; // virtual root folder
    for (const index of currentPath) {
      folder = folder.children[index];
    }
    return folder;
  };

  const currentFolder = getCurrentFolder();

  // Update Firebase with the new resource tree
  const updateResources = (newResources) => {
    const db = getDatabase(app);
    const schoolId = props.schoolData.path;
    const resourcesRef = ref(db, `schools/${schoolId}/public/resources`);
    set(resourcesRef, newResources)
      .then(() => {

        setResources(newResources);
      })
      .catch((error) => {
        console.error("Error updating resources: ", error);
        setMessage("Error updating resources.");
      });
  };

  // Add a new resource (folder or link) to the current folder
  const addResource = (e) => {
    e.preventDefault();
    let newResource;
    if (newItemType === 'folder') {
      newResource = { type: 'folder', name: newItemName, children: [] };
    } else {
      newResource = { type: 'link', url: newItemUrl, name:  newItemName};
    }
    // Clone the resource tree
    const newResources = [...resources];
    let folder = { children: newResources };
    for (let i = 0; i < currentPath.length; i++) {
      folder = folder.children[currentPath[i]];
    }
    // Add the new resource to the current folder
    folder.children.push(newResource);
    updateResources(newResources);
    setNewItemName('');
    setNewItemUrl('');
  };

  // When a folder icon is clicked, navigate into it
  const openFolder = (index) => {
    setCurrentPath([...currentPath, index]);
  };

  // Go back one level in the folder hierarchy
  const goBack = () => {
    setCurrentPath(currentPath.slice(0, currentPath.length - 1));
  };

  // Desktop grid styling
  const iconStyle = {
    width: '100px',
    textAlign: 'center',
    margin: '10px',
    cursor: 'pointer'
  };

  return (
    <div className="container card">
      <div className="card-body">
        <h3>Add Resources</h3>
        {message && <div className="alert alert-info">{message}</div>}
        {/* Navigation controls */}
        <div className="mb-3">
          {currentPath.length > 0 && (
            <button className="btn btn-secondary mb-2" onClick={goBack}>Back</button>
          )}
        </div>
        {/* Desktop grid */}
        <div className="d-flex flex-wrap">
          {!(currentFolder?.children?.length) && <h5>This folder is currently empty.</h5>}
          {currentFolder.children && currentFolder.children.map((item, index) => (
            <div key={index} style={iconStyle} onClick={() => {
              if (item.type === 'folder') {
                openFolder(index);
              }
            }}>
              {item.type === 'folder' ? (
                <div>
                  <FontAwesomeIcon icon={faFolder} size="3x" />
                  <div>{item.name}</div>
                </div>
              ) : (
                <div>
                  <FontAwesomeIcon icon={faLink} size="3x" />
                  <div style={{ fontSize: '0.8em' }}>
                    <a href={item.url.startsWith('http') ? item.url : `https://${item.url}`} target="_blank" rel="noopener noreferrer">
                      {item.name ? item.name : item.url}
                    </a>

                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Form to add a new resource at the current folder level */}
        <div className="mt-4">
          {(currentFolder?.children?.length > 0) && <hr />}

          <h5>Add New</h5>
          <form onSubmit={addResource} className="d-flex align-items-center">
            <select
              value={newItemType}
              onChange={(e) => setNewItemType(e.target.value)}
              className="form-select w-auto me-2"
            >
              <option value="folder">Folder</option>
              <option value="link">Link</option>
            </select>
            <input
              type="text"
              placeholder={newItemType === 'folder' ? "Folder Name" : "Link Name"}
              value={newItemName}
              onChange={(e) => setNewItemName(e.target.value)}
              className="form-control me-2"
              required
            />
            {newItemType === 'link' && (
              <input
                type="text"
                placeholder="Link URL"
                value={newItemUrl}
                onChange={(e) => setNewItemUrl(e.target.value)}
                className="form-control me-2"
                required
              />
            )}
            <button type="submit" className="btn btn-primary">Add</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateResources;
