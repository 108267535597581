import React from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import Grid from "./grid/grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMailForward, faUpload, faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { CSVLink } from "react-csv";

class ManageStaff extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staff: null,
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      loading: false,
      loadingMessage: "",
      successMessages: [],
      errorMessages: [],
      showAddForm: false,
      showInviteForm: false,
      showImportForm: false,
      importResults: [],
    };
  }

  componentDidMount() {
    this.getStaff(this.props.schoolData.path);
  }

  getStaff = (schoolPath) => {
    this.setState({ loading: true, loadingMessage: "Loading staff..." });
    const functions = getFunctions();
    const getSchoolStaff = httpsCallable(functions, "getSchoolStaff");
    getSchoolStaff({ school: schoolPath })
      .then((result) => {
        const users = result.data.authData.users;
        let staffValues = result.data.values;
        let staffValuesArray = [];

        for (let i = 0; i < users.length; i++) {
          staffValues[users[i].uid] = {
            ...staffValues[users[i].uid],
            email: users[i].email,
            uid: users[i].uid,
            key: users[i].uid,
          };
        }

        staffValuesArray = Object.values(staffValues);
        this.setState({ staff: staffValuesArray, loading: false });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          errorMessages: [`Failed to load staff: ${error.message}`],
        });
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  addUser = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
      loadingMessage: "Adding user...",
      errorMessages: [],
      successMessages: [],
    });

    try {
      const functions = getFunctions();
      const createSchoolStaff = httpsCallable(functions, "createSchoolStaff");
      const result = await createSchoolStaff({
        school: this.props.schoolData.path,
        email: this.state.email,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        password: this.state.password,
      });

      if (result.data.error) throw new Error(result.data.error);

      this.setState({
        successMessages: [`User ${this.state.email} added successfully`],
        email: "",
        firstName: "",
        lastName: "",
        password: "",
      });
      await this.getStaff(this.props.schoolData.path);
    } catch (error) {
      this.setState({
        errorMessages: [`Error adding user: ${error.message}`],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  inviteUser = async (e) => {
    e.preventDefault();
    const { email, firstName, lastName } = this.state;
    this.setState({
      loading: true,
      loadingMessage: "Sending invitation...",
      errorMessages: [],
      successMessages: [],
    });

    try {
      await this.inviteUserFromData({ email, firstName, lastName });
      this.setState({
        successMessages: [`Invitation sent to ${email}`],
        email: "",
        firstName: "",
        lastName: "",
      });
      await this.getStaff(this.props.schoolData.path);
    } catch (error) {
      this.setState({
        errorMessages: [`Error inviting user: ${error.message}`],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  inviteUserFromData = async (user) => {
    try {
      const functions = getFunctions();
      const inviteSchoolStaff = httpsCallable(functions, "inviteSchoolStaff");
      const result = await inviteSchoolStaff({
        school: this.props.schoolData.path,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      });

      console.log("result of invite", result)

      if (result.data.error) throw new Error(result.data.error.errorInfo?.message);
      await this.sendPasswordReset(user.email);
      return { ...user, status: "success" };
    } catch (error) {
      console.log("there was an error", error)
      throw new Error(`Failed to invite ${user.email}: ${error}`);
    }
  };

  sendPasswordReset = async (email) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      throw new Error(`Password reset failed: ${error.message}`);
    }
  };

  handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    this.setState({
      loading: true,
      loadingMessage: "Processing spreadsheet...",
      importResults: [],
      errorMessages: [],
      successMessages: [],
    });

    try {
      const data = await this.readExcelFile(file);
      const results = [];

      for (const row of data) {
        try {
          const user = this.validateRow(row);
          await this.inviteUserFromData(user);
          results.push({ ...user, status: "success", message: "Invited successfully" });
        } catch (error) {
          results.push({
            email: row.email,
            status: "error",
            message: error.message,
          });
        }
      }

      this.setState({
        importResults: results,
        successMessages: ["File processing completed"],
      });
      await this.getStaff(this.props.schoolData.path);
    } catch (error) {
      this.setState({
        errorMessages: [`File processing failed: ${error.message}`],
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  readExcelFile = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const workbook = XLSX.read(event.target.result, { type: "binary" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          resolve(XLSX.utils.sheet_to_json(worksheet, { defval: "" }));
        } catch (error) {
          reject(new Error("Invalid file format"));
        }
      };
      reader.onerror = () => reject(new Error("Error reading file"));
      reader.readAsBinaryString(file);
    });
  };

  validateRow = (row) => {
    const email = row["Email"] || row.email;
    const firstName = row["First Name"] || row.firstName;
    const lastName = row["Last Name"] || row.lastName;

    if (!email || !firstName || !lastName) {
      throw new Error("Missing required fields");
    }

    return { email, firstName, lastName };
  };

  toggleForm = (formName) => {
    this.setState({
      showAddForm: formName === "add",
      showInviteForm: formName === "invite",
      showImportForm: formName === "import",
      errorMessages: [],
      successMessages: [],
    });
  };

  renderFormInput = (name, placeholder, type = "text") => (
    <div className="mb-3">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        value={this.state[name]}
        onChange={this.handleChange}
        className="form-control"
        required
      />
    </div>
  );

  render() {
    const headers = [
      { label: "First Name", key: "firstName" },
      { label: "Last Name", key: "lastName" },
      { label: "Email", key: "email" },
    ];

    return (
      <div className="container py-4">
        <h3 className="mb-4">Manage Staff</h3>

        <div className="mb-4">
          <div className="btn-group">
            <button
              className={`btn btn-outline-primary ${this.state.showAddForm ? "active" : ""}`}
              onClick={() => this.toggleForm("add")}
            >
              <FontAwesomeIcon icon={faPlus} /> Add Staff
            </button>
            <button
              className={`btn btn-outline-primary ${this.state.showInviteForm ? "active" : ""}`}
              onClick={() => this.toggleForm("invite")}
            >
              <FontAwesomeIcon icon={faMailForward} /> Email Invite
            </button>
            <button
              className={`btn btn-outline-primary ${this.state.showImportForm ? "active" : ""}`}
              onClick={() => this.toggleForm("import")}
            >
              <FontAwesomeIcon icon={faUpload} /> Import Spreadsheet
            </button>
          </div>
        </div>



        {this.state.showAddForm && (
          <form onSubmit={this.addUser} className="card mb-4">
            <div className="card-body">
              <h5 className="card-title mb-4">Add New Staff Member</h5>
              {this.renderFormInput("firstName", "First Name")}
              {this.renderFormInput("lastName", "Last Name")}
              {this.renderFormInput("email", "Email", "email")}
              {this.renderFormInput("password", "Password", "password")}
              <button type="submit" className="btn btn-primary">
                Add User
              </button>
            </div>
          </form>
        )}

        {this.state.showInviteForm && (
          <form onSubmit={this.inviteUser} className="card mb-4">
            <div className="card-body">
              <h5 className="card-title mb-4">Invite Staff Member</h5>
              {this.renderFormInput("firstName", "First Name")}
              {this.renderFormInput("lastName", "Last Name")}
              {this.renderFormInput("email", "Email", "email")}
              <button type="submit" className="btn btn-primary">
                Send Invite
              </button>
            </div>
          </form>
        )}

        {this.state.showImportForm && (
          <div className="card mb-4">
            <div className="card-body">
              <h5 className="card-title mb-4">Import Staff Members</h5>
              <div className="mb-3">
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  onChange={this.handleFileUpload}
                  className="form-control"
                  id="fileUpload"
                />
                <div className="form-text">
                  Upload a spreadsheet with columns: First Name, Last Name, Email
                </div>
              </div>
              <CSVLink
                data={[{ firstName: "", lastName: "", email: "" }]}
                headers={headers}
                filename="staff_template.csv"
                className="btn btn-outline-secondary"
              >
                Download Template
              </CSVLink>

              {this.state.loading && (
                <div className="alert alert-info d-flex align-items-center">
                  <FontAwesomeIcon icon={faSpinner} spin className="me-2" />
                  {this.state.loadingMessage}
                </div>
              )}

              {this.state.errorMessages.map((msg, i) => (
                <div key={i} className="alert alert-danger alert-dismissible fade show">
                  {msg}
                  <button type="button" className="btn-close" onClick={() =>
                    this.setState({ errorMessages: [] })} />
                </div>
              ))}

              {this.state.successMessages.map((msg, i) => (
                <div key={i} className="alert alert-success alert-dismissible fade show">
                  {msg}
                  <button type="button" className="btn-close" onClick={() =>
                    this.setState({ successMessages: [] })} />
                </div>
              ))}

              {this.state.importResults.length > 0 && (
                <div className="mt-4">
                  <h6>Import Results:</h6>
                  <div className="list-group">
                    {this.state.importResults.map((result, i) => (
                      <div
                        key={i}
                        className={`list-group-item list-group-item-${result.status === "success" ? "success" : "danger"
                          }`}
                      >
                        <strong>{result.email}</strong>: {result.message}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {this.state.staff && (
          <div className="card">
            <div className="card-body">
              <h5 className="card-title mb-4">Current Staff Members</h5>
              <p className="text-muted">Any staff on this list may also go to <a href="http://app.beworldclass.org/setPassword" target="_blank">http://app.beworldclass.org/setPassword</a> to gain access to their account.</p>
              <Grid
                columns={[
                  { label: "First Name", key: "firstName" },
                  { label: "Last Name", key: "lastName" },
                  { label: "Email Address", key: "email" },
                ]}
                values={this.state.staff}
                startingFilters={[]}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ManageStaff;