import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { getDatabase, ref, update } from "firebase/database";
import app from '../../firebase';
import { Form } from 'react-bootstrap';

const StylingSettings = (props) => {
    // Retrieve initial CSS code from schoolData, or default to an empty string.
    const [cssCode, setCssCode] = useState(props.schoolData.customCSS || '');
    // Toggle the help message visibility
    const [showHelp, setShowHelp] = useState(false);

    useEffect(() => {
        setCssCode(props.schoolData.customCSS || '');
    }, [props.schoolData.customCSS]);

    // Save the custom CSS code to Firebase
    const handleSaveSettings = (e) => {
        e.preventDefault();
        const db = getDatabase(app);
        const schoolId = props.schoolData.path;
        const publicDataRef = ref(db, `schools/${schoolId}/public`);

        update(publicDataRef, {
            customCSS: cssCode,
        }).then(() => {
            alert("Styling settings saved successfully!");
        }).catch((error) => {
            console.error("Error saving styling settings: ", error);
        });
    };

    // Update the state when the textarea changes
    const handleCSSChange = (e) => {
        setCssCode(e.target.value);
    };

    // Toggle the help message when the help button is clicked
    const handleHelpClick = () => {
        setShowHelp(!showHelp);
    };

    return (
        <div className="container">
            <h3>
                <FontAwesomeIcon icon={faPaintBrush} /> Custom Styling
            </h3>
            <small className="text-muted d-block mt-2">
                This is designed for people with web development knowledge to change the styling and branding of any part of the platform.
            </small>
            <div className="mb-3">
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleHelpClick}
                >
                    Don't know how to code CSS?
                </button>
            </div>
            {showHelp && (
                <div className="alert alert-info">
                    If you need help with styling, please contact us at <strong>info@beworldclass.org</strong>
                </div>
            )}
            <form onSubmit={handleSaveSettings}>
                <Form.Group className="mb-3">
                    <Form.Label>Paste your custom CSS here:</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows="10"
                        value={cssCode}
                        onChange={handleCSSChange}
                        placeholder="/* Enter your custom CSS code here */"
                    />
                   
                </Form.Group>

                <button type="submit" className="btn btn-primary">Save</button>
            </form>
        </div>
    );
};

export default StylingSettings;
