import React from "react";
import { useParams } from 'react-router-dom';
import StudentPage from "./studentPage";
import { getDatabase, ref, set, onValue, get } from "firebase/database";
import app from "../firebase";
import FindSchoolDatabase from "./findSchoolDatabase";

function FindSchoolUrl(props) {


    const { schoolPath } = useParams();
    const user = props.user
    const mrCharlieBot = props.mrCharlieBot


    console.log("school is", schoolPath)
    



    return (

        <div>
            <FindSchoolDatabase schoolPath={schoolPath} user={user} mrCharlieBot={mrCharlieBot} /> 
        </div>
    );


}

export default FindSchoolUrl;