import React from "react";
import { getDatabase, ref, get } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import app from "../firebase";
import StudentPage from "./studentPage";
import { Spinner } from "react-bootstrap";
import CharlieMainMenu from "../mrcharliebot/charlieMainMenu";

class FindSchoolDatabase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolData: null,
      loaded: false,
      user: null
    };
  }

  componentDidMount() {
    const database = getDatabase(app);
    const schoolDatabasePath = ref(
      database,
      "schools/" + this.props.schoolPath + "/public"
    );

    get(schoolDatabasePath)
      .then((snapshot) => {
        if (snapshot.exists()) {
          const schoolData = snapshot.val();
          console.log("School from database", schoolData);
          this.setState({ schoolData, loaded: true }, () => {
            // If demo mode is enabled, sign in anonymously.
            if (schoolData.demo === true) {
              const auth = getAuth(app);
              signInAnonymously(auth)
                .then((userCredential) => {
                  // Create a minimal user object with at least a uid property.
                  const user = { uid: userCredential.user.uid };
                  this.setState({ user });
                })
                .catch((error) => {
                  console.error("Anonymous sign-in error:", error);
                });
            }
          });
        } else {
          console.log("No School data available");
          this.setState({ loaded: true });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  render() {
    const { schoolData, loaded, user } = this.state;
    // If demo, use our anonymous user; otherwise, fallback to the user passed in via props.
    const currentUser = user || this.props.user;

    return (
      <div>
        {schoolData && (
          <>
            <StudentPage schoolData={schoolData} user={currentUser} mrCharlieBot={this.props.mrCharlieBot} />
          </>
        )
        }

        {!loaded && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        {loaded && !schoolData && <h2>This is not a valid school link</h2>}
      </div>
    );
  }
}

export default FindSchoolDatabase;
