import React from "react";
import { Formik, Field, Form } from "formik";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import firebaseErrorMessages from "./firebaseErrorMessages.js";
import ForgotPasswordForm from "./forgotPasswordForm"; // Import the new component

class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            values: {},
            showForgotPassword: false, // Toggle between login and forgot password
        };
    }

    getErrorMessage = (errorCode) => {
        return firebaseErrorMessages[errorCode] || firebaseErrorMessages.default;
    };

    signIn = () => {
        const auth = getAuth();
        const { password } = this.state.values;
        const email = this.state.values.email?.includes("@")
            ? this.state.values.email
            : `b3w0r1d+${this.state.values?.email}@beworldclass.org`;

        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                // Handle successful sign-in
            })
            .catch((error) => {
                const friendlyMessage = this.getErrorMessage(error.code);
                this.setState((prevState) => ({
                    errors: { ...prevState.errors, signIn: friendlyMessage },
                }));
            });
    };

    toggleForgotPassword = () => {
        this.setState({ showForgotPassword: !this.state.showForgotPassword });
    };

    render() {
        return (
            <div className="sign-in-form card p-4 p-md-5 shadow-lg border-0"

                style={{

                    margin: 'auto', // Center horizontally if needed within its parent
                    background: 'rgba(255, 255, 255, 0.9)', // Semi-transparent white
                    backdropFilter: 'blur(12px)',          // Blur background
                    WebkitBackdropFilter: 'blur(12px)',    // Safari prefix
                    border: '1px solid rgba(255, 255, 255, 0.2)', // Subtle light border
                    borderRadius: '1rem', // Rounded corners
                    textAlign: "center"
                }}
            >
                <Formik
                    initialValues={{}}
                    onSubmit={async (values) => {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        this.setState({ submitAttempt: true, values: values }, () => {
                            this.signIn();
                        });
                    }}
                    validate={(values) => {
                        const errors = {};

                        if (!values.email) {
                            errors.email = "Required";
                        }

                        if (!values.password) {
                            errors.password = "Required";
                        }

                        this.setState({ errors: errors, values: values });
                    }}
                >
                    <Form>
                        {!this.state.showForgotPassword ? (
                            <>
                                {!this.props.mrCharlieBot && (
                                    <div className="sign-in-logo-wrapper">
                                        <img
                                            className="sign-in-logo"
                                            src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2022/06/cropped-BeWorldClass-transparent-rgb.png?resize=144%2C42&ssl=1"
                                            alt="BeWorldClass Logo"
                                        />
                                    </div>
                                )}

                                <div style={{ textAlign: "center", paddingTop: "2rem" }}>
                                   

                                    <div className="form-field  mb-4" style={{textAlign:"left"}}>
                                        <label style={{marginBottom: '0.4rem',}}>Email/Username</label>
                                        <Field
                                            name="email"
                                            type="text"
                                            className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""
                                                } form-control`}
                                            placeholder=" "
                                        />
                                        <div className="invalid-feedback">{this.state.errors.email}</div>
                                    </div>

                                    <div className="form-field mb-4" style={{textAlign:"left"}}>
                                        <label>Password</label>
                                        <Field
                                            name="password"
                                            type="password"
                                            className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""
                                                } form-control`}
                                            placeholder=" "
                                        />
                                        <div className="invalid-feedback">{this.state.errors.password}</div>
                                    </div>

                                    <div className="form-field">
                                        <button className="btn btn-primary" type="submit">
                                            Login
                                        </button>
                                        <div className="text-danger">{this.state.errors.signIn}</div>
                                    </div>

                                    <div className="form-field">
                                        <button type="button" className="btn btn-link" onClick={this.toggleForgotPassword}>
                                            Forgot Password?
                                        </button>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <h4>Reset Password</h4>

                                <ForgotPasswordForm

                                    email={this.state.values.email}
                                />
                                <div className="form-field" style={{ textAlign: "left" }}>
                                    <button type="button" className="btn btn-link" onClick={this.toggleForgotPassword}>
                                        Back to Sign In
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </Formik>
            </div>
        );
    }
}

export default SignIn;
