import React from "react";
import { Formik, Form, Field, FieldArray, getIn } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import app from "../../firebase";
import { getDatabase, set, ref } from "firebase/database";
import StudentModule from "../../student/modules/studentModule";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import segmentTypes from "./segmentTypes"; 

// ---------- Helper Components for Rendering Fields ----------

const RenderField = ({ baseName, fieldConfig, parentSegment }) => {
  const fieldName = `${baseName}.${fieldConfig.name}`;
  if (fieldConfig.type === "text") {
    return (
      <div>
        <label>{fieldConfig.label}:</label>
        <Field
          name={fieldName}
          type="text"
          placeholder={fieldConfig.placeholder || fieldConfig.label}
        />
      </div>
    );
  } else if (fieldConfig.type === "checkbox") {
    return (
      <div>
        <label>
          {fieldConfig.label}:{" "}
          <Field name={fieldName} type="checkbox" />
        </label>
      </div>
    );
  } else if (fieldConfig.type === "select") {
    // Use provided options or try to dynamically extract them from parentSegment.
    let options = fieldConfig.options;
    if (!options && fieldConfig.optionsField && parentSegment) {
      const dynamicOptions = parentSegment[fieldConfig.optionsField] || [];
      options = dynamicOptions.map((item) => ({
        value: item[fieldConfig.optionFieldName],
        label: item[fieldConfig.optionFieldName]
      }));
    }
    return (
      <div>
        <label>{fieldConfig.label}:</label>
        <Field as="select" name={fieldName}>
          <option value="">Select {fieldConfig.label}</option>
          {options &&
            options.map((option, idx) => (
              <option key={idx} value={option.value}>
                {option.label}
              </option>
            ))}
        </Field>
      </div>
    );
  } else {
    // Fallback to a text field
    return (
      <div>
        <label>{fieldConfig.label}:</label>
        <Field name={fieldName} type="text" placeholder={fieldConfig.label} />
      </div>
    );
  }
};

const RenderArrayField = ({ baseName, arrayConfig, parentSegment }) => {
  return (
    <FieldArray name={baseName}>
      {({ push, remove, form }) => {
        // Use getIn to properly resolve the nested field path (e.g., "segments.0.options")
        const items = getIn(form.values, baseName) || [];
        return (
          <div>
            <h4>{arrayConfig.label}</h4>
            {items.length > 0 ? (
              items.map((item, idx) => (
                <div key={idx} style={{ marginBottom: "0.5rem" }}>
                  {arrayConfig.itemFields.map((fieldConfig, fieldIdx) => (
                    <RenderField
                      key={fieldIdx}
                      baseName={`${baseName}.${idx}`}
                      fieldConfig={fieldConfig}
                      parentSegment={parentSegment}
                    />
                  ))}
                  <button
                    type="button"
                    onClick={() => remove(idx)}
                    style={{ marginLeft: "0.5rem" }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              ))
            ) : (
              <div>No {arrayConfig.label.toLowerCase()} added.</div>
            )}
            <button
              type="button"
              onClick={() => push(arrayConfig.defaultItem || {})}
            >
              {arrayConfig.addButtonLabel}
            </button>
          </div>
        );
      }}
    </FieldArray>
  );
};

const SegmentFieldRenderer = ({ segment, index }) => {
  const config = segmentTypes[segment.type];
  const baseName = `segments.${index}`;
  if (!config) {
    // Fallback if no type is selected
    return (
      <div style={{ marginLeft: "1rem" }}>
        <div>
          <label>Question:</label>
          <Field name={`${baseName}.question`} type="text" />
        </div>
      </div>
    );
  }
  return (
    <div style={{ marginLeft: "1rem" }}>
      {config.fields &&
        config.fields.map((fieldConfig, idx) => (
          <RenderField
            key={idx}
            baseName={baseName}
            fieldConfig={fieldConfig}
            parentSegment={segment}
          />
        ))}
      {config.arrays &&
        Object.entries(config.arrays).map(([arrayKey, arrayConfig]) => (
          <RenderArrayField
            key={arrayKey}
            baseName={`${baseName}.${arrayKey}`}
            arrayConfig={arrayConfig}
            parentSegment={segment}
          />
        ))}
    </div>
  );
};

// -------------------- Main Component --------------------

class ModuleSetup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleData: {
        name: "",
        sortOrder: 0,
        investigation: "",
        preparation: {
          openingQuestion: "",
          preparationTime: 0, // in minutes
          purpose: "",
          material: ""
        },
        segments: [],
        synthesis:
          "This will appear after learners have played through the Investigation and should help them improve their score in the Review phase."
      },
      loaded: false,
      unsavedChanges: false
    };
  }

  componentDidMount() {
    const { schoolData, selectedModule } = this.props;
    const existingModule =
      schoolData.moduleConfig && schoolData.moduleConfig[selectedModule];

    if (existingModule && existingModule.name) {
      this.setState({
        moduleData: existingModule,
        loaded: true,
        unsavedChanges: false
      });
    } else {
      this.setState({ loaded: true, unsavedChanges: false });
    }
  }

  submitToFirebase = (values) => {
    const { schoolData, selectedModule } = this.props;
    const database = getDatabase(app);
    const submissionPath = ref(
      database,
      `schools/${schoolData.path}/public/moduleConfig/${selectedModule}`
    );

    set(submissionPath, values)
      .then(() => {
        this.setState({ unsavedChanges: false });
        console.log("Module data saved successfully!");
      })
      .catch((error) => {
        console.error("Error writing to Firebase: ", error);
      });
  };

  render() {
    const { moduleData, loaded } = this.state;
    if (!loaded) return <div>Loading...</div>;

    // A default structure for a new segment (all arrays empty)
    const defaultSegment = {
      type: "",
      question: "",
      options: [],      // for CHOOSE
      categories: [],   // for CATEGORISE
      items: [],        // for CATEGORISE
      sortItems: [],    // for SORT
      pairs: []         // for MATCH
    };

    return (
      <div>
        <h2>Module Setup</h2>
        <Formik
          initialValues={moduleData}
          onSubmit={(values) => {
            this.submitToFirebase(values);
          }}
        >
          {({ values }) => (
            <Form>
              <div>
                <label>Module Name:</label>
                <Field name="name" type="text" />
              </div>

              <div>
                <label>Sort Order:</label>
                <Field name="sortOrder" type="number" />
              </div>

              <div>
                <label>Investigation:</label>
                <Field name="investigation">
                  {({ field, form }) => (
                    <ReactQuill
                      value={field.value}
                      onChange={(content) =>
                        form.setFieldValue(field.name, content)
                      }
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, false] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ color: [] }, { background: [] }],
                          ["link", "image", "video"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                          ["table"],
                          ["clean"]
                        ]
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "link",
                        "image",
                        "video",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "table"
                      ]}
                    />
                  )}
                </Field>
              </div>

              <h3>Preparation</h3>
              <div>
                <label>Opening Question:</label>
                <Field name="preparation.openingQuestion" type="text" />
              </div>
              <div>
                <label>Preparation Time (minutes):</label>
                <Field name="preparation.preparationTime" type="number" />
              </div>
              <div>
                <label>Purpose:</label>
                <Field name="preparation.purpose" type="text" />
              </div>
              <div>
                <label>Material:</label>
                <Field name="preparation.material">
                  {({ field, form }) => (
                    <ReactQuill
                      value={field.value}
                      onChange={(content) =>
                        form.setFieldValue(field.name, content)
                      }
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, false] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ color: [] }, { background: [] }],
                          ["link", "image", "video"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                          ["table"],
                          ["clean"]
                        ]
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "link",
                        "image",
                        "video",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "table"
                      ]}
                    />
                  )}
                </Field>
              </div>

              <h3>Segments</h3>
              <FieldArray name="segments">
                {({ remove, push }) => (
                  <div>
                    {values.segments && values.segments.length > 0 ? (
                      values.segments.map((segment, index) => (
                        <div
                          key={index}
                          style={{
                            border: "1px solid #ccc",
                            padding: "1rem",
                            marginBottom: "1rem"
                          }}
                        >
                          <div>
                            <label>Segment Type:</label>
                            <Field as="select" name={`segments.${index}.type`}>
                              <option value="">Select Type</option>
                              {Object.entries(segmentTypes).map(([key, config]) => (
                                <option key={key} value={key}>
                                  {config.label}
                                </option>
                              ))}
                            </Field>
                            <button
                              type="button"
                              onClick={() => remove(index)}
                              style={{ marginLeft: "0.5rem" }}
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </button>
                          </div>
                          {/* Render segment-specific fields based on the config */}
                          <SegmentFieldRenderer segment={segment} index={index} />
                        </div>
                      ))
                    ) : (
                      <div>No segments added.</div>
                    )}
                    <button type="button" onClick={() => push(defaultSegment)}>
                      Add Segment
                    </button>
                  </div>
                )}
              </FieldArray>

              <h3>Synthesis</h3>
              <div>
                <label>Synthesis:</label>
                <Field name="synthesis">
                  {({ field, form }) => (
                    <ReactQuill
                      value={field.value}
                      onChange={(content) =>
                        form.setFieldValue(field.name, content)
                      }
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, 3, false] }],
                          ["bold", "italic", "underline", "strike"],
                          [{ color: [] }, { background: [] }],
                          ["link", "image", "video"],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ align: [] }],
                          ["table"],
                          ["clean"]
                        ]
                      }}
                      formats={[
                        "header",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "color",
                        "background",
                        "link",
                        "image",
                        "video",
                        "list",
                        "bullet",
                        "indent",
                        "align",
                        "table"
                      ]}
                    />
                  )}
                </Field>
              </div>

              <button type="submit" style={{ marginTop: "1rem" }}>
                <FontAwesomeIcon icon={faCheck} /> Save Module
              </button>
            </Form>
          )}
        </Formik>

        <br />
        <br />
        <h1>Test the Module</h1>
        <br />
        <br />

        {/* Placeholder for testing the module */}
        <StudentModule moduleData={this.state.moduleData} />
      </div>
    );
  }
}

export default ModuleSetup;
