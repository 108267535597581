import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

class FlaggedContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize filteredSubmissions from props if available
      filteredSubmissions: props.flaggedSubmissions || [],
      filters: {
        reason: ''
      },
      expandedSubmissions: new Set()
    };
  }

  componentDidMount() {
    // In case flaggedSubmissions comes in after the initial mount, update state accordingly.
    if (this.props.flaggedSubmissions) {
      this.setState({ filteredSubmissions: this.props.flaggedSubmissions });
    }
  }

  componentDidUpdate(prevProps) {
    // When flaggedSubmissions prop changes, update the state.
    if (prevProps.flaggedSubmissions !== this.props.flaggedSubmissions) {
      this.setState({ filteredSubmissions: this.props.flaggedSubmissions });
    }
  }

  handleFilterChange = (e) => {
    const reasonFilter = e.target.value;
    this.setState((prevState) => ({
      filters: {
        ...prevState.filters,
        reason: reasonFilter
      },
      // Filter the submissions coming from props so that if a filter is active,
      // only submissions with at least one matching reason are included.
      filteredSubmissions: this.props.flaggedSubmissions.filter((submission) =>
        reasonFilter === '' ||
        (submission.reason &&
          submission.reason.some((r) => r.includes(reasonFilter)))
      )
    }));
  };

  toggleSubmission = (submissionId) => {
    this.setState((prevState) => {
      const expandedSubmissions = new Set(prevState.expandedSubmissions);
      if (expandedSubmissions.has(submissionId)) {
        expandedSubmissions.delete(submissionId);
      } else {
        expandedSubmissions.add(submissionId);
      }
      return { expandedSubmissions };
    });
  };

  render() {
    const { filteredSubmissions, expandedSubmissions } = this.state;
    // Reverse the submissions array for display without mutating the original array.
    const displayedSubmissions = [...filteredSubmissions].reverse();
    // Create a unique list of reasons for the dropdown filter.
    const reasons = Array.from(
      new Set(this.props.flaggedSubmissions.flatMap(sub => sub.reason || []))
    );

    return (
      <div className="container mt-5">
        <div className="card shadow">
          <div className="card-header bg-primary text-white">
            <h3 className="card-title">Flagged Content</h3>
          </div>
          <div className="card-body">
            <div className="mb-4">
              <select className="form-select" onChange={this.handleFilterChange}>
                <option value="">All Reasons</option>
                {reasons.map((reason, index) => (
                  <option key={index} value={reason}>
                    {reason}
                  </option>
                ))}
              </select>
            </div>

            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
              {displayedSubmissions.length > 0 ? (
                <ul className="list-group">
                  {displayedSubmissions.map((submission) => {
                    // Find the corresponding user for this submission.
                    const user = this.props.users?.find(u => u.uid === submission.user);
                    // Format the submission time to UK format.
                    const time = new Date(submission.time);
                    const ukOptions = {
                      timeZone: 'Europe/London',
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    };
                    const ukDateTime = time.toLocaleString('en-GB', ukOptions);

                    if (user) {
                      return (
                        <li
                          key={submission.key}
                          className="list-group-item"
                        >
                          {/* Student info aligned neatly */}
                          <div className="row align-items-center">
                            <div className="col-md-3">
                              <strong>
                                {user.firstName} {user.lastName}
                              </strong>
                            </div>
                            <div className="col-md-3">
                              <span>Class: {user.class || 'N/A'}</span>
                            </div>
                            <div className="col-md-3">
                              <span>Year: {user.yearGroup || 'N/A'}</span>
                            </div>
                            <div className="col-md-3">
                              {ukDateTime}
                            </div>
                          </div>

                          {/* Reason pills and toggle button */}
                          <div className="row align-items-center mt-2">
                            <div className="col">
                              {submission.reason &&
                                submission.reason.map((reason, idx) => {
                                  // If the reason includes any of these strings, use warning styling.
                                  const isWarning =
                                    reason.includes("Safeguard") ||
                                    reason.includes("Dangerous") ||
                                    reason.includes("Distress");
                                  const pillClass = isWarning ? 'bg-danger' : 'bg-warning';
                                  return (
                                    <span
                                      key={idx}
                                      className={`badge rounded-pill me-1 ${pillClass}`}
                                    >
                                      {reason}
                                    </span>
                                  );
                                })}
                            </div>
                            <div className="col-auto">
                              <button
                                className="btn btn-sm btn-outline-secondary"
                                onClick={() => this.toggleSubmission(submission.key)}
                              >
                                {expandedSubmissions.has(submission.key) ? (
                                  <>
                                    Hide Text <FontAwesomeIcon icon={faChevronUp} />
                                  </>
                                ) : (
                                  <>
                                    Show Text <FontAwesomeIcon icon={faChevronDown} />
                                  </>
                                )}
                              </button>
                            </div>
                          </div>

                          {/* Expanded submission text */}
                          {expandedSubmissions.has(submission.key) && (
                            <div className="mt-3">
                              <p>
                                "<strong>{submission.value || 'N/A'}</strong>"
                              </p>
                            </div>
                          )}
                        </li>
                      );
                    }
                    return null;
                  })}
                </ul>
              ) : (
                <div className="text-center text-muted mt-4">
                  No flagged content found.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FlaggedContent;
