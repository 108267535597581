import React from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { getFirestore, doc, setDoc } from "firebase/firestore";

import app from '../firebase.js'
//import { firebase_app } from 'firebase';



class SignUpSchool extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errors: {},
            submitAttempt: false,
            values: {},
            serverError: null,
        }
    }

    componentDidMount() {



    }

    signUp = () => {


        createUserWithEmailAndPassword(getAuth(app), this.state.values.email, this.state.values.password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;

                this.setState({ user: user })
                this.createSchoolInDatabase(user)

                console.log(this.state.user)
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                this.setState({ serverError: errorMessage })
                console.log(errorMessage)
            });
    }

    createSchoolInDatabase = (user) => {

        function makeId(length) {
            var result = '';
            var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for (var i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }

            result =  `${result}`//${this.state.values.name}
            return result;
        }



        const schoolPath = makeId(10)
        const database = getDatabase(app)
        const inSchoolPath = ref(database, 'schools/' + schoolPath);
        const userPath = ref(database, 'users/' + user.uid);



        set(userPath, {
            role: "teacher",
            schools: {
                [schoolPath]: true,
            }

        }).then(() => {
            set(inSchoolPath, {
                staff: {
                    [user.uid]: {
                        firstName: this.state.values.firstName,
                        lastName: this.state.values.lastName,
                    }
                },
                public: {
                    name: this.state.values.name,
                    path: schoolPath,
                    stage: this.state.values.schoolType,
                    safeStart: this.props.safeStart ? true : false,
                    mrCharlieBot: this.props.mrCharlieBot ? true : false
                }
            })
        }).then(() => {
            // Now create the school in Firestore
            this.createSchoolInFirestore(schoolPath, user);
        });

    }

    createSchoolInFirestore = (schoolId, user) => {
        console.log("firestore")
        const db = getFirestore(app);
        const schoolRef = doc(db, 'schools', schoolId);

        setDoc(schoolRef, {
            name: this.state.values.name,
            staff: {
                [user.uid]: true
            }
        }).then(() => {
            console.log("School created in Firestore");
        }).catch((error) => {
            this.setState({ serverError: error })
            console.error("Error creating school in Firestore: ", error);
        });
    }

    render() {

        return (
            <div style={{ textAlign: "center", padding: "2rem" }}>
                <img src="https://jaj.dfq.mybluehost.me/website_5857b8b4/wp-content/uploads/2024/12/Copy-of-Charlie-Bot.png" height="100px" alt="" />
                <div className="card" style={{ textAlign: "left", padding: "2rem" }}>
                    <Formik
                        initialValues={{}}
                        onSubmit={async values => {
                            await new Promise(resolve => setTimeout(resolve, 500));
                            if (Object.keys(this.state.errors).length === 0) {

                                console.log("submit")
                                this.setState({ submitAttempt: true, values: values }, () => {
                                    this.signUp();
                                });

                            }
                            else {


                                this.setState({ submitAttempt: true })
                            }

                        }}
                        validate={values => {
                            const errors = {};

                            if (!values.firstName) {
                                errors.firstName = 'Required';
                            }
                            if (!values.lastName) {
                                errors.lastName = 'Required';
                            }

                            if (!values.schoolType) {
                                errors.schoolType = 'Required';
                            }

                            if (!values.schoolSize) {
                                errors.schoolSize = 'Required';
                            }

                            if (!values.email) {
                                errors.email = 'Required';
                            } else if (
                                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                                    values.email
                                )
                            ) {
                                errors.email = 'Invalid email address';
                            }

                            if (values.email != values.confirmEmail) {
                                errors.confirmEmail = 'Email address does not match'
                            }

                            if (!values.password) {
                                errors.password = 'Required'
                            } else if (values.password.length < 6) {

                                errors.password = 'Password must be at least 6 characters in length'

                            }


                            if (values.password != values.confirmPassword) {
                                errors.confirmPassword = 'Password does not match'
                            }
                            if (!values.name) {
                                errors.name = 'Required'
                            }




                            this.setState({ errors: errors })
                            console.log(errors)
                            console.log(values)
                        }
                        }
                    >

                        <Form >

                            {this.props.safeStart &&
                                <div style={{textAlign:"center"}}>

                                    <img src="https://i0.wp.com/beworldclass.org/wp-content/uploads/2024/04/Safe-start-logo-500-x-200-px-1.png?w=500&ssl=1"
                                        style={{ height: "20vh" }}>

                                    </img>
                                </div>
                            }

                            <h2 className="form-heading">Sign up your school</h2>


                            <h4 style={{ marginTop: "4rem" }}>School Details</h4>

                            <label >School Name</label>
                            <Field name="name" type="text" className={`${this.state.errors.name && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                            <div className="invalid-feedback">{this.state.errors.name}</div>

                            {!this.props.safeStart && !this.props.mrCharlieBot &&
                                <>
                                    < label > School Tagline</label>
                                    <Field name="tagline" type="text" className={`${this.state.errors.tagline && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.tagline}</div>
                                </>
                            }

                            <label >School type</label>
                            <Field as="select" name="schoolType" className={`${this.state.errors.schoolType && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " >
                                <option></option>
                                <option value="primary">Primary School</option>
                                <option value="secondary">Secondary School</option>
                                <option value="special">Special School</option>
                            </Field>
                            <div className="invalid-feedback">{this.state.errors.schoolType}</div>


                            <label >Number of students in your school</label>
                            <Field name="schoolSize" type="number" className={`${this.state.errors.tagline && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />

                            {this.props.safeStart || this.props.mrCharlieBot ?

                                <p>This is so we can estimate our server requirements. A ballpark figure is suitable.</p>

                                :

                                <p>This is is used for your own internal reporting and data analysis. A ballpark figure is suitable.</p>
                            }
                            <div className="invalid-feedback">{this.state.errors.schoolSize}</div>

                            <hr />
                            <h4 style={{ marginTop: "4rem" }} >Your account details</h4>
                            <p>This is to create your account, you will be able to add more staff after this.</p>
                            <div className="row">
                                <div className="col">
                                    <label >First name</label>
                                    <Field name="firstName" type="text" className={`${this.state.errors.firstName && this.state.submitAttempt ? "is-invalid" : ""} form-control`} />
                                    <div className="invalid-feedback">{this.state.errors.firstName}</div>
                                </div>

                                <div className="col">

                                    <label >Last Name</label>
                                    <Field name="lastName" type="text" className={`${this.state.errors.lastName && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.lastName}</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">

                                    <label >Email Address</label>
                                    <Field name="email" type="email" className={`${this.state.errors.email && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.email}</div>
                                </div>
                                <div className="col">
                                    <label >Confirm Email Address</label>
                                    <Field name="confirmEmail" type="email" className={`${this.state.errors.confirmEmail && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.confirmEmail}</div>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col">
                                    <label >Create Password</label>
                                    <Field name="password" type="password" className={`${this.state.errors.password && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.password}</div>
                                </div>
                                <div className="col">
                                    <label >Confirm Password</label>
                                    <Field name="confirmPassword" type="password" className={`${this.state.errors.confirmPassword && this.state.submitAttempt ? "is-invalid" : ""} form-control`} placeholder=" " />
                                    <div className="invalid-feedback">{this.state.errors.confirmPassword}</div>
                                </div>
                            </div>

                            {this.state.serverError &&
                                <div className="alert alert-danger">{this.state.serverError}</div>
                            }
                            <button className="btn btn-primary" type="submit">Submit</button>

                        </Form >
                    </Formik >
                </div >
            </div >
        );
    }
}

export default SignUpSchool;