import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationCircle,
    faChevronDown,
    faChevronUp,
    faTrophy
} from '@fortawesome/free-solid-svg-icons';
import SocialMediaPost from './socialMediaPost';

class TopSubmissionsSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingSearchQuery: '',
            searchQuery: '',
            filters: {
                yearGroup: '',
                class: ''
            },
            filteredSubmissions: [],
            expandedSubmissions: new Set(),
            isLoading: false,
            domFixToggle: false
        };
        this.filterSubmissionsTimeout = null;
    }

    componentDidMount() {
        this.filterSubmissions();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.searchQuery !== this.state.searchQuery ||
            prevState.filters !== this.state.filters ||
            prevProps.extendedSubmissions !== this.props.extendedSubmissions
        ) {
            if (this.filterSubmissionsTimeout) {
                clearTimeout(this.filterSubmissionsTimeout);
            }
            this.filterSubmissionsTimeout = setTimeout(() => {
                this.filterSubmissions();
            }, 300);
        }
    }
    filterSubmissions = () => {
        const { extendedSubmissions } = this.props;
        const { searchQuery, filters } = this.state;
    
        this.setState({ isLoading: true });
    
        const initialSubmissions = Array.isArray(extendedSubmissions) ? extendedSubmissions : [];
        const filteredWithScores = [];
        const hasSearchQuery = Boolean(searchQuery?.trim());
        const query = hasSearchQuery ? searchQuery.trim().toLowerCase() : '';
        const queryWords = query ? query.split(/\s+/) : [];
    
        for (const submission of initialSubmissions) {
            // Apply base filters
            if (submission.inappropriate) continue;
            if (filters.yearGroup && submission.yearGroup != filters.yearGroup) continue;
            if (filters.class && submission.class !== filters.class) continue;
    
            let score;
    
            if (hasSearchQuery) {
                // Calculate search score
                const searchableText = Object.values(submission)
                    .filter(value => typeof value === 'string')
                    .join(' ')
                    .toLowerCase();
    
                let searchScore = 0;
                for (const word of queryWords) {
                    const regex = new RegExp(`\\b${word}\\b`);
                    if (regex.test(searchableText)) {
                        searchScore += 2;
                    } else if (searchableText.includes(word)) {
                        searchScore += 1;
                    }
                }
    
                if (searchScore === 0) continue;
                score = searchScore;
            } else {
                // Calculate default sorting score
                const understanding = Number(submission.understanding || 0);
                const impressive = Number(submission.impressiveSorting || 0);
                const valueEstimate = Number(submission.valueEstimate || 0) * 0.01;
                score = understanding + impressive + valueEstimate;
            }
    
            filteredWithScores.push({ submission, score });
        }
    
        // Single sort operation
        filteredWithScores.sort((a, b) => b.score - a.score);
    
        this.setState({
            filteredSubmissions: filteredWithScores.map(item => item.submission),
            isLoading: false
        });
    };

    handleSearchChange = e => {
        this.setState({ pendingSearchQuery: e.target.value });
    };

    handleSearchClick = () => {
        this.setState({ searchQuery: this.state.pendingSearchQuery });
    };

    handleFilterChange = (filterKey, value) => {
        this.setState(prevState => ({
            filters: {
                ...prevState.filters,
                [filterKey]: value || ''
            }
        }));
    };

    toggleSubmission = submissionId => {
        this.setState(prevState => {
            const expandedSubmissions = new Set(prevState.expandedSubmissions);
            if (expandedSubmissions.has(submissionId)) {
                expandedSubmissions.delete(submissionId);
            } else {
                expandedSubmissions.add(submissionId);
            }
            return { expandedSubmissions, domFixToggle: true };
        }, () => {
            setTimeout(() => {
                this.setState(prevState => ({
                    domFixToggle: !prevState.domFixToggle
                }));
            }, 500);
        });
    };

    render() {
        const {
            pendingSearchQuery,
            filters,
            filteredSubmissions,
            expandedSubmissions,
            isLoading
        } = this.state;

        const { extendedSubmissions } = this.props;
        const yearGroups = new Set();
        const classes = new Set();

        if (Array.isArray(extendedSubmissions)) {
            extendedSubmissions.forEach(submission => {
                if (submission.yearGroup) {
                    yearGroups.add(submission.yearGroup);
                }
                if (submission.class) {
                    classes.add(submission.class);
                }
            });
        }

        return (
            <div className="container mt-5">
                <div className="card shadow">
                    <div className="card-header bg-primary text-white">
                        <h3 className="card-title">
                            <FontAwesomeIcon icon={faTrophy} /> Top Submissions
                        </h3>
                        <p className="small mb-0">
                            <FontAwesomeIcon icon={faExclamationCircle} /> Ensure permissions are
                            received from students and parents to release any identifiable
                            information.
                        </p>
                    </div>
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-md-4 mb-2">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search submissions..."
                                        value={pendingSearchQuery}
                                        onChange={this.handleSearchChange}
                                        disabled={isLoading}
                                    />
                                    <button
                                        className="btn btn-primary"
                                        onClick={this.handleSearchClick}
                                        disabled={isLoading}
                                    >
                                        Search
                                    </button>
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <select
                                    className="form-select"
                                    value={filters.yearGroup}
                                    onChange={e =>
                                        this.handleFilterChange('yearGroup', e.target.value)
                                    }
                                    disabled={isLoading}
                                >
                                    <option value="">All Year Groups</option>
                                    {[...yearGroups].map(yg => (
                                        <option key={yg} value={yg}>
                                            {yg}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="col-md-4 mb-2">
                                <select
                                    className="form-select"
                                    value={filters.class}
                                    onChange={e => this.handleFilterChange('class', e.target.value)}
                                    disabled={isLoading}
                                >
                                    <option value="">All Classes</option>
                                    {[...classes].map(cls => (
                                        <option key={cls} value={cls}>
                                            {cls}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        {isLoading && (
                            <div className="text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        )}

                        {!isLoading && (
                            <div style={{ maxHeight: '500px', overflowY: 'auto' }}>
                                <ul className="list-group">
                                    {filteredSubmissions.map(submission => {
                                        const submissionId = submission.key;
                                        return (
                                            <li
                                                key={submissionId}
                                                className="list-group-item list-group-item-action"
                                            >
                                                <div className="d-flex justify-content-between align-items-left">
                                                    <div>
                                                        <strong>
                                                            {submission.firstName} {submission.lastName}
                                                        </strong>{' '}
                                                        <FontAwesomeIcon
                                                            icon={faExclamationCircle}
                                                            title="Ensure permissions are received from students and parents to release any identifiable information."
                                                            className="text-warning"
                                                        />
                                                    </div>
                                                    <div className="text-muted">{submission.source}</div>
                                                    <button
                                                        className="btn btn-sm btn-outline-secondary"
                                                        onClick={() => this.toggleSubmission(submissionId)}
                                                    >
                                                        {expandedSubmissions.has(submissionId) ? (
                                                            <>
                                                                Hide Details{' '}
                                                                <FontAwesomeIcon icon={faChevronUp} />
                                                            </>
                                                        ) : (
                                                            <>
                                                                Show Details{' '}
                                                                <FontAwesomeIcon icon={faChevronDown} />
                                                            </>
                                                        )}
                                                    </button>
                                                </div>
                                                {expandedSubmissions.has(submissionId) && (
                                                    <div className="row mt-3">
                                                        <div className="col">
                                                            <h6 className="text-uppercase text-primary">
                                                                {submission.characteristic}
                                                            </h6>
                                                            <p className="lead" style={{ fontSize: '1.2rem' }}>
                                                                {submission.description}
                                                            </p>
                                                            <p>
                                                                <strong>Year Group:</strong>{' '}
                                                                {submission.yearGroup}
                                                            </p>
                                                            <p>
                                                                <strong>Class:</strong> {submission.class}
                                                            </p>
                                                        </div>
                                                        <div className="col">
                                                            <SocialMediaPost postData={{ quote: submission.description }} />
                                                        </div>
                                                        {this.state.domFixToggle && <p>{' '}</p>}
                                                    </div>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}

                        {!isLoading && filteredSubmissions.length === 0 && (
                            <div className="text-center text-muted mt-4">
                                No submissions found.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TopSubmissionsSearch;
