// StudentRenderers.jsx
import React from "react";
import { Field, FieldArray } from "formik";

// For the "Choose" segment
export const ChooseStudentRenderer = ({ segment, index, values, setFieldValue }) => {
  const numCorrect = (segment.displayOptions || []).filter(opt => opt.isCorrect).length;
  return (
    <div>
      {(segment.displayOptions || []).map((option, optIndex) => {
        if (numCorrect === 1) {
          return (
            <div key={optIndex}>
              <label>
                <Field
                  type="radio"
                  name={`segments.${index}.selectedOption`}
                  value={option.text}
                />{" "}
                {option.text}
              </label>
            </div>
          );
        } else {
          return (
            <div key={optIndex}>
              <label>
                <Field
                  type="checkbox"
                  name={`segments.${index}.selectedOptions`}
                  value={option.text}
                />{" "}
                {option.text}
              </label>
            </div>
          );
        }
      })}
    </div>
  );
};

// For the "Categorise" segment
export const CategoriseStudentRenderer = ({ segment, index, values, setFieldValue }) => (
  <div>
    <p>
      <strong>Categories:</strong>{" "}
      {(segment.categories || []).map(cat => cat.name).join(", ")}
    </p>
    {(segment.displayItems || []).map((item, itemIndex) => (
      <div key={itemIndex} style={{ marginBottom: "0.5rem" }}>
        <label>
          {item.text}{" "}
          <Field as="select" name={`segments.${index}.answers.${itemIndex}`}>
            <option value="">Select Category</option>
            {(segment.categories || []).map((cat, catIndex) => (
              <option key={catIndex} value={cat.name}>
                {cat.name}
              </option>
            ))}
          </Field>
        </label>
      </div>
    ))}
  </div>
);

// For the "Sort" segment
export const SortStudentRenderer = ({ segment, index, values, setFieldValue }) => (
  <div>
    <FieldArray name={`segments.${index}.sortOrder`}>
      {() => {
        const sortOrder = values.segments[index].sortOrder || [];
        return (
          <div>
            {sortOrder.map((item, itemIndex) => (
              <div
                key={itemIndex}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "0.5rem"
                }}
              >
                <span style={{ marginRight: "1rem" }}>{item}</span>
                <button
                  type="button"
                  onClick={() => {
                    if (itemIndex > 0) {
                      const newOrder = [...sortOrder];
                      [newOrder[itemIndex - 1], newOrder[itemIndex]] = [
                        newOrder[itemIndex],
                        newOrder[itemIndex - 1]
                      ];
                      setFieldValue(`segments.${index}.sortOrder`, newOrder);
                    }
                  }}
                >
                  ↑
                </button>
                <button
                  type="button"
                  onClick={() => {
                    if (itemIndex < sortOrder.length - 1) {
                      const newOrder = [...sortOrder];
                      [newOrder[itemIndex + 1], newOrder[itemIndex]] = [
                        newOrder[itemIndex],
                        newOrder[itemIndex + 1]
                      ];
                      setFieldValue(`segments.${index}.sortOrder`, newOrder);
                    }
                  }}
                  style={{ marginLeft: "0.5rem" }}
                >
                  ↓
                </button>
              </div>
            ))}
          </div>
        );
      }}
    </FieldArray>
  </div>
);

// For the "Match" segment
export const MatchStudentRenderer = ({ segment, index, values, setFieldValue }) => (
  <div>
    <FieldArray name={`segments.${index}.matches`}>
      {() => (
        <div>
          {(segment.pairs || []).map((pair, pairIndex) => (
            <div key={pairIndex} style={{ marginBottom: "0.5rem" }}>
              <span style={{ marginRight: "1rem" }}>{pair.left}</span>
              <Field as="select" name={`segments.${index}.matches.${pairIndex}`}>
                <option value="">Select matching item</option>
                {(segment.shuffledRightOptions || []).map((option, optIndex) => (
                  <option key={optIndex} value={option}>
                    {option}
                  </option>
                ))}
              </Field>
            </div>
          ))}
        </div>
      )}
    </FieldArray>
  </div>
);
