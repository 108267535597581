import React from "react";
import { useParams } from 'react-router-dom';
import Characteristics from "./characteristics.js"
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Portfolio from "../../portfolio.js";
import CustomPortfolio from "../customPortfolio.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';

function AreaPage(props) {


    const { areaPath } = useParams();
    const area = props.areaData.filter(area => area.path === areaPath)[0]
    console.log("area", area)
    const areas = props.areaData
    const schoolData = props.schoolData
    const user = props.user
    const sidebarIcons = []

    for (let i = 0; areas.length > i; i++) {
        sidebarIcons.push(<a className="row" href={"/student/area/" + areas[i].path}><div><img src={areas[i].imageUrl} className="area-page-image col-6" /><p className="col-6">area {i + 1}</p></div></a>)
    }

    if (area) {
        return (

            <Routes>
                <Route path='/' element={
                    <div className="platform-background" style={{ marginTop: "-3rem", paddingTop: "3rem" }}>

                        <div className="row">

                            {area.imageUrl ?
                                <div className="sidebar col-12 col-lg-2">
                                    <img src={area.imageUrl} className={"area-page-image"} />
                                </div> :
                                <div className="sidebar col-12 col-lg-2">
                                <div
                                    className="area-icon-text "
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "1rem",
                                        width: "200px",
                                        height: "200px",
                                        borderRadius: "50%",
                                        textAlign: "center",
                                        overflow: "hidden",
                                    }}
                                >
                                    <FontAwesomeIcon icon={area.icon} size="2xl" />
                                    <div
                                        style={{
                                            marginTop: "0.5rem",
                                            wordWrap: "break-word",
                                            maxWidth: "95%",
                                        }}
                                    >
                                        {area.name}
                                    </div>
                                </div>
                                </div>
                            }


                            <div className="col-12 col-lg-10">

                                <div class="jumbotron jumbotron-fluid">
                                    <div class="container">
                                        <p class="lead"> {area.name}</p>
                                        <h1 class="display-4">{area.motto} </h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="sidebar col-12 col-lg-2"
                                style={{
                                    backgroundColor: "#f8f9fa",
                                    border: "1px solid #dee2e6",
                                    borderRadius: "4px",
                                    height: "100%",
                                    marginTop: "4.4rem"
                                }}
                            >
                                <Link to={"/student/" + schoolData.path} className="mb-3" style={{ fontWeight: "bold", textDecoration: "none" }}>
                                    &larr; Back
                                </Link>

                                {schoolData.demo &&
                                    <div className="mb-3">
                                        <div className="alert alert-info p-2" role="alert" style={{ marginBottom: "0" }}>
                                            <b>Tip!</b><br />Try submitting some evidence! If you give a full enough reflection you get a badge!
                                        </div>
                                    </div>
                                }
                                <div style={{ marginTop: "auto", textAlign: "right" }}>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={`/student/${schoolData.path}/area/${area.path}/portfolio`}
                                        className="btn btn-outline-secondary"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        Download Portfolio
                                    </a>
                                </div>
                            </div>


                            <div className="col-12 col-lg-10">

                                <Characteristics schoolData={schoolData} user={user} area={area} />
                            </div>
                        </div>
                    </div>

                } />
                <Route path='/portfolio' element={<Portfolio schoolData={schoolData} user={user} area={area} />} />


            </Routes>

        )
    } else {
        return (<Routes>
            <Route path='/' element={
                <div className="container">
                    can't find area.
                </div>
            } />
            <Route path='/customPortfolio' element={<CustomPortfolio schoolData={schoolData} user={user} areaData={props.areaData} areaPath={areaPath} />} />

        </Routes>)
    }

}

export default AreaPage;